import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import Button from '../../components/Button'
import { createService, updateService } from '../../services/market.service'
import { buildIntervals } from '../../utils/calendar-utils'
import { format, parseISO } from 'date-fns'
import Datepicker from 'react-tailwindcss-datepicker'

const startTimeIntervals = buildIntervals()
const [, ...restOfArray] = startTimeIntervals;
const  endTimeIntervals = [...restOfArray, '12:00 am']

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })
        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        }
      }
    },
    [validationSchema]
  )

function CreateServiceForm({
  isAdd,
  canEdit,
  photos,
  userId,
  currentService,
  validationSchema,
  id,
  categories,
}) {
  const toast = useToast()
  const history = useHistory()
  const resolver = useYupValidationResolver(yup.object(validationSchema))
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, touchedFields },
    setValue,
  } = useForm({
    resolver,
  })

const handleValueChange = (newValue) => {
  setValue('startDate', newValue?.startDate) 
  setValue('endDate', newValue?.endDate) 
} 

  const [endTimeOptions, setendTimeOptions] = useState(endTimeIntervals)

  const [unit, startTime, endTime, recurrence, startDate, endDate] = watch([
    'unit',
    'startTime',
    'endTime',
    'recurrence',
    'startDate',
    'endDate',
  ])

  useEffect(() => {
    if(startTime){
      const iStartTime = startTimeIntervals.indexOf(startTime)
      const slicedArray = endTimeIntervals.slice(iStartTime)
      setendTimeOptions(slicedArray)
      if(endTime){
        const iEndTime = endTimeIntervals.indexOf(endTime)
        if(iEndTime < iStartTime){
          setValue('endTime', undefined) 
        }
      }
    }
  }, [startTime, endTime, setValue])
  
  useEffect(() => {
    if (unit === 'One-time') {
      setValue('unitValue', undefined) 
    }
  }, [unit, setValue])

  useEffect(() => {
    if (recurrence !== 'Once') {
      setValue('startDate', undefined)
      setValue('endDate', undefined)
    }
  }, [recurrence, setValue])

  useEffect(() => {
    if (currentService) {
      setValue('title', currentService.title)
      setValue('price', currentService.price)
      setValue('description', currentService.description)
      setValue('category', currentService.categoryId)
      setValue('location', currentService.location)
      setValue('quantity', currentService.quantity)
      setValue('recurrence', currentService.recurrence)
      setValue('serviceFormat', currentService.serviceFormat)
      setValue('unit', currentService.unit)
      setValue('unitValue', currentService.unitValue)
      if( currentService?.startDate && currentService?.endDate){
        const startDateISO = parseISO(currentService?.startDate)
        const endDateISO = parseISO(currentService?.endDate)
        setValue('startDate', format(startDateISO, 'yyyy-MM-dd'))
        setValue('endDate', format(endDateISO, 'yyyy-MM-dd'))
      }
      setValue('startTime', currentService.startTime)
      setValue('endTime', currentService.endTime)
      setValue('attachments', currentService.attachments)
    }
  }, [currentService, setValue])

  const handleOnSubmit = async (values) => {
    try {
      if (isAdd && photos.length < 1) return
      
      let isActive = true
      let quantity = 1
      if (isAdd) {
        await createService({
          ...values,
          userId,
          files: photos || [],
          isActive,
          quantity,
        })
      } else {
        await updateService({ ...values, userId, isActive, quantity, _id: currentService._id })
      }
      history.push('/marketplace')
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormControl mb={4} isInvalid={errors.title && touchedFields.title}>
        <FormLabel htmlFor="title">Title</FormLabel>
        <Input id="title" {...register('title')} placeholder="Title" disabled={!canEdit} />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <div className="flex flex-row gap-2">
        <FormControl mb={4} isInvalid={errors.price && touchedFields.price}>
          <FormLabel htmlFor="price">Price</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.300" children="$" />
            <Input
              type="number"
              {...register('price')}
              id="price"
              placeholder="Price"
              disabled={!canEdit}
            />
          </InputGroup>
          <FormErrorMessage>{errors?.price?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mb={4} isInvalid={errors.unitValue && touchedFields.unitValue}>
          <FormLabel htmlFor="unitValue">Value</FormLabel>
          <InputGroup>
            <Input
              type="number"
              {...register('unitValue')}
              id="unitValue"
              placeholder="Unit Value"
              disabled={!canEdit || unit === 'One-time'}
            />
          </InputGroup>
          <FormErrorMessage>{errors?.unitValue?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mb={4} isInvalid={errors.unit && touchedFields.unit}>
          <FormLabel htmlFor="unit">Unit</FormLabel>
          <Select
            {...register('unit')}
            placeholder="Select Unit"
            cursor="pointer"
            disabled={!canEdit}
          >
            <option value="Minutes" className={'cursor-pointer'}>
              Minutes
            </option>
            <option value="Hours" className={'cursor-pointer'}>
              Hours
            </option>
            <option value="Days" className={'cursor-pointer'}>
              Days
            </option>
            <option value="One-time" className={'cursor-pointer'}>
              One-time
            </option>
          </Select>
          <FormErrorMessage>{errors?.unit?.message}</FormErrorMessage>
        </FormControl>
      </div>
      <FormControl mb={4} isInvalid={errors.description && touchedFields.description}>
        <FormLabel htmlFor="description">Description</FormLabel>
        <Textarea
          {...register('description')}
          id="description"
          placeholder="Description"
          resize={'none'}
          disabled={!canEdit}
        />
        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl mb={4} isInvalid={errors.serviceFormat && touchedFields.serviceFormat}>
        <FormLabel htmlFor="serviceFormat">Service Format</FormLabel>
        <Select
          {...register('serviceFormat')}
          placeholder="Select Service Format"
          cursor="pointer"
          disabled={!canEdit}
        >
          <option value="Group" className={'cursor-pointer'}>
            Group
          </option>
          <option value="Personal" className={'cursor-pointer'}>
            Personal
          </option>
          <option value="Other" className={'cursor-pointer'}>
            Other
          </option>
        </Select>
        <FormErrorMessage>{errors?.serviceFormat?.message}</FormErrorMessage>
      </FormControl>
      {!id && (
        <FormControl mb={4} isInvalid={errors.category && touchedFields.category}>
          <FormLabel htmlFor="category">Category</FormLabel>
          <Select
            {...register('category')}
            def={currentService?.categoryId}
            placeholder="Select Category"
            cursor="pointer"
            disabled={!canEdit}
          >
            {categories.map((cat) => {
              return (
                <option key={cat.id} value={cat.id} className={'cursor-pointer'}>
                  {cat.name}
                </option>
              )
            })}
          </Select>
          <FormErrorMessage>{errors?.category?.message}</FormErrorMessage>
        </FormControl>
      )}
      <FormControl mb={4} isInvalid={errors.location && touchedFields.location}>
        <FormLabel htmlFor="location">Location</FormLabel>
        <Input {...register('location')} id="location" placeholder="Location" disabled={!canEdit} />
        <FormErrorMessage>{errors?.location?.message}</FormErrorMessage>
      </FormControl>
      <FormControl mb={4} isInvalid={errors.unitValue && touchedFields.unitValue}>
        <FormLabel htmlFor="recurrence">Recurrence</FormLabel>
        <Select
          {...register('recurrence')}
          placeholder="Select Recurrence"
          cursor="pointer"
          disabled={!canEdit}
        >
          <option value="Once" className={'cursor-pointer'}>
            Once
          </option>
          <option value="Daily" className={'cursor-pointer'}>
            Daily
          </option>
          <option value="Weekly" className={'cursor-pointer'}>
            Weekly
          </option>
          <option value="Biweekly" className={'cursor-pointer'}>
            Biweekly
          </option>
          <option value="Monthly" className={'cursor-pointer'}>
            Monthly
          </option>
        </Select>
        <FormErrorMessage>{errors?.recurrence?.message}</FormErrorMessage>
      </FormControl>
      {recurrence === 'Once' && (
        <div className="flex flex-row gap-2 dark:bg-slate-200">
          <FormControl
            mb={4}
            isInvalid={
              (errors.startDate && touchedFields.startDate) ||
              (errors.endDate && touchedFields.endDate)
            }
          >
            <Datepicker
              containerClassName="dark-datepicker"
              inputClassName="relative w-full bg-input border border-input rounded text-white p-2"
              useRange={false}
              value={{ startDate, endDate }}
              onChange={handleValueChange}
              readOnly={true}
              displayFormat={'MM/DD/YYYY'}
              separator={'to'}
              disabled={!canEdit || recurrence !== 'Once'}
            />
            <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
            <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
          </FormControl>
        </div>
      )}
      <div className="flex flex-row gap-2">
        <FormControl mb={4} isInvalid={errors.startTime && touchedFields.startTime}>
          <FormLabel htmlFor="startTime">Start Time</FormLabel>
          <Select
            {...register('startTime')}
            placeholder="Select Start Time"
            cursor="pointer"
            disabled={!canEdit}
          >
            {startTimeIntervals.map((date) => {
              return (
                <option key={`startTime-${date}`} value={date} className={'cursor-pointer'}>
                  {date}
                </option>
              )
            })}
          </Select>
          <FormErrorMessage>{errors?.startTime?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mb={4} isInvalid={errors.endTime && touchedFields.endTime}>
          <FormLabel htmlFor="endTime">End Time</FormLabel>
          <Select
            {...register('endTime')}
            placeholder="Select End Time"
            cursor="pointer"
            disabled={!canEdit}
          >
            {endTimeOptions.map((date) => {
              return (
                <option key={`endTime-${date}`} value={date} className={'cursor-pointer'}>
                  {date}
                </option>
              )
            })}
          </Select>
          <FormErrorMessage>{errors?.endTime?.message}</FormErrorMessage>
        </FormControl>
      </div>
      {!currentService._id && (
        <FormControl mb={4} isInvalid={errors.attachments && touchedFields.attachments}>
          <FormLabel htmlFor="attachments">Upload File</FormLabel>
          <Input
            id="attachments"
            type="file"
            multiple={true}
            className="cursor-pointer pt-1"
            {...register('attachments')}
            placeholder="Upload File"
            disabled={!canEdit}
          />
          <FormErrorMessage>{errors?.attachments?.message}</FormErrorMessage>
        </FormControl>
      )}

      <HStack flex={1} mt={8}>
        {canEdit && !id && (
          <Button
            text={`${isAdd ? `Add Service` : 'Update'}`}
            type={'submit'}
            disabled={!isValid || isSubmitting || (isAdd && photos.length < 1)}
            isLoading={isSubmitting}
          />
        )}
      </HStack>
    </form>
  )
}

export default CreateServiceForm
