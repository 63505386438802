import apiClient from '../http-clients/api.client'

export const createEvent = (eventData) => apiClient.post('/v1/event', eventData)

export const getEventAttendeesProfiles = (eventId) =>  apiClient.get(`/v1/event/${eventId}/attendees`)

export const getEventByLinkId = (linkId) =>  apiClient.get(`/v1/event/link-id/${linkId}`)

export const getEventById = (eventId) => apiClient.get(`/v1/event/${eventId}`)

export const getUserEvents = (userId) => apiClient.get(`/v1/event/by-user/${userId}`)

export const addEventAttendee = (eventId, userId) => apiClient.get(`/v1/event/attendee/${eventId}/${userId}`)
