import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { IoIosSearch } from 'react-icons/io'
import useServices from '../state/services'

const ServiceFilter = () => {
  const fetchCategories = useServices(state => state.fetchCategories)
  const categories = useServices(state => state.categories)
  const searchText = useServices(state=> state.filters.searchText)
  const category = useServices(state => state.filters.category)
  const setFilters = useServices(state => state.setFilters)
  const clearFilters = useServices(state => state.clearFilters)

  useEffect(() => {
    fetchCategories().then()
    // eslint-disable-next-line
  }, [])

  return (
    <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Box>
        {(searchText || category.name) && (
          <Text>{`Showing Results for ${searchText || ''}, ${category.name || ''}`}</Text>
        )}
      </Box>
      <Stack direction="row">
        <Button
          variant="link"
          onClick={clearFilters}
        >
          Clear
        </Button>
        <Select
          placeholder="Select Category"
          cursor="pointer"
          value={category.id || '-1'}
          onChange={({ target }) => setFilters({
              searchText,
              category: target.value ? categories.find((x) => x.id === target.value) : {},
            })
          }
        >
          {categories.map((cat) => {
            return (
              <option key={cat.id} value={cat.id} className={'cursor-pointer'}>
                {cat.name}
              </option>
            )
          })}
        </Select>
        <InputGroup minW={300}>
          <Input
            placeholder="Search services"
            value={searchText}
            onChange={(event) => {
              setFilters({ searchText: event.target.value, category })
            }}
          />
          <InputRightElement children={<IoIosSearch />} />
        </InputGroup>
      </Stack>
    </Flex>
  )
}

export default ServiceFilter
