const radii = {
  none: '0',
  sm: '0.125rem',
  base: '0.75rem',
  md: '6px',
  lg: '12px',
  xl: '18px',
  '2xl': '2.5rem',
  '3xl': '3.5rem',
  full: '9999px',
}

export default radii
