import {
  Avatar,
  Box,
  Collapse,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import Thread from './Thread'
import Calendar from 'react-calendar'
import { GlobalContext } from '../../index'
import { BiArrowBack } from 'react-icons/bi'
import { useContext, useEffect } from 'react'
import { useHandleMessageSearch } from './utils/Utils'
import { BsFillCalendarEventFill } from 'react-icons/bs'
import ShowSearchMessage from './components/SearchMessage'
import { format, formatDistanceToNowStrict } from 'date-fns'
import MessageForwardModal from './components/MessageForwardModal'

function MessageThreads({
  setShowNewMessage,
  setCurrentThread,
  setCurrentContact,
  initCurrentContact,
  setFilteredUserContacts,
  userContacts,
  setSearchUserTex,
  CalendarContainer,
  threadsSorted,
  onReceiverSelected,
  setScrollMessageId,
  isLoading,
  showPostForwardModal,
  onMessageForward,
  isMessageForward,
  handleForwardPost,
  showNewMessage,
  lastMessagePrompt,
  setLastMessagePrompt,
  currentContact,
  scrollChatToBottom,
  onUpdateDeleteDate,
  onDeleteThread,
  handleCloseThread,
  width,
  isMobile = false,
  setShowThread = undefined,
}) {
  let indexGlobal = 0

  const {
    isSearchLoading,
    userSearchResult,
    searchResult,
    searchText,
    setSearchText,
    isSearchTouched,
    setIsSearchTouched,
    isSearch,
    setIsSearch,
    debouncedMessageSearch,
    searchDate,
    onChange,
    handleClearSearch,
  } = useHandleMessageSearch()

  const { isOpen, onToggle, onClose } = useDisclosure()
  const { userInfo, forwardMessage, setForwardMessage } = useContext(GlobalContext)

  useEffect(() => {
    if (isSearchTouched && !searchText) {
      handleClearSearch()
      setIsSearch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, isSearchTouched])

  const handleDate = (day) => {
    let today = new Date()
    return new Date(new Date().setDate(today.getDate() - day))
  }

  return (
    <section
      className={
        isMobile
          ? 'fixed bg-gray-600 h-full w-4/5 overflow-auto hover:w-64 transition-all duration-300 ease-in-out'
          : 'flex md:flex-col md:flex-none overflow-auto hover:w-64 group lg:max-w-sm md:w-2/5 transition-all duration-300 ease-in-out'
      }
    >
      <div className="header p-4 flex flex-row justify-between items-center flex-none">
        <div className="flex items-center">
          {width < 768 && (
            <div className="flex pr-4">
              <button onClick={handleCloseThread}>
                <BiArrowBack size={20} />
              </button>
            </div>
          )}
          <p className=" text-2xl font-bold group-hover:block">Messages</p>
        </div>
        <button
          onClick={() => {
            setShowNewMessage(true)
            setCurrentThread({})
            setCurrentContact(initCurrentContact)
            setFilteredUserContacts(userContacts)
            setSearchUserTex('')
          }}
          className="block rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 md:block group-hover:block cursor-pointer"
        >
          <svg viewBox="0 0 24 24" className="w-full h-full fill-current">
            <path d="M6.3 12.3l10-10a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7zM8 16h2.59l9-9L17 4.41l-9 9V16zm10-2a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h6a1 1 0 0 1 0 2H4v14h14v-6z" />
          </svg>
        </button>
      </div>
      <div className="search-box p-4 flex-none hidden">
        <form onSubmit={() => {}}>
          <div className="relative">
            <label>
              <input
                className="rounded-full py-2 pr-6 pl-10 w-full border border-gray-800 focus:border-gray-700 bg-gray-800 focus:bg-gray-900 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in"
                type="text"
                placeholder="Search Messenger"
              />
              <span className="absolute top-0 left-0 mt-2 ml-3 inline-block">
                <svg viewBox="0 0 24 24" className="w-6 h-6">
                  <path
                    fill="#bbb"
                    d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                  />
                </svg>
              </span>
            </label>
          </div>
        </form>
      </div>
      <div className="flex relative">
        <Popover isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <div className="ml-4 mr-2">
              <button
                className="block rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 md:block group-hover:block cursor-pointer"
                onClick={onToggle}
              >
                <BsFillCalendarEventFill className="w-full h-full fill-current" />
              </button>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <CalendarContainer>
                <Calendar
                  onChange={(date) => {
                    onChange(date)
                    debouncedMessageSearch(searchText, format(date, 'yyyy-MM-dd'))
                    onToggle()
                  }}
                  value={searchDate}
                  maxDate={new Date()}
                  minDate={handleDate(30)}
                />
              </CalendarContainer>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <div className="relative flex items-center w-full mr-4 h-full group">
          <svg
            className="absolute left-0 z-20 hidden w-4 h-4 ml-4 text-gray-500 pointer-events-none fill-current group-hover:text-gray-400 sm:block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
          </svg>
          <input
            type="text"
            className="block w-full py-1.5 pl-10 pr-4 leading-normal rounded-2xl focus:border-transparent border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-opacity-90 bg-gray-800 dark:bg-gray-800 text-gray-400 aa-input"
            placeholder="Find messages..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
              setIsSearchTouched(true)
              debouncedMessageSearch(event.target.value, searchDate)
            }}
          />
        </div>
      </div>
      {isSearch ? (
        <ShowSearchMessage
          setIsSearch={setIsSearch}
          searchResult={searchResult}
          userSearchResult={userSearchResult}
          handleClearSearch={handleClearSearch}
          isSearchLoading={isSearchLoading}
          threadsSorted={threadsSorted}
          onReceiverSelected={onReceiverSelected}
          setScrollMessageId={setScrollMessageId}
        />
      ) : (
        <div className="contacts p-2 mt-4 flex-1 overflow-y-scroll">
          {forwardMessage !== '' && !isLoading && (
            <div className="py-2 overflow-hidden">
              {showPostForwardModal && (
                <MessageForwardModal
                  onMessageForward={onMessageForward}
                  isMessageForward={isMessageForward}
                  onClose={() => setForwardMessage('')}
                  handleForwardPost={handleForwardPost}
                  isPost={true}
                />
              )}
            </div>
          )}
          {isLoading ? (
            <Stack>
              <Skeleton isLoaded={!isLoading} height="90px" />
              <Skeleton isLoaded={!isLoading} height="90px" />
              <Skeleton isLoaded={!isLoading} height="90px" />
              <Skeleton isLoaded={!isLoading} height="90px" />
              <Skeleton isLoaded={!isLoading} height="90px" />
            </Stack>
          ) : (
            <>
              <Collapse in={showNewMessage} unmountOnExit animateOpacity>
                <div className="flex items-center p-3 bg-gray-700 hover:bg-gray-800 rounded-lg relative cursor-pointer">
                  <div className="w-16 h-16 relative flex flex-shrink-0 items-center">
                    <Avatar bg="gray.500" size={'lg'} />
                  </div>
                  <div className="flex flex-row flex-1 min-w-0 ml-4 mr-2 group-hover:block justify-between">
                    <p>New Message</p>
                  </div>
                </div>
              </Collapse>
              <Box className="cursor-pointer">
                {threadsSorted.map((messageThread, index) => {
                  let contact = initCurrentContact
                  if (userInfo.id === messageThread?.receiver?._id) {
                    contact = messageThread?.sender
                  } else {
                    contact = messageThread?.receiver
                  }
                  let relativeTime = ''
                  if (messageThread?.lastMessage?.updatedAt) {
                    relativeTime = formatDistanceToNowStrict(
                      new Date(messageThread?.lastMessage?.updatedAt),
                      { addSuffix: false }
                    )
                  }
                  let delDate =
                    userInfo.id === messageThread.senderId
                      ? messageThread?.senderIdDeleteDate
                      : messageThread.receiverIdDeleteDate

                  let isShown = new Date(delDate) > new Date(messageThread?.lastMessage?.updatedAt)

                  if (index === indexGlobal && !lastMessagePrompt && !isShown) {
                    setLastMessagePrompt(true)
                    onReceiverSelected(messageThread, contact)
                  } else if (index === indexGlobal) {
                    indexGlobal++
                  }
                  if (!isShown) {
                    return (
                      <Thread
                        key={messageThread?._id}
                        messageThread={messageThread}
                        contact={contact}
                        relativeTime={relativeTime}
                        onReceiverSelected={onReceiverSelected}
                        currentContact={currentContact}
                        scrollChatToBottom={scrollChatToBottom}
                        onUpdateDeleteDate={onUpdateDeleteDate}
                        onDeleteThread={onDeleteThread}
                        setShowThread={setShowThread}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </Box>
            </>
          )}
        </div>
      )}
    </section>
  )
}

export default MessageThreads
