import React, { useEffect, useState } from 'react'
import { getUsersByList } from '../services/user.service'
import UserLikeView from './UserLikeView'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'

const LikesModal = ({ onClose, likes }) => {
  const [users, setUsers] = useState([])
  const toast = useToast()

  useEffect(() => {
    const onLoadHandle = async () => {
      try {
        const { data } = await getUsersByList(likes)
        if (data) {
          setUsers(data)
        }
      } catch (error) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
    onLoadHandle()
  }, [likes, toast])

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent pt={4}>
        <ModalHeader textAlign={['center']}>People Who Liked Your Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <div className="flex ml-6 py-2 flex-col">
            {users.map((user) => (
              <div className="py-2">
                <UserLikeView name={user.name} userName={user.username} profilePhoto={user.photo} />
              </div>
            ))}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LikesModal
