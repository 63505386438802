import { Button, Flex, FormControl, FormLabel, HStack, IconButton, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, Textarea, VStack, useClipboard } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getEventAttendeesProfiles, getEventById } from '../../../services/event.service'
import AttendeeAdded from './AttendeeAdded'
import { BsFillCalendarEventFill } from 'react-icons/bs'
import { format, parseISO } from 'date-fns'
import { getDayDifference } from '../../../utils/calendar-utils'
import { BiCopy } from 'react-icons/bi'

const EventDetailsModal = ({ isOpen, onClose, eventId }) => {

    const [attendees, setAttendees] = useState([])
    const [eventData, setEventData] = useState([])
    const [isLoading, setLoading] = useState(true)
    const { hasCopied, onCopy } = useClipboard(eventData?.link)

    const getDateFormat = date => {
        const parsedDate = parseISO(date)
        return  format(parsedDate, 'EEEE, MMMM dd')
    }

    const getTimeFormat = (date) => {
      const parsedDate = parseISO(date)
      return format(parsedDate, 'hh:mm a')
    }

    useEffect(() => {
      setLoading(true)
      const loadEventInformation = async () => {
        if(eventId && eventId !== ""){
            const {
            data: { event },
            } = await getEventById(eventId)

            const {
            data: { attendees },
            } = await getEventAttendeesProfiles(eventId)
            setAttendees(attendees)
            setEventData(event)
            setLoading(false)
        }
      }
      loadEventInformation()
    }, [eventId])

    return (
      <Modal
        size="3xl"
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
        bgColor="white"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Event Details</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose()
            }}
          />
          <ModalBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <div>
                  <VStack spacing={4}>
                    <Flex w="100%" justifyContent="space-between" alignItems="center">
                      <HStack spacing={2}>
                        <BsFillCalendarEventFill className="w-8 h-10" />
                        <Text fontSize="sm">
                          <strong>{getDateFormat(eventData?.startDateTimeUTC)}</strong>
                        </Text>
                        {eventData?.startDateTimeUTC &&
                          eventData?.endDateTimeUTC &&
                          getDayDifference(
                            parseISO(eventData?.startDateTimeUTC),
                            parseISO(eventData?.endDateTimeUTC)
                          ) !== 1 && (
                            <>
                              <Text fontSize="sm"> - </Text>
                              <Text fontSize="sm">
                                <strong>{getDateFormat(eventData?.endDateTimeUTC)}</strong>
                              </Text>
                            </>
                          )}
                      </HStack>
                      {!eventData?.isAllDay ? (
                        <HStack>
                          <Text>{getTimeFormat(eventData?.startDateTimeUTC)}</Text>
                          <Text> to </Text>
                          <Text>{getTimeFormat(eventData?.endDateTimeUTC)}</Text>
                        </HStack>
                      ) : (
                        <HStack>
                          <Text>{"All Day"}</Text>
                        </HStack>
                      )}
                    </Flex>
                    <FormControl>
                      <FormLabel>Title</FormLabel>
                      <Input defaultValue={eventData?.title} readOnly />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Recurrence</FormLabel>
                      <Input defaultValue={eventData?.recurrencePattern} readOnly />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Description</FormLabel>
                      <Textarea
                        placeholder="Event description..."
                        defaultValue={eventData?.description}
                        readOnly
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Link</FormLabel>
                      <InputGroup>
                        <Input defaultValue={eventData?.link} />
                        <InputRightElement width="4.5rem">
                          <IconButton
                            aria-label="Copy to clipboard"
                            icon={<BiCopy />}
                            onClick={onCopy}
                            colorScheme="teal"
                            size="sm"
                          >
                            {hasCopied ? 'Copied' : 'Copy'}
                          </IconButton>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Attendees</FormLabel>
                      <div>
                        <div className="flex flex-wrap gap-2 pt-4">
                          {attendees?.map((attendee, i) => (
                            <AttendeeAdded key={`${i}`} userProfile={attendee} disabled={true} />
                          ))}
                        </div>
                      </div>
                    </FormControl>
                  </VStack>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="white"
              colorScheme="ghost"
              mr={3}
              onClick={() => {
                onClose()
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}

export default EventDetailsModal
