import React from 'react'
import { truncate } from '../../../utils/string-utils'
import profilePicture from '../../../assets/svgs/avtar-rect.svg'


const ConnectionsProfile = ({ userProfile, onSelect = () => {} }) => {
  const { photo, name = [] } = userProfile

  return (
    <div
      className="flex mr-6 cursor-pointer"
      onClick={() => {
        onSelect(userProfile)
      }}
    >
      <div>
        <img className="inline-block h-12 w-12 rounded-full" src={photo || profilePicture} alt="" />
        <p className="text-sm leading-6 font-medium text-white">{truncate(name, 8)}</p>
      </div>
    </div>
  )
}

export default ConnectionsProfile
