import { useState, useEffect } from 'react'
import { Icon, Text } from '@chakra-ui/react'
import { ImFilesEmpty } from 'react-icons/im'

const FilePreview = ({ file }) => {
  const [fileValue, setFileValue] = useState(null)

  useEffect(() => {
    setFileValue(file)
  }, [file])

  const renderPreview = (value, type) => {
    switch (type) {
      case 'gif':
        return renderImage(value)
      case 'photo':
        return renderImage(value)
      case 'video':
        return renderVideo(value)
      case 'audio':
        return renderAudio(value)
      case 'pdf':
        return renderPDF(value)
      default:
        return renderFile(value)
    }
  }

  const renderImage = (value) => {
    return <img width="100%" height="100%" alt="" src={value?.original} />
  }

  const renderVideo = (value) => {
    return (
      <video width="100%" height="100%">
        <source src={value?.original} type="video/mp4" />
      </video>
    )
  }

  const renderAudio = (value) => {
    return (
      <audio
        style={{ background: 'transparent' }}
        id="audio_example"
        className="video-js vjs-default-skin"
        controls
        preload="auto"
        data-setup="{}"
      >
        <source src={value?.original} type="audio/mp3" />
      </audio>
    )
  }

  const renderFile = (value) => {
    return (
      <div>
        <Icon as={ImFilesEmpty} boxSize={20} />
        <Text fontSize="sm">{value?.name}</Text>
      </div>
    )
  }

  const renderPDF = (value) => {
    return <embed src={value?.original} type="application/pdf" width="100%" height="100%" />
  }

  return <div>{renderPreview(fileValue, fileValue?.type)}</div>
}

export default FilePreview
