import { FiShoppingBag } from 'react-icons/fi'
import { IoIosStarOutline } from 'react-icons/io'
import { MdHomeRepairService } from 'react-icons/md'
import { create } from 'zustand'
import { deleteService, getCategories, getServices } from '../services/market.service'
import { addToFavorite, removeFavorite } from '../services/user.service'

export const sideMenu = [
  {
    name: 'all',
    label: 'All Listings',
    icon: () => <FiShoppingBag size={24} />,
  },
  {
    name: 'my_services',
    label: 'My Services',
    icon: () => <MdHomeRepairService size={24} />,
  },
  {
    name: 'myFav',
    label: 'My Favorites',
    icon: () => <IoIosStarOutline size={24} />,
  },
]

const initialFilters = {
  searchText: '',
  category: {},
}

const useServices = create((set, get) => ({
  list: [],
  my: [],
  categories: [],
  filters: initialFilters,
  currentMenu: sideMenu[0],
  isServicesLoaded: false,
  isLoading: true,
  showShareModal: false,
  serviceId: '',
  onShareService: (id) => {
    set({ serviceId: id, showShareModal: true })
  },
  clearShareState: () => {
    set({ serviceId: '', showShareModal: false })
  },
  setCurrentMenu: (value) => {
    set({ currentMenu: value })
  },
  setFilters: (value) => {
    set({ filters: value })
  },
  clearFilters: () => {
    set({ filters: initialFilters })
  },
  fetchServices: async (searchText = '', categoryId = '', isMy = false, isFavorite = false) => {
    const { data } = await getServices(searchText, categoryId, isMy)
    const list = isFavorite ? data.filter((item) => item.isFavorite) : data
    set({ list, isServicesLoaded: true, isLoading: false })
  },
  fetchCategories: async () => {
    const { data: categories } = await getCategories()
    set({ categories })
  },
  getMyServices: async () => {
    const { data } = await getServices('', '', true)
    set({ my: data })
  },
  serviceDelete: async (id = '') => {
    await deleteService(id)
    const state = get()
    set({ list: state.list.filter((item) => item.id !== id) })
  },
  onServiceFavorite: async (id, isFavorite) => {
    isFavorite ? await removeFavorite(id) : await addToFavorite(id)
    const state = get()
    state.currentMenu.name === 'myFav'
      ? set({ list: state.list.filter((item) => item.id !== id) })
      : set({
          list: state.list.map((item) =>
            item.id === id ? { ...item, isFavorite: !isFavorite } : item
          ),
        })
  },
}))

export default useServices
