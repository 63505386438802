import { Center, Flex, Icon, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ImFilesEmpty } from 'react-icons/im'
import ImageCarousel from './ImageCarousel/ImageCarousel'
import RenderImage from './RenderImage/RenderImage'
import { VideoPlayer } from './VideoPlayer'

const PostCarousel = ({
  attachments,
  startIndex = 0,
  useLocalPDFViewer = false,
  setCurrentIndex = (index) => {},
}) => {
  const [files, setFiles] = useState([])
  useEffect(() => {
    let updated = attachments || []
    updated = updated.map((file) => {
      return {
        ...file,
        renderItem:
          file.type === 'photo' || file.type === 'gif'
            ? (value) => renderImage(value)
            : (value) => renderPreview(value, file.type),
      }
    })
    setFiles(updated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments])

  const renderImage = (value) => {
    return <RenderImage photo={value.thumbnail} />
  }

  const renderPreview = (value, type) => {
    switch (type) {
      case 'video':
        return renderVideo(value)
      case 'audio':
        return renderAudio(value)
      case 'pdf':
        return renderPDF(value)
      default:
        return renderFile(value)
    }
  }

  const renderVideo = (value) => {
    const options = { sources: [{ src: value.original, type: 'video/mp4' }], controls: true }
    return (
      <Flex justifyContent={'center'} px={8}>
        <VideoPlayer options={options} playerClassName={'vjs-big-play-centered vjs-fluid'} />
      </Flex>
    )
  }

  const renderAudio = (value) => {
    return (
      <Center
        px={8}
        sx={{
          '.vjs-big-play-button': {
            display: 'none',
          },
          '.vjs-control-bar': {
            display: 'flex',
          },
        }}
      >
        <VStack spacing={12}>
          <audio
            style={{ background: 'transparent', height: '125px' }}
            id="audio_example"
            className="video-js vjs-default-skin"
            controls
            preload="auto"
            data-setup="{}"
          >
            <source src={value.original} type="audio/mp3" />
          </audio>{' '}
          <Text>{value.fileName}</Text>
        </VStack>
      </Center>
    )
  }

  const renderFile = (value) => {
    return (
      <Center h={'full'} onClick={() => console.log('FILE CLICKED')}>
        <VStack spacing={12}>
          <Icon as={ImFilesEmpty} boxSize={20} />
          <Text>{value.fileName}</Text>
        </VStack>
      </Center>
    )
  }

  const renderPDF = (value) => {
    return (
      <Flex justifyContent={'center'} h={'full'} px={16}>
        <embed src={value.original} type="application/pdf" width="100%" height="100%"></embed>
      </Flex>
    )
  }

  return (
    <ImageCarousel
      photos={files}
      startIndex={startIndex}
      setCurrentIndex={setCurrentIndex}
      showThumbnails={false}
    />
  )
}

export default PostCarousel
