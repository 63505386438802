import React, { useContext, useEffect, useRef, useState } from 'react'
import Peer from 'simple-peer'
import {
  Box,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  SimpleGrid,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { GlobalContext } from '../../'
import { MdCallEnd } from 'react-icons/md'
import { IoMdMic, IoMdMicOff } from 'react-icons/io'

const Video = ({ peer }) => {
  const videoRef = useRef()

  useEffect(() => {
    peer.on('stream', (stream) => {
      videoRef.current.srcObject = stream
    })
  }, [peer])

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
  )
}

const createPeer = (userToSignal, callerID, stream, socket) => {
  const peer = new Peer({
    initiator: true,
    trickle: false,
    stream,
  })

  peer.on('signal', (signal) => {
    socket.emit('sending signal', { userToSignal, callerID, signal })
  })

  return peer
}

const addPeer = (incomingSignal, callerID, stream, socket) => {
  const peer = new Peer({
    initiator: false,
    trickle: false,
    stream,
  })

  peer.on('signal', (signal) => {
    socket.emit('returning signal', { signal, callerID })
  })

  peer.signal(incomingSignal)

  return peer
}

const EventCallRoom = ({ roomID, onEnd }) => {
  const [peers, setPeers] = useState([])
  const [muted, setMuted] = useState(false)

  const { socket } = useContext(GlobalContext)

  const peersRef = useRef([])
  const userVideoRef = useRef()

  const toggleMute = () => setMuted(!muted)

  const endCall = () => {
    peers.forEach((peer) => peer.destroy())
    setPeers([])
    onEnd()
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
      if (userVideoRef.current) {
        userVideoRef.current.srcObject = stream
      }

      const setupSocketListeners = (stream) => {
        socket.emit('join room', roomID)

        socket.on('all users', (users) => {
          const newPeers = users.map((userID) => {
            const peer = createPeer(userID, socket.id, stream, socket)
            peersRef.current.push({ peerID: userID, peer })
            return peer
          })
          setPeers(newPeers)
        })

        socket.on('user joined', (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream, socket)
          peersRef.current.push({ peerID: payload.callerID, peer })
          setPeers((prevPeers) => [...prevPeers, peer])
        })

        socket.on('receiving returned signal', (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id)
          if (item) {
            item.peer.signal(payload.signal)
          }
        })
      }

      setupSocketListeners(stream)
    })
  }, [roomID, socket])

  return (
    <Modal onClose={endCall} size="full" isOpen={true}>
      <ModalContent m={0} borderRadius={0} maxWidth="100vw" maxHeight="100vh" overflow="hidden">
        <ModalBody>
          <Grid
            templateAreas={`"main"`}
            gridTemplateRows="50px 1fr"
            gridTemplateColumns="1fr"
            h="100%"
            gap={0}
          >
            <GridItem area="main" style={{ position: 'relative' }}>
              <SimpleGrid columns={Math.ceil(Math.sqrt(peers.length + 1))} spacing={0} h="100%">
                <Box height="full">
                  <video
                    ref={userVideoRef}
                    playsInline
                    autoPlay
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      position: 'relative',
                      zIndex: 1,
                    }}
                  />
                </Box>
                {peers.map((peer, index) => (
                  <Box key={index} height="full" style={{ position: 'relative', zIndex: 1 }}>
                    <Video peer={peer} />
                  </Box>
                ))}
              </SimpleGrid>
            </GridItem>
          </Grid>

          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            p={4}
            bg="gray.800"
            borderTop="1px solid"
            borderColor="gray.300"
            style={{ zIndex: 2 }}
          >
            <HStack justifyContent="center" spacing={4}>
              <IconButton
                rounded="full"
                bg="white"
                aria-label={muted ? 'Unmute' : 'Mute'}
                size="lg"
                icon={
                  muted ? (
                    <IoMdMicOff color="black" size={26} />
                  ) : (
                    <IoMdMic color="black" size={26} />
                  )
                }
                onClick={toggleMute}
              />
              <IconButton
                rounded="full"
                bg="red.500"
                aria-label="End Call"
                size="lg"
                icon={<MdCallEnd color="white" size={24} />}
                onClick={endCall}
              />
            </HStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EventCallRoom
