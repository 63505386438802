import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useClipboard,
  Select,
  Switch,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsFillCalendarEventFill,  } from 'react-icons/bs'
import { BiCopy } from 'react-icons/bi'
import { GlobalContext } from '../../../'
import { createEvent } from '../../../services/event.service'
import useServices from '../../../state/services';


import {
  buildIntervals,
  changeDateHours,
  getDayDifference,
} from '../../../utils/calendar-utils'

import './style.css'
import CalendarAttendesAdd from './CalendarAttendeesAdd'

const frequencies = [
  'Once',
  'Daily',
  'Every weekday (Monday to Friday)',
  'Weekly',
  'Monthly',
  'Anually',
]

const EventModal = ({ isOpen, onClose, selectedDate, fetchEvents, link }) => {
  const [toIntervals, setToIntervals] = useState([])
  const my = useServices(state => state.my)
  const [attendeesList, setAttendeesList] = useState([])
  const getMyServices = useServices(state => state.getMyServices)

  const toast = useToast()
  const { userInfo } = useContext(GlobalContext)
  const { hasCopied, onCopy } = useClipboard(link)

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()

  const allDay = watch('allDay', false)
  const from = watch('from') || '12:00 am'
  const to = watch('to') || '12:15 am'

  const timeIntervals = useMemo(() => buildIntervals(), [])

  useEffect(() => {
    getMyServices().then()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (from !== undefined) {
      if (from === '11:45 pm') {
        setToIntervals(['12:00am'])
      } else {
        const index = timeIntervals.indexOf(from)
        const nextIntervals = timeIntervals.slice(index + 1)
        setToIntervals([...nextIntervals])
      }
    } else {
      setToIntervals(timeIntervals.slice(1))
    }
    // eslint-disable-next-line
  }, [from])

  const onSubmit = async (data) => {
    try {
      await createEvent({
        title: data.title,
        description: data.description,
        startDateTimeUTC: data.allDay
          ? selectedDate.start
          : changeDateHours(selectedDate.start, from),
        endDateTimeUTC: data.allDay
          ? selectedDate.end
          : changeDateHours(
              getDayDifference(selectedDate?.start, selectedDate?.end) > 1
                ? selectedDate.end
                : selectedDate.start,
              to
            ),
        link,
        isAllDay: data.allDay,
        isRecurring: data.recurrence !== 'Once',
        recurrencePattern: data.recurrence,
        createdAt: new Date().toJSON(),
        updatedAt: new Date().toJSON(),
        service: data.service,
        createdBy: userInfo.id,
        attendees: attendeesList?.map((profile) => profile.id) || [],
      })
      toast({
        duration: 5000,
        status: 'success',
        position: 'top-right',
        variant: 'solid',
        title: 'Event Created Successfully',
      })
      fetchEvents()
      window.location.reload()
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setAttendeesList([])
      onClose()
    }
  }

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
        reset()
        setAttendeesList([])
      }}
      bgColor="white"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Event</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose()
            reset()
            setAttendeesList([])
          }}
        />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)} action="#" autoComplete="off">
            <VStack spacing={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel>All day</FormLabel>
                <Switch size="lg" {...register('allDay')} />
              </FormControl>
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <HStack spacing={2}>
                  <BsFillCalendarEventFill className="w-8 h-10" />
                  <Text fontSize="sm">
                    <strong>
                      {selectedDate?.start.toLocaleString('default', {
                        weekday: 'long',
                        month: 'long',
                        day: '2-digit',
                      })}
                    </strong>
                  </Text>
                  {selectedDate?.start &&
                    selectedDate?.end &&
                    getDayDifference(selectedDate?.start, selectedDate?.end) !== 1 
                    && (
                      <>
                        <Text fontSize="sm"> - </Text>
                        <Text fontSize="sm">
                          <strong>
                            {selectedDate?.end.toLocaleString('default', {
                              weekday: 'long',
                              month: 'long',
                              day: '2-digit',
                            })}
                          </strong>
                        </Text>
                      </>
                    )}
                </HStack>
                <HStack>
                  <Select
                    defaultValue={'12:00 am'}
                    disabled={allDay}
                    size="md"
                    {...register('from')}
                  >
                    {timeIntervals.map((interval, i) => (
                      <option key={`interval-1-${i}`} value={interval}>
                        {interval}
                      </option>
                    ))}
                  </Select>
                  <Text> to </Text>
                  <Select defaultValue={'12:15 am'} disabled={allDay} size="md" {...register('to')}>
                    {toIntervals.map((interval, i) => (
                      <option key={`interval-1-${i}`} value={interval}>
                        {interval}
                      </option>
                    ))}
                  </Select>
                </HStack>
              </Flex>
              <FormControl isInvalid={errors.title}>
                <FormLabel>Title</FormLabel>
                <Input
                  placeholder="Event title..."
                  {...register('title', {
                    required: 'This field is required',
                    minLength: {
                      value: 4,
                      message: 'Minimum length should be 4',
                    },
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Recurrence</FormLabel>
                <Select size="md" {...register('recurrence')}>
                  {frequencies.map((frequency) => (
                    <option value={frequency}>{frequency}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea placeholder="Event description..." {...register('description')} />
              </FormControl>
              <FormControl>
                <FormLabel>Link</FormLabel>
                <InputGroup>
                  <Input value={link}/>
                  <InputRightElement width="4.5rem">
                    <IconButton
                      aria-label="Copy to clipboard"
                      icon={<BiCopy />}
                      onClick={onCopy}
                      colorScheme="teal"
                      size="sm"
                    >
                      {hasCopied ? 'Copied' : 'Copy'}
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Associated Service</FormLabel>
                <Select size="md" {...register('service')}>
                  <option value=''>None</option>
                  {my.map((service) => (
                    <option key={service._id} value={service._id}>{service.title}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Attendees</FormLabel>
                <CalendarAttendesAdd
                  attendesList={attendeesList}
                  setAttendesList={setAttendeesList}
                />
              </FormControl>
            </VStack>
            <Flex m="40px 0 15px 0px" justifyContent="flex-end" width="100%">
              <Button
                color="white"
                colorScheme="ghost"
                mr={3}
                onClick={() => {
                  onClose()
                  reset()
                  setAttendeesList([])
                }}
              >
                Cancel
              </Button>
              <Button colorScheme="blue" isLoading={isSubmitting} type="submit">
                Submit
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EventModal
