import React from 'react'
import icon404notfound from '../assets/pngs/ghost.png'
import { useHistory } from 'react-router'

const NotFound404 = () => {
  const history = useHistory()
  const onHome = () => {
    history.push('/')
  }
  return (
    <div className="flex flex-1 justify-center items-center h-full w-full">
      <div className="flex flex-col w-full max-w-md px-4 py-8  rounded-lg  dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center mb-2 text-4xl font-medium text-gray-400 sm:text-3xl dark:text-white">
          <p>This Page is a Ghost</p>
        </div>
        <div
          onClick={() => onHome()}
          className="self-center mb-2 py-4 text-4xl font-medium text-gray-400 sm:text-3xl cursor-pointer dark:text-white"
        >
          <img src={icon404notfound} alt="404 pge not found" />
        </div>
        <div className="self-center mb-2 text-sm font-medium text-gray-400 ">
          <p>
            Once alive and now dead, this ghost appears to have some unfinished business. Could it
            be with you?
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFound404
