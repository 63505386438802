import React, {useContext, useState} from 'react'
import { GlobalContext } from '../..'
import { login } from '../../services/user.service'
import Button from '../../components/Button'
import { useForm } from 'react-hook-form'
import { FaUserAstronaut } from 'react-icons/fa'
import useCheckLogIn from '../../hooks/useCheckLogIn'
import logoWithName from '../../assets/svgs/CoNectar_with_text.svg'
import { FormControl, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import { GoogleLogin } from '@react-oauth/google';
import useGoogleFetch from "../../hooks/useGoogleFetch";

const LogIn = ({ history }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()

 
  const [showPass, setShowPass] = useState(false)
  const [loginNoMatch, setLoginNoMatch] = useState(false)
  const { updateUserInfo } = useContext(GlobalContext)
  useCheckLogIn()

  const { handleGoogle} = useGoogleFetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/auth/signInGoogle`
  )
  const handleChange = () => {
    reset({ errors: false })
    setLoginNoMatch(false)
  }

  const validateFields = (password, usernameOrEmail) => {
    if (!password || !usernameOrEmail || password === '' || usernameOrEmail === '') {
      return false
    }
    return true
  }

  const handleOnSubmit = async (data) => {
    const { usernameOrEmail, password } = data
    let allValidated = validateFields(usernameOrEmail, password)
    if (!allValidated) return
    try {
      const response = await login(usernameOrEmail, password)
      localStorage.setItem('user', JSON.stringify(response.data))
      updateUserInfo()
      history.push({ pathname: '/' })
    } catch (error) {
      setLoginNoMatch(true)
    }
  }


  const responseMessage = (response) => {
    handleGoogle(response);
  }

  const errorMessage = (response) => {
    console.log(response);
  }

  return (
    <div className="flex flex-1 justify-center items-center h-full w-full">
      <div className="flex flex-col w-full max-w-md px-4 mx-3 pb-8 bg-white rounded-lg shadow-2xl dark:bg-gray-800 sm:mx-3 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center ">
          <img className="w-60 -mt-6 -mb-6" src={logoWithName} alt="logo" />
        </div>
        <p
          style={{
            visibility: loginNoMatch ? 'visible' : 'hidden',
          }}
          className="text-red-500 text-sm italic text-center"
        >
          {'Email or password invalid!'}
        </p>
        <div className="mt-2">
          <form onSubmit={handleSubmit(handleOnSubmit)} action="#" autoComplete="off">
            <FormControl isInvalid={errors.usernameOrEmail}>
              <div className="flex flex-col mb-6">
                <div className="flex relative mb-2">
                  <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-600 shadow-sm text-sm">
                    <FaUserAstronaut />
                  </span>
                  <InputGroup>
                    <input
                      type="text"
                      name="usernameOrEmail"
                      id="sign-in-email"
                      defaultValue=""
                      {...register('usernameOrEmail', {
                        required: 'Please enter your email or Co-Nectar username.',
                      })}
                      onChange={handleChange}
                      placeholder="Username or email"
                      className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    />
                  </InputGroup>
                </div>
                <FormErrorMessage>
                  {errors.usernameOrEmail && errors.usernameOrEmail.message}
                </FormErrorMessage>
              </div>
            </FormControl>
            <FormControl isInvalid={errors.password}>
              <div className="flex flex-col mb-6">
                <div className="flex relative mb-2">
                  <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-600 shadow-sm text-sm">
                    <svg
                      width="15"
                      height="15"
                      fill="currentColor"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                    </svg>
                  </span>
                  <InputGroup>
                    <input
                      type={showPass ? 'text' : 'password'}
                      name="password"
                      id="password"
                      defaultValue=""
                      {...register('password', {
                        required: "Password field can't be empty.",
                      })}
                      onChange={handleChange}
                      placeholder="Your password"
                      className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 pl-4 pr-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    />
                    <span
                      style={{ right: 13, top: '36%' }}
                      className="inline-flex items-center bg-white text-gray-500 cursor-pointer absolute"
                      onClick={() => setShowPass(!showPass)}
                    >
                      <i className={`far fa-eye${!showPass ? '-slash' : ''}`}></i>
                    </span>
                  </InputGroup>
                </div>
                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
              </div>
            </FormControl>
            <div className="flex items-center mb-6 -mt-4">
              <div className="flex ml-auto">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={() => {
                    history.push('/forgot-password')
                  }}
                  className="inline-flex text-xs font-medium text-gray-500 sm:text-sm
                  dark:text-gray-100 hover:text-gray-700 dark:hover:text-white"
                >
                  Forgot Your Password?
                </a>
              </div>
            </div>
            <div className={'flex w-full mb-4'}>
              <Button
                className={isSubmitting ? 'cursor-not-allowed' : 'cursor-pointer'}
                text={'Login'}
                type={'submit'}
                isLoading={isSubmitting}
              />
            </div>
          </form>

          <div className="flex justify-center flex-col items-center">
          <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />

          </div>
        </div>
        <div className="flex items-center justify-center mt-6">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="inline-flex items-center text-xs font-medium text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white"
            onClick={() => {
              history.push('/conectar-or-google')
            }}
          >
            <span className="ml-2">
              Don&#x27;t have an account? <span className={'text-purple-600'}>Signup</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default LogIn
