import React, { useEffect, useRef } from 'react'
import { Box, IconButton } from '@chakra-ui/react'
import useState from 'react-usestateref'
import { IoStopCircleSharp, IoPlayCircleSharp, IoPauseCircleSharp } from 'react-icons/io5'
import MicRecorder from 'mic-recorder-to-mp3'

const Mp3Recorder = new MicRecorder({ bitRate: 128 })

const formatTime = (t) => {
  let theMinutes = '0' + Math.floor(t / 60)
  let theSeconds = '0' + parseInt(t % 60)
  let theTime = theMinutes.slice(-2) + ':' + theSeconds.slice(-2)
  return theTime
}

const AudioRecorder = ({ recordStarted = false, onRecordChange, onRecordCancel }) => {
  const [, setDuration, durationRef] = useState(60)
  const [timer, setTimer, timerRef] = useState(0)
  const [audioURL, setAudioURL] = useState(null)
  const [isPaused, setIsPaused] = useState(true)
  const countRef = useRef(null)
  const audioPlayerRef = useRef(null)

  useEffect(() => {
    if (recordStarted) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          Mp3Recorder.start()
            .then(() => {
              startTimerProgress()
            })
            .catch((e) => {
              console.error(e)
              onRecordCancel()
            })
        })
        .catch((e) => {
          console.error(e)
          onRecordCancel()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordStarted])

  const startTimerProgress = () => {
    countRef.current = setInterval(() => {
      if (timerRef.current >= durationRef.current) {
        onStopRecord()
      } else {
        setTimer((timer) => timer + 0.1)
      }
    }, 100)
  }

  const onStopRecord = () => {
    clearInterval(countRef.current)
    setTimer(0)
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        setAudioURL(blobURL)
        onRecordChange(true, blob)
      })
      .catch((e) => console.log(e))
  }

  const recordWidth = (timer / durationRef.current) * 100

  return (
    <Box className="relative flex-grow rounded-full overflow-hidden">
      <audio
        ref={audioPlayerRef}
        style={{ display: 'none' }}
        src={audioURL}
        onEnded={() => setIsPaused(true)}
        onLoadedMetadata={({ target }) => {
          setDuration(Number(target.duration))
        }}
        controls={false}
      />
      <label className="">
        <Box
          style={{
            height: '40px',
          }}
          className="rounded-full py-2 pl-3 pr-10 w-full bg-purple-600 focus:bg-gray-900 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in"
          type="text"
          placeholder=""
        />
        <Box
          style={{
            top: 0,
            left: 0,
            width: `${recordWidth}%`,
            transition: 'width .5s ease',
            height: '40px',
          }}
          className="absolute w-full bg-purple-800 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in rounded-l-full"
          type="text"
          placeholder=""
        />
        <p
          style={{
            top: 6,
            right: 16,
          }}
          className="absolute font-bold"
        >
          {formatTime(timer)}
        </p>
        {audioURL ? (
          <IconButton
            position={'absolute'}
            cursor={'pointer'}
            left={1}
            top={1}
            mr={2}
            variant="unstyled"
            aria-label="Audio"
            as={isPaused ? IoPlayCircleSharp : IoPauseCircleSharp}
            w={8}
            h={8}
            color="red.400"
            onClick={() => {
              if (audioPlayerRef.current.paused) {
                const audioPlayPromise = audioPlayerRef?.current.play()
                if (audioPlayPromise !== undefined) {
                  audioPlayPromise
                    .then((_) => {
                      console.log('audio played auto')
                    })
                    .catch((_) => {
                      console.log('playback prevented')
                    })
                }
                setIsPaused(false)
                startTimerProgress()
              } else {
                setIsPaused(true)
                audioPlayerRef.current.pause()
                clearInterval(countRef.current)
              }
            }}
          />
        ) : (
          <IconButton
            position={'absolute'}
            cursor={'pointer'}
            left={1}
            top={1}
            mr={2}
            variant="unstyled"
            aria-label="Audio"
            as={IoStopCircleSharp}
            w={8}
            h={8}
            color="red.400"
            onClick={() => {
              onStopRecord()
            }}
          />
        )}
      </label>
    </Box>
  )
}

export default AudioRecorder
