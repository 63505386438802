import axios from 'axios'
import { setupInterceptorsTo } from '../utils/Interceptors'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 300000,
  headers: { 'X-Client-Version': '0.1.0' },
})

export default setupInterceptorsTo(instance)
