import { extendTheme } from '@chakra-ui/react'
import foundations from './foundations'

const direction = 'ltr'

const config = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
  cssVarPrefix: 'ckds',
}

export const theme = {
  components: {
    Button: {
      baseStyle: (props) => ({
        _focus: {
          boxShadow: 'none',
        },
      }),
    },
  },
  direction,
  ...foundations,
  config,
}

export default extendTheme(theme)
