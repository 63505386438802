import {
  Box,
  HStack,
  Button as ButtonChakra,
  Center,
  Icon,
  Text,
  VStack,
  useToast,
  Heading,
  Flex,
} from '@chakra-ui/react'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Button from '../../components/Button'
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel'
import { IoImageOutline, IoImages, IoRemove } from 'react-icons/io5'
import { BsChatDots } from 'react-icons/bs'
import { getCategories, getService } from '../../services/market.service'
import { GlobalContext } from '../..'
import CreateServiceForm from './CreateServiceForm'

const serviceValidationSchema = {
  title: Yup.string().max(64, 'Must be 64 characters or less').required('Required'),
  price: Yup.number('Must be a number').min(1, 'Must be greater than 0').required('Required'),
  description: Yup.string().max(500, 'Must be 500 characters or less').required('Required'),
  category: Yup.string().max(200, 'Must be 200 characters or less').required('Required'),
  location: Yup.string().max(200, 'Must be 200 characters or less').required('Required'),
  unit: Yup.string().max(200, 'Must be 200 characters or less').required('Required'),
  recurrence: Yup.string().max(200, 'Must be 200 characters or less').required('Required'),
  serviceFormat: Yup.string().max(200, 'Must be 200 characters or less').required('Required'),
}

const initialService = {
  title: '',
  price: 0,
  quantity: 1,
  description: '',
  condition: 'New',
  category: '',
  location: '',
  isActive: true,
  photos: [],
}

const CreateService = () => {
  const [service] = useState({
    id: null,
    photos: [],
    categoryId: '',
    isActive: true,
  })
  const { userInfo } = useContext(GlobalContext)
  const [photos, setPhotos] = useState([])
  const [validationSchema, setValidationSchema] = useState(serviceValidationSchema)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [categories, setCategories] = useState([])
  const history = useHistory()
  const fileInputRef = useRef(null)
  const [currentService, setCurrentService] = useState(initialService)
  const toast = useToast()
  const userId = userInfo.id
  const { id, service_id } = useParams()
  const isAdd = !service_id

  const [canEdit, setCanEdit] = useState(true)

  useEffect(() => {
    const canEdit = !!(
      (currentService?.id && userInfo.id === currentService?.userId) ||
      !!!currentService?.id
    )

    setCanEdit(canEdit)
  }, [currentService, userInfo])

  useEffect(() => {
    setPhotos(service.photos || [])
    onGetCategories()
    setValidationSchema(serviceValidationSchema)
    setCurrentService(initialService)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.photos])

  const onGetService = async () => {
    try {
      const service = await getService(service_id ? service_id : id)
      setCurrentService(service.data)
      let photosData = service.data.photos?.map((photo) => {
        return {
          original: photo,
          thumbnail: photo,
        }
      })
      setPhotos(photosData)
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }
  useEffect(() => {
    if (id || service_id) {
      onGetService()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, service_id])

  const onGetCategories = async () => {
    try {
      const { data: cats } = await getCategories()
      setCategories(cats)
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleOnPurchase = async (values) => {
    const { _id, ...others } = values
    try {
      history.push('/checkout', {
        service: {
          ...others,
          id: _id,
          quantity: 1,
        },
      })
    } catch (error) {}
  }

  const handleOnImagePick = (event) => {
    if (!event.target.files[0]) return

    let newPhotos = []
    Array.from(event?.target?.files)?.forEach((file) => {
      const generatedUrl = URL.createObjectURL(file)
      let updatedPhoto = {
        original: generatedUrl,
        thumbnail: generatedUrl,
        file,
      }
      newPhotos.push(updatedPhoto)
    })
    setPhotos([...photos, ...newPhotos])
  }

  return (
    <div className="flex flex-1 px-20 py-24 w-full">
      <div className="flex-1 flex-col w-full rounded-lg dark:bg-gray-800 ">
        {canEdit && !id ? (
          <div className="flex-1 self-center mb-7  text-3xl font-bold text-white sm:text-3xl dark:text-white">
            {`${isAdd ? 'Add' : 'Edit'} Service`}
          </div>
        ) : (
          <div className="flex-1 self-center mb-7 dark:text-white">
            <VStack align="left" gap={5}>
              <Heading mt={8}>{currentService.title}</Heading>
              <Text fontSize="md">{currentService.description}</Text>
            </VStack>
          </div>
        )}
        <HStack className="flex-1" alignItems="stretch">
          <Box
            display={'flex'}
            flexDirection={'column'}
            flex={2}
            mr={4}
            mt={2}
            alignItems={'stretch'}
            justifyContent={'stretch'}
          >
            {!id && (
              <HStack justifyContent="space-between" mb={2} display={isAdd ? 'flex' : 'none'}>
                <ButtonChakra
                  leftIcon={<IoImages />}
                  variant="outline"
                  onClick={() => {
                    fileInputRef?.current?.click()
                  }}
                >
                  Add Photos
                </ButtonChakra>
                <ButtonChakra
                  leftIcon={<IoRemove />}
                  variant="outline"
                  onClick={() => {
                    if (photos.length < 1) {
                      return
                    }

                    let updatedPhotos = photos.filter((x, index) => index !== currentImageIndex)
                    setPhotos(updatedPhotos || [])
                    setCurrentImageIndex(0)
                  }}
                >
                  Remove
                </ButtonChakra>
              </HStack>
            )}
            {!id ? (
              photos?.length < 1 ? (
                <Center
                  flex={1}
                  border={1}
                  borderColor={'gray.600'}
                  borderStyle={'solid'}
                  rounded="md"
                  cursor={'pointer'}
                  onClick={() => {
                    fileInputRef?.current?.click()
                  }}
                >
                  <VStack>
                    <Icon boxSize={40} as={IoImageOutline}></Icon>
                    <Text fontSize="lg" fontWeight="bold">
                      Add Photos
                    </Text>
                  </VStack>
                </Center>
              ) : (
                <div className="mt-6">
                  <ImageCarousel
                    photos={photos}
                    setCurrentIndex={(index) => {
                      setCurrentImageIndex(index)
                    }}
                  />
                </div>
              )
            ) : (
              <div className="mt-6">
                <ImageCarousel
                  photos={photos}
                  setCurrentIndex={(index) => {
                    setCurrentImageIndex(index)
                  }}
                />
              </div>
            )}
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              multiple
              id={Math.random().toString()}
              onChange={handleOnImagePick}
              onClick={(event) => {
                event.target.value = ''
              }}
              hidden
            />
          </Box>
          <Box flex={1}>
            {id && <Heading mt={6}>Service Information</Heading>}
            {id && (
              <Flex flexDirection="column" mt={8} gap={5}>
                <HStack>
                  <Text as="b" fontSize="lg">
                    Price:{' '}
                  </Text>
                  <Text>{currentService.price}$</Text>
                </HStack>
                <HStack>
                  <Text as="b" fontSize="lg">
                    Location:{' '}
                  </Text>
                  <Text>{currentService?.location}</Text>
                </HStack>
                <Text as="b" fontSize="lg">
                  Seller Details:{' '}
                </Text>
                <div className="flex justify-around">
                  <VStack mb={5} align="left">
                    <HStack>
                      <Text as="b" fontSize="lg">
                        Name:{' '}
                      </Text>
                      <Text>{currentService?.user?.name}</Text>
                    </HStack>
                    <HStack>
                      <Text as="b" fontSize="lg">
                        Email:{' '}
                      </Text>
                      <Text>{currentService?.user?.email}</Text>
                    </HStack>
                    <HStack>
                      <Text as="b" fontSize="lg">
                        Username:{' '}
                      </Text>
                      <Text
                        as="u"
                        cursor="pointer"
                        onClick={() => history.push(`/profile/${currentService?.user?.id}`)}
                      >
                        {currentService.user?.username}
                      </Text>
                    </HStack>
                  </VStack>
                  <div className="mb-5">
                    <img
                      className="w-24 h-24 rounded-full"
                      src={currentService?.user?.photo}
                      alt="sellerPic"
                    />
                  </div>
                </div>
                {userId !== currentService?.user?.id && (
                  <div className="grid grid-cols-2 gap-4">
                    <Button
                      text="Contract Service"
                      onClick={() => handleOnPurchase(currentService)}
                    />
                    <button
                      onClick={() => {
                        localStorage.removeItem('thread')
                        history.push('/messages', {
                          userInfo: {
                            id: currentService?.user?.id,
                          },
                        })
                      }}
                      className="flex justify-center items-center h-10 py-2 px-4  bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                    >
                      <BsChatDots className="mr-2" /> Contact Seller
                    </button>
                  </div>
                )}
              </Flex>
            )}
            {!id && (
              <CreateServiceForm
                isAdd={isAdd}
                canEdit={canEdit}
                photos={photos}
                userId={userId}
                currentService={currentService}
                validationSchema={validationSchema}
                id={id}
                categories={categories}
              />
            )}
          </Box>
        </HStack>
      </div>
    </div>
  )
}

export default CreateService
