import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../..'
import { getPosts, getPostSearch } from '../../services/post.service'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react'
import ConnectionRow from './ConnectionRow'
import { getUserSearch } from '../../services/user.service'
import RePost from './RePost'
import lostInSpace from '../../assets/pngs/Lost_Space.png'

const pageSize = 20
const SearchPage = ({ history }) => {
  const searchText = history?.location?.state?.searchText || ''
  const [, setHasMore] = useState(true)
  const [userProfiles, setUserProfiles] = useState([])
  const [postsSearch, setPostsSearch] = useState([])
  const { userInfo: currentUserInfo } = useContext(GlobalContext)
  const toast = useToast()

  useEffect(() => {
    onSearchUsers()
    onSearchPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onSearchUsers()
    onSearchPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const onSearchUsers = async () => {
    try {
      const { data } = await getUserSearch(searchText)
      if (data?.length < 1) {
        setHasMore(false)
      } else {
        setHasMore(true)
        setUserProfiles([...data])
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onSearchPosts = async () => {
    try {
      const { data } = await getPostSearch(searchText)
      if (data) {
        setPostsSearch(data)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const fetchMoreData = async () => {
    try {
      let pageIndex = Math.floor((userProfiles.length - 1) / pageSize)
      const { data } = await getPosts(pageSize, pageIndex + 1)
      if (data?.length < 1) {
        setHasMore(false)
      } else {
        setUserProfiles([...userProfiles, ...data])
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const resultsText = `Showing Results for "${searchText}"`

  return (
    <div id="scrollableDiv" className=" relative h-screen z-10 overflow-x-hidden overflow-y-auto">
      <div className="px-6 md:px-12 flex items-center py-36 w-full">
        <div className="flex flex-1 justify-center w-full">
          <header style={{ flex: 2 }} className="text-white py-4 h-auto">
            <div style={{}}></div>
          </header>
          <main style={{ flex: 6 }} role="main">
            <div className="flex flex-1" style={{}}>
              <section
                className="flex-4 border border-y-0 border-gray-800"
                style={{ flex: 4, flexBasis: 160 }}
              >
                <h1 className="p-4 text-white">{resultsText}</h1>
                <hr className="border-gray-800" />
                {postsSearch.length === 0 ? (
                  <div className="my-10 ">
                    <img className="m-auto h-96" src={lostInSpace} alt="lostInSpace" />
                    <div className="text-center my-4">
                      <h1>This search term is lost in space</h1>
                    </div>
                  </div>
                ) : (
                  <ul className="list-none">
                    <Tabs>
                      <TabList>
                        {postsSearch.length ? <Tab>{`Posts (${postsSearch.length})`}</Tab> : null}
                        {userProfiles.filter((user) => user.id !== currentUserInfo.id).length ? (
                          <Tab>{`Users (${
                            userProfiles.filter((user) => user.id !== currentUserInfo.id).length
                          })`}</Tab>
                        ) : null}
                      </TabList>

                      <TabPanels>
                        {postsSearch.length ? (
                          <TabPanel>
                            <InfiniteScroll
                              dataLength={postsSearch.length}
                              next={fetchMoreData}
                              hasMore={false}
                              loader={<h4 className="text-white text-center p-8">Loading...</h4>}
                              scrollableTarget="scrollableDiv"
                            >
                              {postsSearch && postsSearch.length > 0 ? (
                                postsSearch.map((post) => {
                                  return <RePost repostId={post?.id} />
                                })
                              ) : (
                                <h1 className="p-4 text-white">{'No Posts Found'}</h1>
                              )}
                            </InfiniteScroll>
                          </TabPanel>
                        ) : null}
                        {userProfiles.filter((user) => user.id !== currentUserInfo.id).length ? (
                          <TabPanel>
                            <InfiniteScroll
                              dataLength={userProfiles.length}
                              next={fetchMoreData}
                              hasMore={false}
                              loader={<h4 className="text-white text-center p-8">Loading...</h4>}
                              scrollableTarget="scrollableDiv"
                            >
                              {userProfiles
                                .filter((x) => x.id !== currentUserInfo.id)
                                .map((userProfile) => {
                                  return (
                                    <ConnectionRow
                                      key={userProfile.id}
                                      currentUserId={currentUserInfo.id}
                                      userProfile={{
                                        ...userProfile,
                                      }}
                                      onAddConnection={() => {
                                        onSearchUsers()
                                      }}
                                      onRemoveConnection={() => {
                                        onSearchUsers()
                                      }}
                                      onSelect={(userProfile) => {
                                        history.push(`/profile/${userProfile.id}`, {
                                          userObject: userProfile,
                                        })
                                      }}
                                    />
                                  )
                                })}
                              {!userProfiles ||
                                (userProfiles.filter((x) => x.id !== currentUserInfo.id) < 1 && (
                                  <h1 className="p-4 text-white">{'No Users Found'}</h1>
                                ))}
                            </InfiniteScroll>
                          </TabPanel>
                        ) : null}
                      </TabPanels>
                    </Tabs>
                  </ul>
                )}
              </section>

              <aside style={{ flex: 2 }} className="position-relative">
                <div style={{}}>
                  <div className="overflow-y-auto fixed h-screen"></div>
                </div>
              </aside>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default SearchPage
