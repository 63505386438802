import React from 'react'
import Button from '../../components/Button'
import { FaUserAstronaut } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import { FormControl, FormErrorMessage, InputGroup, useToast } from '@chakra-ui/react'
import { requestPasswordReset } from '../../services/user.service'
import useCheckLogIn from '../../hooks/useCheckLogIn'

const ForgotPassword = () => {
  useCheckLogIn()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()

  const toast = useToast()

  const usernameOrEmail = watch('usernameOrEmail')

  const onSubmit = async (data) => {
    try {
      const response = await requestPasswordReset({
        usernameOrEmail: data.usernameOrEmail,
      })
      if (response.data?.message === 'Created') {
        toast({
          duration: 8000,
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
          title: 'A Reset Password Link Has Been To Your Email',
          description: 'Please check your inbox messages.',
        })
      }
    } catch (_) {
      toast({
        duration: 8000,
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        title: 'Oops! Something Went Wrong',
        description: 'Please contact support at team@conectar.ch',
      })
    } finally {
      reset()
    }
  }

  return (
    <div className="flex flex-1 justify-center items-center h-full w-full">
      <div className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow-2xl dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center mb-2 text-4xl font-medium text-gray-600 sm:text-3xl dark:text-white">
          Forgot Password
        </div>
        <div className="self-center mb-4 text-sm font-medium text-gray-400 dark:text-white">
          Don't worry, happens to the best of us.
        </div>
        <div className="my-4">
          <form onSubmit={handleSubmit(onSubmit)} action="#" autoComplete="off">
            <FormControl isInvalid={errors.newPassword}>
              <div className="flex flex-col mb-6">
                <div className="flex relative mb-2">
                  <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-600 shadow-sm text-sm">
                    <FaUserAstronaut />
                  </span>
                  <InputGroup>
                    <input
                      type="text"
                      name="email"
                      onChange={null}
                      id="sign-in-email"
                      {...register('usernameOrEmail', {
                        required: 'Please enter your email or Co-Nectar username.',
                      })}
                      placeholder="Your email or Co-Nectar username"
                      className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    />
                  </InputGroup>
                </div>
                <FormErrorMessage>
                  {errors.usernameOrEmail && errors.usernameOrEmail.message}
                </FormErrorMessage>
              </div>
            </FormControl>
            <div className={'flex w-full'}>
              <Button
                className={!usernameOrEmail ? 'cursor-not-allowed' : 'cursor-pointer'}
                text="Email me a reset link"
                disabled={!usernameOrEmail}
                isLoading={isSubmitting}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
