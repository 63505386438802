import React, { useCallback, useContext, useEffect } from 'react'
import { GlobalContext } from '../..'
import { getLatestPosts, getPostsByConnection, getPublicPost } from '../../services/post.service'
import Button from '../../components/Button'
import CreatePost from './CreatePost'
import Post from './Post'
import InfiniteScroll from 'react-infinite-scroll-component'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import useState from 'react-usestateref'
import { useToast } from '@chakra-ui/react'
import { IoMdRefresh } from 'react-icons/io'

const pageSize = 10

const initialPost = {
  id: null,
  photos: [],
}

const Dashboard = () => {
  const [showCreatePostModal, setShowCreatePostModal] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [, setCurrentPost, currentPostRef] = useState(initialPost)
  const [posts, setPosts] = useState([])
  const [latestPosts, setLatestPosts] = useState([])
  const [latestPost, setLatestPost] = useState(null)
  const [latestPostBtn, setLatestPostBtn] = useState(false)
  const [repostId, setRepostId] = useState('')
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const {
    userInfo,
    audios: { snapSound },
  } = useContext(GlobalContext)
  const toast = useToast()

  const [, setSnapSoundRef, snapSoundRef] = useState(snapSound)

  useEffect(() => {
    if (snapSound) {
      setSnapSoundRef(snapSound)
    }
  }, [snapSound, setSnapSoundRef])

  const playSnapSound = () => {
    if (snapSoundRef.current) {
      snapSoundRef.current.muted = false
      const audioPlayPromise = snapSoundRef?.current.play()
      if (audioPlayPromise !== undefined) {
        audioPlayPromise.then((_) => {}).catch((_) => {})
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const postId = latestPost._id || latestPost.id
      onGetLatestPosts(postId).then((res) => {
        if (res.length) {
          clearInterval(interval)
          setLatestPostBtn(true)
        }
      })
    }, 1000 * 60 * 5)

    return () => clearInterval(interval)
  }, [latestPost])

  useEffect(() => {
    if (posts.length) {
      const latestPost = posts[0]
      setLatestPost(latestPost)
    }
  }, [posts])

  const onGetLatestPosts = async (id) => {
    const { data } = await getLatestPosts(id)
    return data
  }

  const handleLatestPosts = async () => {
    const postId = latestPost._id || latestPost.id
    const latestPosts = await onGetLatestPosts(postId)

    if (latestPosts?.length) setLatestPosts(latestPosts)
  }

  useEffect(() => {
    if (latestPosts.length) {
      const currentPosts = [...posts]
      currentPosts.unshift(...latestPosts)
      setPosts(currentPosts)
      setLatestPostBtn(false)
      setLatestPosts([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestPosts])

  const onGetPosts = useCallback(async () => {
    setIsLoading(true)
    const last_page_size = localStorage.getItem('posts_size')

    try {
      if (userInfo.id) {
        const { data } = await getPostsByConnection(last_page_size, 0)
        localStorage.setItem('posts_size', 10)
        if (data?.length < 1) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
        setPosts([...data])
      } else {
        const { data } = await getPublicPost(last_page_size, 0)
        localStorage.setItem('posts_size', 10)
        if (data?.length < 1) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
        setPosts([...data])
      }
      setIsLoading(false)
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }, [toast, userInfo.id])

  useEffect(() => {
    onGetPosts()
  }, [onGetPosts])

  const fetchMoreData = async () => {
    try {
      let pageIndex = Math.floor((posts.length - 1) / pageSize)
      if (userInfo.id) {
        const { data } = await getPostsByConnection(pageSize, pageIndex + 1)
        if (data?.length < 1) {
          setHasMore(false)
        } else {
          setPosts([...posts, ...data])
        }
      } else {
        const { data } = await getPublicPost(pageSize, pageIndex + 1)
        if (data?.length < 1) {
          setHasMore(false)
        } else {
          setPosts([...posts, ...data])
        }
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const profilePhoto = userInfo && userInfo.photo ? userInfo.photo : profilePicture

  const handleSeeMorePost = async () => {
    setIsLoading(true)
    const oldPosts = [...posts]
    const { data } = await getPostsByConnection(pageSize, pageIndex + 1)
    if (data?.length < 1) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    setPosts([...oldPosts, ...data])
    setPageIndex(pageIndex + 1)
    setIsLoading(false)
    localStorage.setItem('posts_size', oldPosts.length + data.length)
  }

  return (
    <>
      <div id="scrollableDiv" className="h-screen z-10 overflow-x-hidden overflow-y-auto">
        {showCreatePostModal && userInfo.id && (
          <CreatePost
            onPost={() => {
              setShowCreatePostModal(false)
              onGetPosts()
              setCurrentPost(initialPost)
              setRepostId('')
              playSnapSound()
            }}
            onClose={() => {
              setShowCreatePostModal(false)
              setCurrentPost(initialPost)
              setRepostId('')
            }}
            postData={currentPostRef?.current}
            repostId={repostId}
          />
        )}
        <div className="px-6 flex items-center py-36 w-full">
          <div className="flex flex-1 justify-center w-full">
            <header style={{ flex: 2 }} className="text-white py-4 h-auto">
              <div style={{}}></div>
            </header>
            <main style={{ flex: 5 }} role="main">
              <div className="flex flex-1" style={{}}>
                <section className="flex-4 border border-y-0 border-gray-800" style={{ flex: 4 }}>
                  {userInfo.id && (
                    <aside>
                      <div
                        className="flex cursor-pointer"
                        onClick={() => {
                          setShowCreatePostModal(true)
                        }}
                      >
                        <hr className="border-gray-800" />
                        <div className={'flex flex-col flex-1'}>
                          <div className="flex p-4  pb-0">
                            <div className="lg:w-10 sm:w-0 mr-2">
                              <img
                                className="inline-block h-10 w-10 rounded-full"
                                src={profilePhoto}
                                alt=""
                              />
                            </div>
                            <div className="flex-1 bg-gray-800 rounded-3xl items-center h-12 p-2">
                              <span className="pl-3 bg-transparent text-gray-400 font-medium text-lg w-full resize-none focuc-visible:border-opacity-0">
                                What's happening?
                              </span>
                            </div>
                          </div>
                          <div className="flex p-4 mb-2">
                            <div className="w-52 px-2">
                              <div className="flex items-center"></div>
                            </div>

                            <div className="flex-1 flex">
                              <div className="flex flex-1 justify-end">
                                <Button text={'Create Post'} className={'w-40 rounded-3xl'} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </aside>
                  )}
                  <hr className="border-gray-800" />
                  <ul className="list-none">
                    {userInfo?.loadPostsAutomatically ? (
                      <InfiniteScroll
                        dataLength={posts.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<h4 className="text-white text-center p-8">Loading...</h4>}
                        style={{ overflow: 'unset' }}
                        scrollableTarget="scrollableDiv"
                      >
                        {posts.map((post) => {
                          return (
                            <Post
                              key={post.id}
                              post={{
                                ...post,
                                profilePhoto: post?.user?.photo || profilePicture,
                                name: post?.user?.name,
                                userName: post?.user?.username,
                                userObjId: post?.user?.id,
                              }}
                              onDelete={() => {
                                onGetPosts()
                              }}
                              onEdit={() => {
                                setCurrentPost(post)
                                setShowCreatePostModal(true)
                              }}
                              onRepost={() => {
                                setShowCreatePostModal(true)
                                setRepostId(post.id)
                              }}
                              hideEdit={post?.user?.id !== userInfo.id}
                            />
                          )
                        })}
                      </InfiniteScroll>
                    ) : (
                      <>
                        {posts.map((post) => {
                          return (
                            <Post
                              key={post.id}
                              post={{
                                ...post,
                                profilePhoto: post?.user?.photo || profilePicture,
                                name: post?.user?.name,
                                userName: post?.user?.username,
                                userObjId: post?.user?.id,
                              }}
                              onDelete={() => {
                                onGetPosts()
                              }}
                              onEdit={() => {
                                setCurrentPost(post)
                                setShowCreatePostModal(true)
                              }}
                              onRepost={() => {
                                setShowCreatePostModal(true)
                                setRepostId(post.id)
                              }}
                              hideEdit={post?.user?.id !== userInfo.id}
                            />
                          )
                        })}

                        {hasMore && userInfo.id ? (
                          <div className="flex justify-start">
                            <button
                              disabled={!hasMore || isLoading}
                              onClick={handleSeeMorePost}
                              className="my-4 ml-4 p-2 transition cursor-pointer hover:bg-gray-700 rounded-md text-lg"
                            >
                              {isLoading ? 'Loading... ' : 'See More'}
                            </button>
                          </div>
                        ) : null}
                      </>
                    )}
                  </ul>
                </section>
                <aside style={{ flex: 2 }} className="position-relative">
                  <div style={{}}>
                    <div className="overflow-y-auto fixed h-screen"></div>
                  </div>
                </aside>
              </div>
            </main>
          </div>
        </div>
        {!posts.length && !isLoading && (
          <div className="px-6 md:px-12 flex items-center w-full">
            <div className="flex flex-1 justify-center w-full">
              <div className="mx-24">
                <p className="p-4 text-left bg-transparent text-gray-400 font-medium text-sm resize-none">
                  Ops, seems you are not following anyone yet
                </p>
              </div>
            </div>
          </div>
        )}
        {latestPostBtn ? (
          <div className="flex justify-end absolute bottom-0 right-0 mb-4 mr-4">
            <button
              className={'flex items-center w-44 z-10 py-2 bg-purple-600 text-white rounded-md'}
              onClick={handleLatestPosts}
            >
              <IoMdRefresh className="mx-1" size={20} />
              New Posts Available
            </button>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default Dashboard
