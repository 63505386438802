import apiClient from '../http-clients/api.client'

export const createService = async (payload) => {
  const formData = new FormData()
  formData.append('title', payload.title)
  formData.append('price', payload.price)
  formData.append('quantity', payload.quantity)
  formData.append('description', payload.description)
  formData.append('categoryId', payload.category)
  formData.append('location', payload.location)
  formData.append('serviceFormat', payload.serviceFormat)
  formData.append('unit', payload.unit)
  formData.append('userId', payload.userId)
  formData.append('isActive', payload.isActive)
  formData.append('recurrence', payload.recurrence)

  if (payload?.unitValue && payload.unitValue !== '') {
    formData.append('unitValue', payload.unitValue)
  }

  if (payload?.startDate && payload.startDate !== '') {
    formData.append('startDate', payload.startDate)
  }

  if (payload?.endDate && payload.endDate !== '') {
    formData.append('endDate', payload.endDate)
  }

  if (payload?.startTime && payload.startTime !== '') {
    formData.append('startTime', payload.startTime)
  }

  if (payload?.endTime && payload.endTime !== '') {
    formData.append('endTime', payload.endTime)
  }

  payload.files.forEach((file) => {
    formData.append('photos', file.file)
  })

  if(payload.attachments?.length){
    let i = 0;
    while(i < payload.attachments?.length){
      formData.append('photos', payload.attachments?.item(i))
      i++
    }
  }

  return await apiClient.post(`/v1/service`, formData)
}

export const getServices = async (searchText = '', categoryId = '', isMy = false) => {
  let queryParam = searchText ? `search=${searchText}` : ''
  queryParam = categoryId
    ? `${queryParam}${queryParam ? '&' : ''}categoryId=${categoryId}`
    : queryParam
  queryParam = isMy ? `${queryParam}${queryParam ? '&' : ''}isMy=${true}` : queryParam

  return await apiClient.get(`/v1/service?${queryParam}`)
}

export const getService = async (id) => {
  return await apiClient.get(`/v1/service/${id}`)
}

export const getServicePublic = async (id) => {
  return await apiClient.get(`/v1/service/public/${id}`)
}

export const getMyFavoriteServices = async () => {
  return await apiClient.get(`/v1/service/get-my-favorite-services`)
}

export const getFeaturedService = async () => {
  return await apiClient.get(`/v1/service/featured-service`)
}

export const getCategories = async () => {
  return await apiClient.get(`/v1/category`)
}

export const deleteService = async (id) => {
  return await apiClient.delete(`/v1/service/${id}`)
}

export const updateService = async (payload) => {
  const formData = new FormData()
  formData.append('title', payload.title)
  formData.append('price', payload.price)
  formData.append('quantity', payload.quantity)
  formData.append('description', payload.description)
  formData.append('categoryId', payload.category)
  formData.append('location', payload.location)
  formData.append('serviceFormat', payload.serviceFormat)
  formData.append('unit', payload.unit)

  if (payload?.unitValue && payload.unitValue !== '') {
    formData.append('unitValue', payload.unitValue)
  }

  if (payload?.recurrence && payload.recurrence !== '') {
    formData.append('recurrence', payload.recurrence)
  }

  if (payload?.startDate && payload.startDate !== '') {
    formData.append('startDate', payload.startDate)
  }

  if (payload?.endDate && payload.endDate !== '') {
    formData.append('endDate', payload.endDate)
  }

  if (payload?.startTime && payload.startTime !== '') {
    formData.append('startTime', payload.startTime)
  }

  if (payload?.endTime && payload.endTime !== '') {
    formData.append('endTime', payload.endTime)
  }

  formData.append('userId', payload.userId)
  formData.append('isActive', payload.isActive)

  return await apiClient.put(`/v1/service/${payload._id}`, formData)
}

export const addOrder = async (payload) => {
  return await apiClient.post(`/v1/order`, payload)
}
