import React from 'react'

const UserLikeView = ({ name, userName, profilePhoto }) => {
  return (
    <div className="flex items-center flex-1">
      <div>
        <img className="inline-block h-10 w-10 rounded-full" src={profilePhoto} alt="" />
      </div>
      <div className="ml-3">
        <p className="text-base leading-6 font-medium text-white">
          {name}
          <span className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
            {` @${userName}`}
          </span>
        </p>
      </div>
    </div>
  )
}

export default UserLikeView
