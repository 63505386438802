import {
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import { MdLibraryAdd } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import useState from 'react-usestateref'
import { GlobalContext } from '../..'
import ServiceFilter from '../../components/ServiceFilter'
import useServices, { sideMenu } from '../../state/services'
import CreatePost from './CreatePost'

const initialService = {
  title: '',
  price: 0,
  quantity: 0,
  description: '',
  condition: '',
  category: '',
  location: '',
  isActive: true,
  photos: [],
}

const PersonasHome = ({ history }) => {
  const { state: locationState } = useLocation()
  const [, setCurrentService] = useState(initialService)
  const serviceId = useServices((state) => state.serviceId)
  const services = useServices((state) => state.list)
  const filters = useServices((state) => state.filters)
  const currentMenu = useServices((state) => state.currentMenu)
  const isLoading = useServices((state) => state.isLoading)
  const isServicesLoaded = useServices((state) => state.isServicesLoaded)
  const showShareModal = useServices((state) => state.showShareModal)
  const onShareService = useServices((state) => state.onShareService)
  const clearShareState = useServices((state) => state.clearShareState)
  const setCurrentMenu = useServices((state) => state.setCurrentMenu)
  const onGetServices = useServices((state) => state.fetchServices)
  const onServiceDelete = useServices((state) => state.serviceDelete)
  const onServiceFavorite = useServices((state) => state.onServiceFavorite)

  const { userInfo } = useContext(GlobalContext)

  useEffect(() => {
    onGetServices(
      filters.searchText,
      filters.category.id,
      currentMenu.name === 'my_services',
      currentMenu.name === 'myFav'
    ).then()
    // eslint-disable-next-line
  }, [filters, currentMenu])

  return (
    <div className="h-full">
      {showShareModal && (
        <CreatePost onPost={clearShareState} onClose={clearShareState} serviceId={serviceId} />
      )}
      <div className="h-screen w-full pt-20 flex antialiased text-gray-200 overflow-hidden">
        <div className="flex-1 flex flex-col">
          <main className="flex-grow flex flex-row min-h-0">
            <section className="flex flex-col flex-none overflow-auto w-26 hover:w-64 group lg:max-w-sm md:w-2/5 transition-all duration-300 ease-in-out">
              <div className="header p-4 flex flex-row justify-between items-center flex-none">
                <p className="text-2xl font-bold hidden md:block group-hover:block">Personas</p>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <div
                  onClick={() => {
                    history.push('/marketplace/createService')
                  }}
                  className="flex items-center hover:bg-gray-700 rounded-lg cursor-pointer p-2"
                >
                  <div className="block rounded-full bg-gray-800 h-full p-2 md:block group-hover:block cursor-pointer">
                    <MdLibraryAdd size={18} />
                  </div>
                  <div className="flex-auto min-w-0 ml-2 hidden md:block group-hover:block">
                    <p className="font-bold text-sm">Add Service</p>
                    <div className={`flex justify-between items-center text-sm}`} />
                  </div>
                </div>
              </div>
              <div className="search-box p-4 flex-none hidden">
                <form onSubmit={() => {}}>
                  <div className="relative">
                    <label>
                      <input
                        className="rounded-full py-2 pr-6 pl-10 w-full border border-gray-800 focus:border-gray-700 bg-gray-800 focus:bg-gray-900 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in"
                        type="text"
                        value=""
                        onChange={() => {}}
                        placeholder="Search Messenger"
                      />
                      <span className="absolute top-0 left-0 mt-2 ml-3 inline-block">
                        <svg viewBox="0 0 24 24" className="w-6 h-6">
                          <path
                            fill="#bbb"
                            d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                          />
                        </svg>
                      </span>
                    </label>
                  </div>
                </form>
              </div>
              <div className="contacts p-2 flex-1 overflow-y-scroll">
                <Box className="cursor-pointer">
                  {sideMenu.map((menu) => {
                    let isCurrent = menu.name === currentMenu.name
                    return (
                      <div
                        key={menu.name}
                        className={`flex justify-between items-center p-3 hover:bg-gray-800 rounded-lg relative mb-1 ${
                          isCurrent ? 'bg-gray-700' : ''
                        }`}
                        onClick={() => setCurrentMenu(menu)}
                      >
                        <div
                          className={`p-2 rounded-full relative flex-shrink-0 flex justify-center items-center ${
                            isCurrent ? 'bg-blue-500' : 'bg-gray-600'
                          }`}
                        >
                          <menu.icon />
                        </div>
                        <div className="flex-auto min-w-0 ml-4 mr-6 hidden md:block group-hover:block">
                          <p className="font-bold">{menu?.label}</p>
                          <div className="flex justify-between items-center text-sm" />
                        </div>
                      </div>
                    )
                  })}
                </Box>
              </div>
            </section>
            <section className="flex w-full">
              <Flex
                flexDirection="column"
                flex="auto"
                border="thick"
                borderColor="gray.800"
                overflowY="auto"
              >
                <Box px={6} pt={2}>
                  <ServiceFilter />
                </Box>
                {services.length > 0 ? (
                  <div className="chat-header px-6 py-4 flex flex-row flex-none justify-between items-center">
                    <section className="text-gray-400 body-font">
                      <div className="ml-50" />
                      <div className="container px-5 py-12 mx-auto">
                        <div className="mb-5">
                          {locationState?.userDetails && (
                            <Text fontSize="xl">
                              Services by{' '}
                              <Text
                                fontSize="xl"
                                as="u"
                                cursor="pointer"
                                onClick={() =>
                                  history.push(`/profile/${locationState?.userDetails?.id}`)
                                }
                              >
                                {locationState?.userDetails?.username}
                              </Text>
                            </Text>
                          )}
                        </div>
                        <div className="flex flex-wrap -m-4">
                          {services.map(
                            (
                              {
                                id,
                                title,
                                photos,
                                description,
                                condition,
                                price,
                                location,
                                userId,
                                user,
                                quantity,
                                isFavorite,
                                categoryId,
                                isActive,
                              },
                              index
                            ) => {
                              return (
                                <Box
                                  key={index}
                                  className="p-4 w-72 cursor-pointer relative"
                                  sx={{
                                    '&:hover': {
                                      '.product_card_menu': {
                                        display: 'flex',
                                      },
                                    },
                                  }}
                                  onClick={() => {
                                    let photosData = photos?.map((photo) => {
                                      return {
                                        original: photo,
                                        thumbnail: photo,
                                      }
                                    })
                                    setCurrentService({
                                      id,
                                      title,
                                      description,
                                      condition,
                                      price,
                                      location,
                                      photos: photosData,
                                      userId,
                                      categoryId,
                                      quantity,
                                      isActive,
                                    })
                                  }}
                                >
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    onClick={() => {
                                      userInfo.id !== userId
                                        ? history.push(`/marketplace/purchase/${id}`)
                                        : history.push(`/marketplace/update/${id}`)
                                    }}
                                    className="block relative h-48 rounded overflow-hidden z-10"
                                  >
                                    {isLoading ? (
                                      <Skeleton height="12rem" />
                                    ) : (
                                      <img
                                        alt="ecommerce"
                                        className="object-cover object-center w-full h-full block "
                                        src={photos[0]}
                                      />
                                    )}
                                  </a>
                                  <div className="mt-4 relative">
                                    <Menu>
                                      <MenuButton
                                        zIndex={20}
                                        display="none"
                                        as={IconButton}
                                        aria-label="menu"
                                        icon={<FiMoreVertical color="gray.600" />}
                                        variant="ghost"
                                        position="absolute"
                                        right={0}
                                        bottom={0}
                                        rounded="full"
                                        onClick={(e) => e.stopPropagation()}
                                        className="product_card_menu"
                                      />
                                      <MenuList zIndex={9999}>
                                        {userInfo.id === userId ? (
                                          <>
                                            <MenuItem onClick={() => onShareService(id)}>
                                              Share
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(event) => {
                                                event.stopPropagation()
                                                onServiceDelete(id).then()
                                              }}
                                            >
                                              Delete
                                            </MenuItem>
                                          </>
                                        ) : (
                                          <>
                                            <MenuItem
                                              onClick={(event) => {
                                                event.stopPropagation()
                                                onServiceFavorite(id, isFavorite).then()
                                              }}
                                            >
                                              {`${
                                                isFavorite ? 'Remove from ' : 'Add to '
                                              } favorite`}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(event) => {
                                                localStorage.removeItem('thread')
                                                history.push('/messages', {
                                                  userInfo: {
                                                    id: userId,
                                                  },
                                                })
                                              }}
                                            >
                                              Message Seller
                                            </MenuItem>
                                            <MenuItem onClick={() => onShareService(id)}>
                                              Share
                                            </MenuItem>
                                          </>
                                        )}
                                      </MenuList>
                                    </Menu>
                                    <Flex direction={'row'} justifyContent={'space-between'}>
                                      <Stack direction={'column'}>
                                        <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                                          {location || ''}
                                        </h3>
                                        <h2 className="text-white title-font text-lg font-medium">
                                          {`$${price || 0}`}
                                        </h2>
                                        <p className="mt-1">{title || ''}</p>
                                      </Stack>
                                      <Box>
                                        <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                                          {`by ${user?.name || 'Unknown User'}`}
                                        </h3>
                                      </Box>
                                    </Flex>
                                  </div>
                                </Box>
                              )
                            }
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                ) : !isServicesLoaded ? (
                  <HStack height={'100%'}>
                    <Stack width={'100%'}>
                      <Center>
                        <Spinner color="#6B7280" />
                      </Center>
                    </Stack>
                  </HStack>
                ) : (
                  <HStack height={'100%'}>
                    <Stack width={'100%'}>
                      <Center>
                        <p className="p-4 text-left bg-transparent text-gray-400 font-medium text-sm resize-none">
                          {currentMenu.name === 'myFav'
                            ? `Oops, you haven't marked any services as favorite`
                            : 'Oops, seems there are no services available'}
                        </p>
                      </Center>
                    </Stack>
                  </HStack>
                )}
              </Flex>
            </section>
          </main>
        </div>
      </div>
    </div>
  )
}

export default PersonasHome
