import apiClient from '../http-clients/api.client'

export const messageForward = async (id, payload) => {
  return apiClient.post(`/v1/conversations/forward-message/${id}`, payload)
}

export const createMessage = async (payload) => {
  return apiClient.post(`/v1/conversations/send-message`, payload)
}

export const readMessages = async (payload) => {
  return apiClient.post(`/v1/conversations/read-messages`, payload)
}

export const getUnreadMessages = async (payload) => {
  return apiClient.post(`/v1/conversations/unread-messages`, payload)
}

export const createMessageWithFiles = async (payload) => {
  return apiClient.post(`/v1/conversations/send-message-with-files`, payload)
}

export const callUser = async (payload) => {
  return apiClient.post(`/v1/conversations/call-user`, payload)
}

export const acceptCall = async (payload) => {
  return apiClient.post(`/v1/conversations/accept-call`, payload)
}

export const endCall = async (payload) => {
  return apiClient.post(`/v1/conversations/end-call`, payload)
}

export const rejectCall = async (payload) => {
  return apiClient.post(`/v1/conversations/reject-call`, payload)
}

export const getMyMessageThreads = async () => {
  return apiClient.get(`/v1/conversations/threads`)
}

export const getMessages = async (threadId) => {
  return apiClient.get(`/v1/conversations/${threadId}`)
}

export const deleteMessage = async (id) => {
  return apiClient.delete(`/v1/conversations/delete-by-own/${id}`)
}

export const deleteThread = async (id) => {
  return apiClient.delete(`/v1/conversations/delete-thread/${id}`)
}

export const updateDeleteDate = async (id, payload) => {
  return apiClient.post(`/v1/conversations/update-delete-date/${id}`, payload)
}

export const multipleMessageDelete = async (payload) => {
  return apiClient.post(`/v1/conversations/delete-by-own-multiple`, payload)
}

export const getSearchMessage = async (text, date) => {
  return apiClient.get(`/v1/conversations/search-messages?text=${text}&date=${date}`)
}
