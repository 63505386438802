import ReactDOM from 'react-dom'
import io from 'socket.io-client'
import { ChakraProvider } from '@chakra-ui/react'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'

import './index.css'
import App from './App'
import theme from './theme'
import { getUser } from './services/user.service'
import call_tone from './assets/sounds/incoming_call.mp3'
import outgoing_callTone from './assets/sounds/outgoing_call.mp3'
import message_tone from './assets/sounds/message_tone.mp3'
import message_sendTone from './assets/sounds/message_send.mp3'
import start_recordingTone from './assets/sounds/start_recording.mp3'
import stop_recordingTone from './assets/sounds/stop_recording.mp3'
import snap_sound from './assets/sounds/snap_sound.mp3'
import LogIn from './modules/User/LogIn'
import CoNectarOrGoogleAccount from './modules/User/CoNectarOrGoogleAccount'
import SignUp from './modules/User/SignUp'
import ResetPassword from './modules/User/Profile/ResetPassword'
import ForgotPassword from './modules/User/ForgotPassword'
import VerifyEmailSignUp from './modules/User/VerifyEmailSignUp'
import { GoogleOAuthProvider } from '@react-oauth/google'
import PrivacyPolicy from './modules/User/PrivacyPolicy'

const socket = io(process.env.REACT_APP_WEB_SOCKET_URL)

export const GlobalContext = createContext({
  userInfo: {},
  updateUserInfo: () => {},
  socket: socket,
  audios: {
    callTone: null,
    outgoingCallTone: null,
    messageTone: null,
    messageSendTone: null,
    startRecordingTone: null,
    stopRecordingTone: null,
    snapSound: null,
  },
  forwardMessage: '',
  newMessageCount: 0,
  setForwardMessage: null,
  setNewMessageCount: null,
  postReceiverId: '',
  setPostReceiverId: null,
})

const Index = () => {
  const [userInfo, setUserInfo] = useState({})
  const [forwardMessage, setForwardMessage] = useState('')
  const [postReceiverId, setPostReceiverId] = useState('')
  const [newMessageCount, setNewMessageCount] = useState(0)
  const [newNotificationCount, setNewNotificationCount] = useState(0)
  const [newNotification, setNewNotification] = useState(null)

  const audios = useRef({
    messageTone: null,
    messageSendTone: null,
    callTone: null,
    outgoingCallTone: null,
    startRecordingTone: null,
    stopRecordingTone: null,
    snapSound: null,
  })

  useEffect(() => {
    onLoad().then()
    wireAudios()
  }, [])

  const wireAudios = () => {
    let messageTone = new Audio(message_tone)
    let callTone = new Audio(call_tone)
    let outgoingCallTone = new Audio(outgoing_callTone)
    let startRecordingTone = new Audio(start_recordingTone)
    let stopRecordingTone = new Audio(stop_recordingTone)
    let messageSendTone = new Audio(message_sendTone)
    let snapSound = new Audio(snap_sound)
    audios.current.messageTone = messageTone
    audios.current.messageSendTone = messageSendTone
    audios.current.callTone = callTone
    audios.current.outgoingCallTone = outgoingCallTone
    audios.current.startRecordingTone = startRecordingTone
    audios.current.stopRecordingTone = stopRecordingTone
    audios.current.snapSound = snapSound
    audios.current.messageTone.muted = true
    audios.current.messageSendTone.muted = true
    audios.current.callTone.muted = true
    audios.current.outgoingCallTone.muted = true
    audios.current.startRecordingTone.muted = true
    audios.current.stopRecordingTone.muted = true
    audios.current.snapSound.muted = true
    const messagePlayPromise = audios?.current.messageTone.play()
    if (messagePlayPromise !== undefined) {
      messagePlayPromise
        .then((_) => {
          console.log('audio played auto')
        })
        .catch((_) => {
          console.log('playback prevented')
        })
    }
    const messageSendPlayPromise = audios?.current.messageSendTone.play()
    if (messageSendPlayPromise !== undefined) {
      messageSendPlayPromise
        .then((_) => {
          console.log('audio played auto')
        })
        .catch((_) => {
          console.log('playback prevented')
        })
    }
    const callPlayPromise = audios?.current.callTone.play()
    if (callPlayPromise !== undefined) {
      callPlayPromise
        .then((_) => {
          console.log('audio played auto')
        })
        .catch((_) => {
          console.log('playback prevented')
        })
    }
    const outgoingCallPlayPromise = audios?.current.outgoingCallTone.play()
    if (outgoingCallPlayPromise !== undefined) {
      outgoingCallPlayPromise
        .then((_) => {
          console.log('audio played auto')
        })
        .catch((_) => {
          console.log('playback prevented')
        })
    }
    const startRecordingPlayPromise = audios?.current.startRecordingTone.play()
    if (startRecordingPlayPromise !== undefined) {
      startRecordingPlayPromise
        .then((_) => {
          console.log('audio played auto')
        })
        .catch((_) => {
          console.log('playback prevented')
        })
    }
    const stopRecordingPlayPromise = audios?.current.stopRecordingTone.play()
    if (stopRecordingPlayPromise !== undefined) {
      stopRecordingPlayPromise
        .then((_) => {
          console.log('audio played auto')
        })
        .catch((_) => {
          console.log('playback prevented')
        })
    }
  }

  const onLoad = async () => {
    const userInfoJSON = localStorage.getItem('user')
    if (userInfoJSON) {
      let userInfoObject = JSON.parse(userInfoJSON)
      let { data: userInfo } = await getUser(userInfoObject.id)
      let updated = {
        ...userInfoObject,
        ...userInfo,
      }
      localStorage.setItem('user', JSON.stringify(updated))
      setUserInfo(updated)
    }
  }

  const updateUserInfo = () => onLoad()

  return (
    <React.StrictMode>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ChakraProvider theme={theme}>
          <GlobalContext.Provider
            value={{
              userInfo,
              updateUserInfo,
              socket,
              audios: audios.current,
              newMessageCount,
              setNewMessageCount,
              forwardMessage,
              setForwardMessage,
              postReceiverId,
              setPostReceiverId,
              newNotificationCount,
              setNewNotificationCount,
              newNotification,
              setNewNotification,
            }}
          >
            <Router>
              <Switch>
                <Route path="/login" component={LogIn} />
                <Route path="/conectar-or-google" component={CoNectarOrGoogleAccount} />
                <Route path="/signup" component={SignUp} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/verify-code" component={VerifyEmailSignUp} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/" component={App} />
              </Switch>
            </Router>
          </GlobalContext.Provider>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))

reportWebVitals()
