import { Box, Flex, Spinner, Stack } from '@chakra-ui/react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { GlobalContext } from '../..'
import { getService, getServicePublic } from '../../services/market.service'

const ServiceNotFound = () => {
  return (
    <div className="cursor-pointer border-gray-600 border border-solid rounded-md">
      <article className="hover:bg-gray-900 transition duration-350 ease-in-out pb-4 rounded-md">
        <div className="flex flex-1 flex-shrink-0 p-4 pb-0">
          <div className="flex-shrink-0 flex-1 group block">
            <div className="flex items-center flex-1">
              <div className="ml-3" />
            </div>
          </div>
        </div>
        <div className="pl-16">
          <p className="text-base width-auto font-small text-white flex-shrink mr-4">
            Service not found
          </p>
        </div>
      </article>
    </div>
  )
}

const ShareService = ({ serviceId }) => {
  const [services, setServices] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { userInfo } = useContext(GlobalContext)
  const history = useHistory()

  const onGetServices = useCallback(async () => {
    try {
      if (userInfo.id) {
        const { data: service } = await getService(serviceId)
        if (service) {
          setServices([service])
          setIsLoading(false)
        }
      } else {
        const { data: service } = await getServicePublic(serviceId)
        if (service) {
          setServices([service])
          setIsLoading(false)
        }
      }
    } catch (error) {
      setIsLoading(false)
    }
  }, [serviceId, userInfo.id])

  useEffect(() => {
    onGetServices()
  }, [serviceId, onGetServices])

  return (
    <div>
      {isLoading ? (
        <Spinner size="sm" color="#6B7280" />
      ) : services && services.length ? (
        <>
          {services.map(({ title, photos, price, location, user }, index) => {
            return (
              <Box
                key={index}
                className="p-4 w-full border-gray-600 border border-solid rounded-md cursor-pointer relative"
                sx={{
                  '&:hover': {
                    '.product_card_menu': {
                      display: 'flex',
                    },
                  },
                }}
                onClick={() => history.push(`/marketplace/purchase/${serviceId}`)}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="block relative lg:h-72 rounded overflow-hidden z-10 sm:h-60">
                  <img
                    alt="ecommerce"
                    className="object-cover object-center w-full h-full block "
                    src={photos[0]}
                  />
                </a>
                <div className="mt-4 relative">
                  <Flex direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'column'}>
                      <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">
                        {location || ''}
                      </h3>
                      <h2 className="text-white title-font text-lg font-medium">{`$${
                        price || 0
                      }`}</h2>
                      <p className="mt-1">{title || ''}</p>
                    </Stack>
                    <Box>
                      <h3
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          history.push(`/profile/${user.id}`)
                        }}
                        className="text-gray-500 text-xs tracking-widest title-font mb-1"
                      >
                        {`by ${user?.name || 'Unknown User'}`}
                      </h3>
                    </Box>
                  </Flex>
                </div>
              </Box>
            )
          })}
        </>
      ) : (
        <ServiceNotFound />
      )}
    </div>
  )
}

export default ShareService
