import { toast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../..'
import { getMyContacts } from '../../services/user.service'
import ConnectionRow from '../Feed/ConnectionRow'

const AllConnections = () => {
  const [userContacts, setUserContacts] = useState([])
  const { userInfo: currentUserInfo } = useContext(GlobalContext)
  const history = useHistory()

  useEffect(() => {
    onGetMyContacts()
  }, [])

  const onGetMyContacts = async () => {
    try {
      let { data: myContacts } = await getMyContacts()
      myContacts = myContacts.map((contact) => {
        let updated = { ...contact }
        updated.id = contact?._id
        return updated
      })
      setUserContacts(myContacts || [])
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <div className="grid lg:grid-cols-1 gap-10">
      <div className="mt-32 justify-items-center mx-96 border-gray-600 border border-solid rounded-md p-5">
        <h1 className="text-gray-100 text-3xl mb-6">Contact List</h1>
        <div>
          {userContacts
            .filter((user) => user.id !== currentUserInfo.id)
            .map((userProfile) => {
              return (
                <ConnectionRow
                  key={userProfile.id}
                  currentUserId={currentUserInfo.id}
                  userProfile={{
                    ...userProfile,
                    connected: true,
                  }}
                  onAddConnection={() => {
                    onGetMyContacts()
                  }}
                  onRemoveConnection={() => {
                    onGetMyContacts()
                  }}
                  onSelect={(userProfile) => {
                    history.push('/profile', {
                      userObject: userProfile,
                    })
                  }}
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default AllConnections
