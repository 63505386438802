import React, { useState } from 'react'
import { appendSheetData } from '../../services/user.service'
import Button from '../../components/Button'

const NewsLetter = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleOnAppendDataClick = async () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.')
      return
    }
    setError('')
    setIsLoading(true)
    setIsSuccess(false)

    try {
      await appendSheetData([[email]])
      setIsSuccess(true)
    } catch (error) {
      setError('Failed to send data. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col md:flex-row-reverse px-6 md:px-12 py-36 items-center gap-8 justify-center min-h-screen">
      <div className="flex-1">
        <img src="" alt="Co-Nectar" className="mx-auto" />
      </div>
      <div className="flex-1 text-center md:text-left">
        <h1 className="text-4xl font-bold text-[#232F3E]">Co-Nectar </h1>
        <p className="text-xl text-[#232F3E] mt-4">
          Join our waitlist to get early access to the Co-Nectar. We will notify you when we launch.
        </p>
        <div className="mt-8 flex flex-row items-center">
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() =>
              validateEmail(email) ? setError('') : setError('Invalid email address.')
            }
            style={{
              color: 'black',
            }}
            className="rounded px-4 py-2 w-full md:w-1/2"
          />
          <p className="text-red-500 text-sm ml-2">{error}</p>

          <Button
            className={'w-24 ml-2'}
            text={isLoading ? 'Sending...' : isSuccess ? '✓ Sent' : 'Send'}
            onClick={handleOnAppendDataClick}
            disabled={isLoading || isSuccess}
          />
        </div>
      </div>
    </div>
  )
}

export default NewsLetter
