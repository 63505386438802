import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { resetPassword } from '../../../services/user.service'
import ModalCustom from '../../../components/ModalCustom/ModalCustom'
import { useForm } from 'react-hook-form'
import { FormControl, FormErrorMessage, InputGroup } from '@chakra-ui/react'

const ResetPasswordModal = ({
  userEmail,
  isOpen,
  onClose = () => {},
}) => {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm()

  const newPassword = watch('newPassword')

  const [showPass, setShowPass] = useState(false)
  const [loginNoMatch, setLoginNoMatch] = useState(false)
  const history = useHistory()

  const handleChange = () => {
    reset({ errors: false })
    setLoginNoMatch(false)
  }

  const validateFields = (oldPassword, newPassword, confirmPassword) => {
    if (
      !oldPassword ||
      !newPassword ||
      !confirmPassword ||
      oldPassword === '' ||
      newPassword === '' ||
      confirmPassword === ''
    ) {
      return false
    }
    return newPassword === confirmPassword;

  }

  const handleOnSubmit = async (data) => {
    let { oldPassword, newPassword, confirmPassword } = data
    let allValidated = validateFields(oldPassword, newPassword, confirmPassword)
    if (!allValidated) {
      return
    }
    try {
      await resetPassword({
        email: userEmail,
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      localStorage.removeItem('user')
      history.push('/login')
      onClose()
    } catch (error) {
      if (error?.response.status === 404 || error?.response.status === 401) {
        setLoginNoMatch(true)
      }
    }
  }

  return (
    <ModalCustom isOpen={isOpen} onClose={onClose}>
      <div style={{ width: 500 }} className="flex flex-1 justify-center items-center w-full p-12">
        <div className="flex flex-col w-full max-w-md  bg-white rounded-lg dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
          <div className="self-center mb-6 text-4xl font-light text-gray-600 sm:text-4xl dark:text-white">
            Reset Password
          </div>
          <p
            style={{ visibility: loginNoMatch ? 'visible' : 'hidden' }}
            className="text-red-500 text-sm italic text-center"
          >
            {'Old password is incorrect!'}
          </p>
          <div className="mt-4">
            <form action="#" autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
              <FormControl isInvalid={errors.oldPassword}>
                <div className="flex flex-col mb-2">
                  <div className="flex relative mb-2">
                    <InputGroup>
                      <input
                        type={showPass ? 'text' : 'password'}
                        name="oldPassword"
                        id="oldPassword"
                        defaultValue=""
                        {...register('oldPassword', {
                          required: 'Please enter your old password.',
                        })}
                        onChange={handleChange}
                        placeholder="Old Password"
                        className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                      <span
                        style={{ right: 13, top: '36%' }}
                        className="inline-flex items-center bg-white text-gray-500 cursor-pointer absolute"
                        onClick={() => setShowPass(!showPass)}
                      >
                        <i className={`far fa-eye${!showPass ? '-slash' : ''}`}/>
                      </span>
                    </InputGroup>
                  </div>
                  <FormErrorMessage>
                    {errors.oldPassword && errors.oldPassword.message}
                  </FormErrorMessage>
                </div>
              </FormControl>
              <FormControl isInvalid={errors.newPassword}>
                <div className="flex flex-col mb-2">
                  <div className="flex relative mb-2">
                    <InputGroup>
                      <input
                        type={showPass ? 'text' : 'password'}
                        name="newPassword"
                        id="newPassword"
                        defaultValue=""
                        {...register('newPassword', {
                          required: 'Please enter your new password.',
                          minLength: {
                            value: 8,
                            message: 'Password must be at least 8 characters long',
                          },
                          maxLength: {
                            value: 20,
                            message: 'Password must not exceed 20 characters in length.',
                          },
                          pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z0-9]).*$/,
                            message:
                              'Password must contain at least one lowercase letter and one uppercase letter or one number',
                          },
                        })}
                        onChange={handleChange}
                        placeholder="New Password"
                        className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                      <span
                        style={{ right: 13, top: '36%' }}
                        className="inline-flex items-center bg-white text-gray-500 cursor-pointer absolute"
                        onClick={() => setShowPass(!showPass)}
                      >
                        <i className={`far fa-eye${!showPass ? '-slash' : ''}`}/>
                      </span>
                    </InputGroup>
                  </div>
                  <FormErrorMessage>
                    {errors.newPassword && errors.newPassword.message}
                  </FormErrorMessage>
                </div>
              </FormControl>
              <FormControl isInvalid={errors.confirmPassword}>
                <div className="flex flex-col mb-6">
                  <div className="flex relative mb-2">
                    <InputGroup>
                      <input
                        type={showPass ? 'text' : 'password'}
                        name="confirmPassword"
                        id="confirmPassword"
                        defaultValue=""
                        {...register('confirmPassword', {
                          required: 'Please enter your confirmation password.',
                          validate: (value) => value === newPassword || 'Passwords do not match',
                        })}
                        onChange={handleChange}
                        placeholder="Confirm Password"
                        className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                      <span
                        style={{ right: 13, top: '36%' }}
                        className="inline-flex items-center bg-white text-gray-500 cursor-pointer absolute"
                        onClick={() => setShowPass(!showPass)}
                      >
                        <i className={`far fa-eye${!showPass ? '-slash' : ''}`}/>
                      </span>
                    </InputGroup>
                  </div>
                  <FormErrorMessage>
                    {errors.confirmPassword && errors.confirmPassword.message}
                  </FormErrorMessage>
                </div>
              </FormControl>
              <div className="flex items-center mb-6 -mt-4"/>
              <div className="flex w-full">
                <button
                  type="submit"
                  className={`py-2 px-4  bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ${
                    isSubmitting ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalCustom>
  )
}

export default ResetPasswordModal
