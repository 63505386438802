import apiClient from '../http-clients/api.client'

export const createComment = async (payload) => {
  const formData = new FormData()
  formData.append('postId', payload.postId)
  formData.append('text', payload.text)
  if (payload.parentId) {
    formData.append('parentId', payload.parentId)
  }
  return await apiClient.post(`/v1/comment`, formData)
}

export const updateComment = async (payload) => {
  const formData = new FormData()
  formData.append('postId', payload.postId)
  formData.append('text', payload.text || '')
  return await apiClient.put(`/v1/comment/${payload.id}`, formData)
}

export const getCommentByUser = async () => {
  return await apiClient.get(`/v1/comment/comments-by-user`)
}

export const getCommentByPost = async (postId) => {
  return await apiClient.get(`/v1/comment/comments-by-post?postId=${postId}`)
}

export const getCommentByPublicPost = async (postId) => {
  return await apiClient.get(`/v1/comment/comments-by-post-public?postId=${postId}`)
}

export const deleteCommentByOwn = async (id) => {
  return await apiClient.delete(`/v1/comment/delete-by-own/${id}`)
}
