import { Box, Flex, Text, toast } from '@chakra-ui/react'
import { useRouteMatch } from 'react-router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  createComment,
  getCommentByPost,
  getCommentByPublicPost,
} from '../../services/comments.service'
import Post from './Post'
import Comment from './Comment'
import CreatePost from './CreatePost'
import { GlobalContext } from '../..'
import { getPost, getPostPublic } from '../../services/post.service'
import { getNotification, readNotifications } from '../../services/notification.service'
import EmojiPickerPopover from '../Message/components/EmojiPickerPopover'

const initialPost = {
  id: null,
  photos: [],
}

const PostPage = () => {
  const { params } = useRouteMatch()
  const { id: postId, userid: userId } = params

  const [post, setPost] = useState({})
  const [postAvailable, setPostAvailable] = useState(true)
  const [showCreatePostModal, setShowCreatePostModal] = useState(false)

  const [, setAttachments] = useState([])
  const [, setIsLoading] = useState(false)
  const [comment, setComment] = useState('')
  const [getComment, setGetComment] = useState([])

  const { userInfo, newNotification, setNewNotification } = useContext(GlobalContext)

  useEffect(() => {
    if (newNotification) {
      const onGetNotification = async (id) => {
        try {
          const notification = await getNotification(id)

          console.log(`new post notification: ${notification}`)

          if (postId && userInfo.id) {
            handleGetPost(postId, userInfo.id)
            handleGetComments(postId, userInfo.id)
          }

          await readNotifications()
        } catch (error) {
          console.error(`error fetching new comment: ${error.message}`)
        }
      }

      onGetNotification(newNotification?.notificationId)
      setNewNotification(null)
    }
  }, [getComment, newNotification, post, postId, setNewNotification, userInfo.id])

  const handleGetPost = async (postId, userId) => {
    try {
      if (userId) {
        const { data: post } = await getPost(postId)
        setPost(post)
      } else {
        const { data: post } = await getPostPublic(postId)
        setPost(post)
      }
    } catch (error) {
      setPostAvailable(false)
    }
  }

  useEffect(() => {
    if (postId && userInfo.id) {
      handleGetPost(postId, userInfo.id)
    }
  }, [postId, userInfo.id])

  const commentOnChange = (e) => {
    setComment(e.target.value)
  }

  const handleCommentOnPost = useCallback(async () => {
    try {
      setIsLoading(true)

      const res = await createComment({ postId: post.id, text: comment })

      if (res.status === 201) {
        try {
          const { data: comments } = await getCommentByPost(postId)
          const { data: post } = await getPost(postId)

          setGetComment(comments)
          setPost(post)
        } catch (error) {
          toast({
            title: 'Oops! Something Went Wrong',
            description: `${error.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }

        setComment('')
        setAttachments([])
        setIsLoading(false)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [comment, post, postId])

  const handleGetComments = async (postId, userId) => {
    try {
      if (userId) {
        const { data: comments } = await getCommentByPost(postId)
        setGetComment(comments)
      } else {
        const { data: comments } = await getCommentByPublicPost(postId)
        setGetComment(comments)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (postId && userInfo.id) {
      handleGetComments(postId, userInfo.id)
    }
  }, [postId, userInfo.id])

  return (
    <Flex paddingTop={28} alignItems={'stretch'} justifyContent={'center'}>
      {showCreatePostModal && (
        <CreatePost
          onPost={() => {
            setShowCreatePostModal(false)
          }}
          onClose={() => {
            setShowCreatePostModal(false)
          }}
          postData={initialPost}
          repostId={post.id}
        />
      )}
      <Box maxW={'3xl'} w={'full'}>
        <div className="border-solid border-2 rounded">
          {post?.id && post?.user?.username === userId && postAvailable ? (
            <Post
              post={{
                ...post,
                profilePhoto: post?.user?.photo,
                name: post?.user?.name,
                userName: post?.user?.username,
              }}
              onDelete={() => {}}
              onEdit={() => {}}
              onRepost={() => {
                setShowCreatePostModal(true)
              }}
              hideEdit={true}
              getComment={getComment}
            />
          ) : (
            <Text textAlign={'center'}>Post is not available or restricted</Text>
          )}

          {userInfo.id && (
            <Flex alignItems={'stretch'} justifyContent={'center'}>
              <Box maxW={'3xl'} w={'full'}>
                <div className="flex-1 pr-2 pt-2 m-2 border-solid border-2 rounded">
                  <textarea
                    className="pl-2 bg-transparent text-gray-400 font-medium text-base w-full focuc-visible:border-opacity-0"
                    placeholder="Write a comment..."
                    value={comment}
                    onChange={commentOnChange}
                  ></textarea>
                  {comment ? (
                    <div className="flex mr-2">
                      <div className="flex-1 px-2">
                        <div className="flex">
                          <EmojiPickerPopover
                            onSelectEmoji={(emoji) => setComment((prev) => `${prev}${emoji}`)}
                          />
                        </div>
                      </div>
                      <div className="flex pb-2">
                        <div className="flex flex-1 justify-end mr-2">
                          <button
                            className={
                              'flex items-center w-42 z-10 py-2 px-3 bg-purple-600 text-white rounded-md'
                            }
                            onClick={handleCommentOnPost}
                          >
                            Add Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Box>
            </Flex>
          )}
          <div>
            {getComment?.map((comment) => (
              <Comment key={comment.id} comment={comment} handleGetComments={handleGetComments} />
            ))}
          </div>
        </div>
      </Box>
    </Flex>
  )
}

export default PostPage
