import React from 'react'
import Spinner from './SpinnerCustom/SpinnerCustom'
import { overrideTailwindClasses } from 'tailwind-override'
import { BUTTON_VARIANT } from '../utils/app-constants'

const ButtonRed = ({
  text = '',
  style = {},
  disabled = false,
  type = 'button',
  isLoading = false,
  variant = BUTTON_VARIANT.PRIMARY,
  Icon = null,
  className = '',
  onClick = (event) => {},
}) => {
  switch (variant) {
    case BUTTON_VARIANT.PRIMARY:
    default:
      return (
        <button
          disabled={disabled}
          style={style}
          type={type}
          className={overrideTailwindClasses(
            `flex justify-center items-center h-10 py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-purple-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ${
              isLoading || disabled ? 'cursor-default' : ''
            } ${disabled ? 'opacity-50' : ''} ${className}`
          )}
          onClick={(event) => {
            !isLoading && !disabled && onClick(event)
          }}
        >
          {isLoading ? <Spinner /> : <span>{text}</span>}
        </button>
      )
    case BUTTON_VARIANT.ICON:
      return (
        <button
          style={style}
          type={type}
          className={overrideTailwindClasses(
            `rounded-full h-auto w-auto p-3 bg-gray-800 hover:bg-gray-500 cursor-pointer ${
              isLoading || disabled ? 'cursor-default' : ''
            } ${disabled ? 'opacity-50' : ''} ${className}`
          )}
          onClick={(event) => {
            !isLoading && onClick(event)
          }}
        >
          {isLoading ? <Spinner classNames="p-2" /> : <Icon />}
        </button>
      )
  }
}

export default ButtonRed
