/* eslint-disable no-mixed-operators */

export const buildIntervals = () => {
  const createIntervalsWithMinutes = (hour, label) => {
    const intervals = []
    const hourFormat = hour < 10 ? `0${hour}` : hour
    intervals.push(`${hourFormat}:00 ${label}`)
    for (let i = 15; i <= 45; i += 15) {
      intervals.push(`${hourFormat}:${i} ${label}`)
    }
    return intervals
  }

  let intervals = []
  intervals = [...intervals, ...createIntervalsWithMinutes(12, 'am')]

  for (let i = 1; i <= 11; i++) {
    intervals = [...intervals, ...createIntervalsWithMinutes(i, 'am')]
  }

  intervals = [...intervals, ...createIntervalsWithMinutes(12, 'pm')]

  for (let i = 1; i <= 11; i++) {
    intervals = [...intervals, ...createIntervalsWithMinutes(i, 'pm')]
  }

  return intervals
}

export const changeDateHours = (date, time) => {
  const isPM = time.includes('pm')
  const split = time.split(':')
  const hours =
    isPM && Number(split[0]) !== 12
      ? Number(split[0]) + 12
      : !isPM && Number(split[0]) === 12
      ? Number(split[0]) - 12
      : Number(split[0])
  const minutes = +split[1].split(' ')[0]
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)
}

export const getDayDifference = (start, end) => {
  const endDay = end.toString().split(' ')[2]
  const startDay = start.toString().split(' ')[2]
  return endDay - startDay
}

export const generateCallLink = () => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  return `${process.env.REACT_APP_URL}/meet/${uuid}`
}
