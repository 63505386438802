import React, { useEffect, useCallback, useState, useContext} from 'react'
import { signup , login} from '../../services/user.service'
import { useForm, Controller } from 'react-hook-form'
import Button from '../../components/Button'
import { FormControl, Center, useToast, PinInput, PinInputField } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router'
import { requestVerificationCode, tokenVerificationCode } from '../../services/user.service'
import useCheckLogIn from '../../hooks/useCheckLogIn'
import { GlobalContext } from '../..'


const CustomPinInput = React.forwardRef((props, ref) => {
  return (
    <PinInput {...props} refs={ref} className="flex-auto">
      <PinInputField className="text-gray-600" />
      <PinInputField className="text-gray-600" />
      <PinInputField className="text-gray-600" />
      <PinInputField className="text-gray-600" />
      <PinInputField className="text-gray-600" />
      <PinInputField className="text-gray-600" />
    </PinInput>
  )
})

const VerifyEmailSignUp = () => {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      verificationCode: '',
    },
  })

  const verificationCode = watch('verificationCode')

  useCheckLogIn()
  const history = useHistory()
  const location = useLocation()
  const toast = useToast()

  const [payload, setIsPayload] = useState({})
  const { updateUserInfo } = useContext(GlobalContext)

  useEffect(() => {
    if (
      !location.state ||
      !location.state.email ||
      !location.state.name ||
      !location.state.username ||
      !location.state.password ||
      !location.state.privacyPolicyAccepted
    ) {
      history.push('/')
    } else {
      setIsPayload(location.state)
    }
  }, [location, history])

  useEffect(() => {
    return () => {
      reset()
      location.state = null
    }
  }, [location, reset])

  const onRequestCode = useCallback(
    async (forceSend = false) => {
      try {
        let { email, name } = payload
        if (email && name) {
          const response = await requestVerificationCode({
            email: email,
            name: name,
            forceSend: forceSend,
          })
          if (response.statusText === 'Created') {
            toast({
              duration: 5000,
              status: 'success',
              position: 'top-right',
              variant: 'left-accent',
              title: 'Check your Email for the Verification Code',
              description: 'Please check your inbox messages.',
            })
          } else if (response.data.error === true) {
            toast({
              duration: 5000,
              status: 'error',
              position: 'top-right',
              variant: 'left-accent',
              title: 'A User with this Email Already Exists.',
              description: 'Please use new email.',
            })
            history.goBack()
          } else if (response.status === 400) {
            toast({
              duration: 5000,
              status: 'error',
              position: 'top-right',
              variant: 'left-accent',
              title: 'Verification Code Already Sent',
              description: 'Please check your email or try again later.',
            })
          }
        }
      } catch (error) {
        toast({
          duration: 5000,
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          title: 'Oops! Something Went Wrong',
          description: 'Please contact support at team@conectar.ch',
        })
      } finally {
        reset()
      }
    },
    [payload, toast, history, reset]
  )

  useEffect(() => {
    onRequestCode(false)
  }, [onRequestCode])

  const onSignUp = async () => {
    try {
      const response = await signup(payload)
      if (response.data) {
        if (response?.data?.reason === 'INAPPROPRIATE_CONTENT') {
          toast({
            title: 'Oops! Something Went Wrong',
            description: `${response?.data?.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          history.push({ pathname: '/signup' })
        } else {
          async function loginAfterSignup() {
            try{
              const response = await login(location.state.username,location.state.password)
              localStorage.setItem('user', JSON.stringify(response.data))   
              updateUserInfo()
              history.push('/')
            }
            catch(error){
              console.log(error)
            }
          }
          loginAfterSignup()
          toast({
            duration: 5000,
            status: 'success',
            position: 'bottom-right',
            variant: 'left-accent',
            title: 'Your Account has Been Created',
            description: 'Please login.',
          })
        }
      } else {
        toast({
          duration: 5000,
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          title: 'Email is Already in Use!',
          description: 'Please contact support at team@conectar.ch',
        })
        history.push({ pathname: '/login' })
      }
    } catch (error) {
      toast({
        duration: 5000,
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        title: 'Oops! Something Went Wrong',
        description: error.message + ', Please contact support at team@conectar.ch',
      })
    } finally {
      reset()
    }
  }

  const onSubmit = async (data) => {
    const {verificationCode} = data
    try {
      let {email} = payload
      const response = await tokenVerificationCode({
        email,
        verificationCode,
      })
      if (response.data?.checkCode) {
        toast({
          duration: 5000,
          status: 'success',
          position: 'bottom-right',
          variant: 'bottom-right',
          title: 'Your code has been verified',
        })
        onSignUp()
      }
    } catch (error) {
      reset()
      if (error?.response.data.statusCode === 400) {
        toast({
          duration: 5000,
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          title: 'Invalid or expired verification code',
        })
      }
    }
  }

  return (
    <div className="flex flex-1 justify-center items-center h-full w-full">
      <div className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow-2xl dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center mb-2 text-4xl font-medium text-gray-600 sm:text-3xl dark:text-white">
          Verification code
        </div>
        <div className="self-center mb-4 text-sm font-medium text-gray-400 dark:text-white">
          Please check your email for the verification code.
        </div>
        <div className="my-4">
          <form onSubmit={handleSubmit(onSubmit)} action="#" autoComplete="off">
            <FormControl>
              <div className="flex flex-col mb-6">
                <div className="flex-auto mb-2">
                  <Center>
                    <Controller
                      name="verificationCode"
                      control={control}
                      render={({ field }) => <CustomPinInput {...field} />}
                    />
                  </Center>
                </div>
              </div>
            </FormControl>
            <div className={'flex w-full'}>
              <Button
                disabled={verificationCode.length < 6}
                text="Verify"
                isLoading={isSubmitting}
                type="submit"
              />
            </div>
          </form>
        </div>
        <div className="my-4">
          <div className="flex w-full">
            <p className="text-sm font-medium text-gray-600">
              Didn&#x27;t receive the code?&nbsp;
              <button
                onClick={() => onRequestCode(true)}
                className="text-purple-600 hover:text-purple-800 focus:text-gray-600"
              >
                click here to request a new code
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmailSignUp
