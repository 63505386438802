import React from 'react'
import useQuery from '../../../hooks/useQuery'
import ResetPasswordForm from '../ResetPasswordForm'

const ResetPassword = () => {
  let query = useQuery()
  return (
    <div className="flex flex-1 justify-center items-center h-full w-full">
      <div className="flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow-2xl dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center mb-2 text-4xl font-medium text-gray-600 sm:text-3xl dark:text-white">
          Reset Your Password
        </div>
        <ResetPasswordForm id={query.get('id')} token={query.get('token')} />
      </div>
    </div>
  )
}

export default ResetPassword
