import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const useCheckLogIn = () => {
  const [logIn, setLogIn] = useState(null)
  const history = useHistory()
  useEffect(() => {
    if (localStorage.getItem('user')) {
      setLogIn(true)
      history.push('/')
    }
    setLogIn(false)
  }, [history])
  return [logIn]
}

export default useCheckLogIn
