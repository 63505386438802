import React from 'react'
import { BsXCircleFill } from 'react-icons/bs'

const AttendeeAdded = ({ userProfile, onDelete = () => {}, disabled = false }) => {
  const { username } = userProfile

  const onHandleRedirect = () => {
    onDelete(userProfile)
  }

  return (
    <div className="flex p-1 rounded-md border justify-between items-center gap-2">
      {username}
      {!disabled && <BsXCircleFill onClick={onHandleRedirect} />}
    </div>
  )
}

export default AttendeeAdded
