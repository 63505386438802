import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Stack,
  toast,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useContext, useRef, useState } from 'react'
import { GlobalContext } from '../../../'
import { getUserSearch } from '../../../services/user.service'
import profilePicture from '../../../assets/svgs/avtar-rect.svg'

const MessageForwardModal = ({
  onClose = () => {},
  onMessageForward,
  isMessageForward,
  handleForwardPost,
  setShowMessageForwardModal,
  isPost = false,
  isFromPost = false,
}) => {
  const history = useHistory()
  const btnRef = useRef(null)
  const { userInfo, setPostReceiverId } = useContext(GlobalContext)
  const [searchText, setSearchText] = useState('')
  const [userSearchResult, setUserSearchResult] = useState([])
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [receiverId, setReceiverId] = useState('')

  const handleUserSearch = async (searchTex) => {
    setIsSearchLoading(true)
    try {
      if (searchText) {
        const userResult = await getUserSearch(searchText)
        if (userResult?.status === 200) {
          setUserSearchResult(userResult?.data)
          setIsSearchLoading(false)
        }
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      finalFocusRef={btnRef}
      scrollBehavior={'inside'}
      isOpen={true}
      onClose={onClose}
      size={'md'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isPost ? 'Forward Post' : 'Forward Message'}</ModalHeader>
        <ModalCloseButton />
        <div className="relative border-b-2 flex items-center w-full h-full group">
          <svg
            className="absolute left-1 z-20 hidden w-4 h-4 mb-4 ml-4 text-gray-500 pointer-events-none fill-current group-hover:text-gray-400 sm:block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
          </svg>
          <input
            type="text"
            className="block w-full py-1.5 pl-10 pr-4 mb-4 ml-2 mr-2 leading-normal rounded-2xl focus:border-transparent border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-opacity-90 bg-gray-800 dark:bg-gray-800 text-gray-400 aa-input"
            placeholder="Find user..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
              handleUserSearch(event.target.value)
            }}
          />
        </div>
        <ModalBody>
          {isSearchLoading ? (
            <Stack>
              <Skeleton isLoaded={!isSearchLoading} height="90px" />
              <Skeleton isLoaded={!isSearchLoading} height="90px" />
              <Skeleton isLoaded={!isSearchLoading} height="90px" />
              <Skeleton isLoaded={!isSearchLoading} height="90px" />
              <Skeleton isLoaded={!isSearchLoading} height="90px" />
            </Stack>
          ) : (
            <>
              {userSearchResult.length ? (
                <div style={{ height: '60vh' }}>
                  {userSearchResult.map((userResult) => {
                    return userResult.id !== userInfo.id ? (
                      <div key={userResult.id} className="scroll-smooth mt-2 w-full">
                        <div className="chat-header px-6 py-4 items-center shadow">
                          <div className="flex">
                            <div className="w-12 h-12 mr-4 relative flex flex-shrink-0">
                              <img
                                className="shadow-md rounded-full w-full h-full object-cover"
                                src={userResult?.photo || profilePicture}
                                alt="profilePhoto"
                              />
                            </div>
                            <div className="flex justify-between w-full">
                              <div className="text-sm">
                                <p className="font-bold">{userResult?.name}</p>
                                <p className="text-gray-300">{userResult?.username}</p>
                              </div>
                              {isMessageForward && receiverId === userResult.id ? (
                                <div>
                                  <Button>
                                    <Spinner />
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    onClick={() => {
                                      if (isPost) {
                                        if (isFromPost) {
                                          setPostReceiverId(userResult.id)
                                          setShowMessageForwardModal(false)
                                          history.push('/messages')
                                        } else handleForwardPost(userResult.id)
                                      } else {
                                        onMessageForward(userResult.id)
                                      }
                                      setReceiverId(userResult.id)
                                    }}
                                  >
                                    Send
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  })}
                </div>
              ) : (
                <p className="text-center">No Results Found</p>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MessageForwardModal
