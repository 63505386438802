/* eslint-disable jsx-a11y/anchor-is-valid */
import { GlobalContext } from '../..'
import { CgFeed } from 'react-icons/cg'
import { useWindowSize } from 'usehooks-ts'
import { useHistory } from 'react-router-dom'
import { FaPeopleArrows } from 'react-icons/fa'
import { MdNotificationsNone } from 'react-icons/md'
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react'
import SearchBar from './SearchBar'
import Button from '../../components/Button'
import { formatDistanceToNowStrict } from 'date-fns'
import {
  getNotification,
  getNotifications,
  readNotifications,
} from '../../services/notification.service'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import React, { useContext, useEffect, useState } from 'react'
import useComponentVisible from '../../hooks/useComponentVisible'
import logoTextOnly from '../../assets/svgs/CoNectar_text_blank.svg'
import { BsChatDots, BsDoorOpen, BsPersonCircle } from 'react-icons/bs'

const pageSize = 30

const TopNavBar = () => {
  const toast = useToast()
  const history = useHistory()
  const [profilePhoto, setProfilePhoto] = useState(null)
  const [notifications, setNotifications] = useState([])
  const menuListWidth = useBreakpointValue({ base: '78vw', md: '24vw' })
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  const {
    userInfo,
    socket,
    newMessageCount = 0,
    newNotificationCount = 0,
    setNewNotificationCount,
    newNotification,
    setNewNotification,
    setNewMessageCount,
  } = useContext(GlobalContext)

  const onGetNotification = async (id) => {
    const singleNotification = await getNotification(id)
    notifications.unshift(singleNotification?.data)
  }

  const { width } = useWindowSize()

  useEffect(() => {
    if (newNotification) {
      onGetNotification(newNotification?.notificationId)
      setNewNotification(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotification, notifications, setNewNotification])

  useEffect(() => {
    try {
      setProfilePhoto(userInfo && userInfo.photo ? userInfo.photo : profilePicture)
    } catch (error) {
      setProfilePhoto(profilePicture)
    }
  }, [userInfo])

  useEffect(() => {
    getNotifications(pageSize, 0).then((notifications) => {
      setNotifications(notifications.data)
    })
  }, [toast])

  const actionResolver = (action) => {
    if (action === 'like') {
      return 'Liked on your post.'
    } else if (action === 'comment') {
      return 'Commented on your post.'
    } else if (action === 'starting') {
      return 'Event is about to start.'
    }
  }

  const menuItem = (notification) => (
    <MenuItem
      key={notification._id}
      min={6}
      color="white"
      className={notification.isRead ? 'mb-2' : 'bg-gray-600 mb-2'}
      onClick={() => {
        if (notification.eventLink) {
          history.push(notification.eventLink)
        } else {
          history.push(`/${userInfo.username}/post/${notification.postId}`)
        }
      }}
    >
      <div className="flex">
        <img
          className="inline-block h-12 w-12 rounded-full"
          src={notification.interactUser.photo || profilePicture}
          alt="ProfileImage"
        />
        <div className="ml-3">
          <p className="text-base leading-6 font-medium text-white">
            {notification.interactUser.name}
          </p>
          <p className="text-sm leading-5 font-medium text-white group-hover:text-gray-300 transition ease-in-out duration-150">
            {actionResolver(notification.action)}
          </p>
          <p
            className={
              notification.isRead ? 'text-sm leading-5 font-small text-gray-400' : 'text-white'
            }
          >
            {formatDistanceToNowStrict(new Date(notification.createdAt), {
              addSuffix: false,
            })}{' '}
            ago
          </p>
        </div>
      </div>
    </MenuItem>
  )

  return (
    <div className="fixed left-0 right-0 z-50">
      <nav className="dark:bg-gray-900">
        <div className="mx-auto">
          <div
            style={{
              backgroundColor: 'rgb(31, 41, 55)',
            }}
            className="flex items-center justify-between h-20"
          >
            <div className="flex items-center">
              <div className="hidden md:block">
                <div className="ml-14 flex items-baseline space-x-4">
                  <a
                    className="text-gray-200 dark:hover:text-white px-3 py-2 rounded-md text-3xl font-extrabold"
                    href="/#"
                    onClick={() => {
                      history.push('/')
                    }}
                  >
                    <img className="w-52" src={logoTextOnly} alt="logo" />
                  </a>
                </div>
              </div>
            </div>
            {userInfo.id ? (
              <div className="flex items-center px-4">
                <div className="container relative left-0 z-50 flex w-3/4 h-auto">
                  <SearchBar />
                </div>
                <Box className="md:ml-6" position={'relative'}>
                  <IconButton
                    variant="ghost"
                    aria-label="Message"
                    fontSize="28px"
                    onClick={(e) => {
                      history.push('/')
                    }}
                    icon={<CgFeed />}
                  />
                </Box>
                <Box className="md:ml-6" position={'relative'}>
                  <IconButton
                    variant="ghost"
                    aria-label="Message"
                    fontSize="28px"
                    onClick={(e) => {
                      history.push('/marketplace')
                    }}
                    icon={<FaPeopleArrows />}
                  />
                </Box>
                <Box className="md:ml-6" position={'relative'}>
                  <IconButton
                    variant="ghost"
                    aria-label="Message"
                    fontSize="28px"
                    onClick={(e) => {
                      history.push('/messages')
                      setNewMessageCount(0)
                    }}
                    icon={<BsChatDots />}
                  />
                  {newMessageCount && newMessageCount > 0 ? (
                    <Text
                      boxSize={5}
                      bg="red"
                      color={'white'}
                      fontSize="xs"
                      rounded={'full'}
                      textAlign={'center'}
                      lineHeight={'18px'}
                      position={'absolute'}
                      right={0}
                      top={0}
                    >
                      {newMessageCount}
                    </Text>
                  ) : null}
                </Box>
                <Menu position={'relative'}>
                  <MenuButton
                    padding={1}
                    marginLeft={6}
                    as={IconButton}
                    colorScheme="transparent"
                    aria-label="menu"
                    _hover={{ bg: '#ffffff14' }}
                    _expanded={{ bg: '#ffffff29' }}
                    right={0}
                    bottom={0}
                    fontSize="28px"
                    onClick={(e) => {
                      e.stopPropagation()
                      readNotifications().then().catch()
                      setNewNotificationCount(0)
                    }}
                    style={width < 768 ? { marginLeft: '0px' } : {}}
                    icon={<MdNotificationsNone color="white" />}
                  />
                  {newNotificationCount && newNotificationCount > 0 ? (
                    <Text
                      boxSize={5}
                      bg="red"
                      color={'white'}
                      fontSize="xs"
                      rounded={'full'}
                      textAlign={'center'}
                      lineHeight={'18px'}
                      position={'absolute'}
                      right={0}
                      top={0}
                      className="mt-5 mr-20"
                    >
                      {newNotificationCount}
                    </Text>
                  ) : null}
                  {notifications.length ? (
                    <MenuList
                      zIndex={9999}
                      className="overflow-auto"
                      style={{
                        height: '50vh',
                        width: menuListWidth, // Adjusted width here
                      }}
                    >
                      {notifications.map((notification, index) =>
                        notification?.authorId !== notification.interactUser._id ? (
                          <div key={index}>{menuItem(notification)}</div>
                        ) : null
                      )}
                    </MenuList>
                  ) : (
                    <MenuList
                      className="scroll-smooth"
                      zIndex={9999}
                      style={{
                        height: '10vh',
                        width: menuListWidth, // Adjusted width here
                      }}
                    >
                      <MenuItem>You have no notifications yet</MenuItem>
                    </MenuList>
                  )}
                </Menu>

                <div className={width < 768 ? 'ml-0 flex items-center' : 'ml-4 flex items-center'}>
                  <div className="flex ml-3 relative">
                    <div ref={ref} className="relative inline-block text-left">
                      <div>
                        <button
                          type="button"
                          className="flex items-center justify-center h-10 w-10 rounded-full py-2 text-sm font-medium text-gray-700 dark:text-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                          id="options-menu"
                          onClick={() => setIsComponentVisible(!isComponentVisible)}
                        >
                          <img
                            alt="profile"
                            src={profilePhoto}
                            className="mx-auto object-cover rounded-full h-10 w-10 bg-white"
                          />
                        </button>
                      </div>
                      <div
                        style={{
                          display: isComponentVisible ? 'inherit' : 'none',
                        }}
                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5"
                      >
                        <div
                          className="py-1 cursor-pointer "
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          <a
                            className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              setIsComponentVisible(false)
                              history.push(`/profile/${userInfo.id}`)
                            }}
                          >
                            <span className="flex flex-col">
                              <span className="flex flex-row">
                                <BsPersonCircle
                                  style={{
                                    marginTop: '4px',
                                    marginRight: '8px',
                                  }}
                                />
                                My Profile
                              </span>
                            </span>
                          </a>
                          <a
                            className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                            role="menuitem"
                            onClick={() => {
                              socket.disconnect()
                              localStorage.removeItem('user')
                              history.push('/login')
                            }}
                          >
                            <span className="flex flex-col">
                              <span className="flex flex-row">
                                <BsDoorOpen
                                  style={{
                                    marginTop: '4px',
                                    marginRight: '8px',
                                  }}
                                />
                                Logout
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center px-4">
                <Button
                  className={'w-24'}
                  position={'relative'}
                  text={'Login'}
                  onClick={() => {
                    history.push('/login')
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default TopNavBar
