import React, { useState, useRef, useContext, useEffect } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  useOutsideClick,
  useToast,
  Spinner,
  Input,
} from '@chakra-ui/react'
import { GlobalContext } from '../../..'
import { getUserPublicSearch, getUserSearch } from '../../../services/user.service'
import { useDebounce } from '../../../hooks/useDebounce'
import SimpleConnectionRow from './SimpleConnectionRow'
import AttendeeAdded from './AttendeeAdded'

const CalendarAttendeesAdd = ({ attendesList, setAttendesList }) => {
  const { userInfo, userInfo: currentUserInfo } = useContext(GlobalContext)
  const [searchText, setSearchText] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const newSearchAreaRef = useRef(null)
  const initialFocusRef = useRef(null)
  const [userProfiles, setUserProfiles] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const debouncedSearchTerm = useDebounce(searchText, 500)
  const toast = useToast()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleUseDebounce(), [debouncedSearchTerm])

  const removeAddedAttendeesFromSearch = (searchList) => {
    const idsInAttendeesList = new Set(attendesList.map((profile) => profile.userId))
    return  searchList.filter((profile) => !idsInAttendeesList?.has(profile.userId))
  }

  const onSearchUsers = async () => {
    try {
      if (searchText && userInfo.id) {
        setIsSearching(true)
        const { data } = await getUserSearch(searchText)
        setIsSearching(false)
        if (data) {
          const searchFiltered = removeAddedAttendeesFromSearch(data)
          setUserProfiles([...searchFiltered])
        }
      } else if (searchText) {
        setIsSearching(true)
        const { data } = await getUserPublicSearch(searchText)
        const searchFiltered = removeAddedAttendeesFromSearch(data)
        setIsSearching(false)
        if (data) {
          setUserProfiles([...searchFiltered])
        }
      } else {
        setUserProfiles([])
        setIsSearching(false)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleUseDebounce = () => {
    if (debouncedSearchTerm) {
      setIsSearching(true)
      onSearchUsers()
    } else {
      setUserProfiles([])
      setIsSearching(false)
    }
  }

  useOutsideClick({
    ref: newSearchAreaRef,
    handler: () => setIsOpen(false),
  })

  const handleUserOnSelect = (userProfile) => {
    setSearchText('')
    setIsOpen(false)
    setUserProfiles([])
    const updatedList = [...attendesList, userProfile]
    setAttendesList(updatedList)
  }

  const handleAttendeeOnRemove = (userId) => {
    const updatedList = attendesList.filter((profile) => profile.userId !== userId)
    setAttendesList(updatedList)
  }

  const handleSearchCloseIconOnClick = () => {
    setSearchText('')
    setUserProfiles([])
  }

  const handleOnChange = (target) => {
    setIsOpen(true)
    setSearchText(target.value)
    setIsSearching(true)
  }

  return (
    <>
      <div ref={newSearchAreaRef}>
        <Popover
          initialFocusRef={initialFocusRef}
          isOpen={isOpen}
          placement="bottom-start"
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <div className="relative flex items-center">
              <Input
                ref={initialFocusRef}
                type="text"
                placeholder="Search"
                value={searchText}
                onFocus={() => setIsOpen(true)}
                onChange={({ target }) => {
                  handleOnChange(target)
                }}
              />
              {searchText && (
                <div className="absolute z-50 flex items-center justify-center w-auto h-10 p-3 pr-2 text-sm text-gray-500 uppercase cursor-pointer right-2">
                  <svg
                    onClick={() => handleSearchCloseIconOnClick()}
                    fill="none"
                    className="relative w-5 h-5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                  </svg>
                </div>
              )}
            </div>
          </PopoverTrigger>
          <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
            <PopoverBody>
              {userProfiles.length ? (
                userProfiles
                  .filter((user) => user.id !== currentUserInfo.id)
                  .slice(0, 5)
                  .map((userProfile) => {
                    return (
                      <SimpleConnectionRow
                        key={userProfile.id}
                        userProfile={{
                          ...userProfile,
                        }}
                        onSelect={() => handleUserOnSelect(userProfile)}
                        shortDeleteView={true}
                      />
                    )
                  })
              ) : isSearching ? (
                <Spinner size="sm" color="#6B7280" />
              ) : searchText ? (
                <p>Hit enter for advanced results</p>
              ) : (
                <p>Enter some text to start searching</p>
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
      <div className="flex flex-wrap gap-2 pt-4">
        {attendesList?.map((attendee, i) => {
          return (
            <AttendeeAdded
              key={`${i}`}
              userProfile={attendee}
              onDelete={() => handleAttendeeOnRemove(attendee?.userId)}
            />
          )
        })}
      </div>
    </>
  )
}

export default CalendarAttendeesAdd
