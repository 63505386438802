import React from 'react'
import { Center, Spinner } from '@chakra-ui/react'

const Loader = () => {
  return (
    <Center className="flex w-full justify-center">
      <section className="flex w-full justify-center">
        <Spinner color="purple.600" size="xl" />
      </section>
    </Center>
  )
}

export default Loader
