import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ButtonRed from '../../../components/ButtonRed'
import { deleteAccount } from '../../../services/user.service'

const DeleteAccountModal = ({ isOpen, onClose = () => {} }) => {
  const toast = useToast()
  const history = useHistory()

  const onDeleteAccount = async () => {
    try {
      await deleteAccount()
      localStorage.removeItem('user')
      history.push('/login')
      onClose()
      toast({
        title: `Successfully Deleted`,
        description: `Your account has been deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Account?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete your account? You can't undo this action. By deleting your
          account all your posts and content will be removed with it.
        </ModalBody>

        <ModalFooter>
          <ButtonRed
            colorScheme="red"
            text={'Delete'}
            className="mr-3 w-20"
            onClick={onDeleteAccount}
          />
          <Button colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteAccountModal
