import React, { useCallback, useContext, useEffect, useState } from 'react'
import { getPost, getPostPublic } from '../../services/post.service'
import { format, formatDistanceToNowStrict } from 'date-fns'
import { Divider, HStack, Icon, Link, Skeleton, Text, useToast } from '@chakra-ui/react'
import { PRIVACY_MODES } from '../../components/PrivacySelector'
import { useHistory } from 'react-router'
import FilePreview from '../../components/FilePreview'
import { GlobalContext } from '../..'

const RePost = ({ repostId }) => {
  const [post, setPost] = useState(null)
  const toast = useToast()
  const history = useHistory()
  const [files, setFiles] = useState([])
  const [tags, setTags] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { userInfo } = useContext(GlobalContext)
  const relativeTime = post
    ? formatDistanceToNowStrict(new Date(post?.createdAt), { addSuffix: false })
    : ''
  const readableTime = post ? format(new Date(post?.createdAt), 'dd MMM yyyy hh:mm aaaa') : ''

  useEffect(() => {
    let updated =
      post?.attachments?.map((file) => {
        return {
          ...file,
          original: file.url,
          thumbnail: file.url,
        }
      }) || []
    setFiles(updated)
  }, [post?.attachments])

  useEffect(() => {
    setTags(post?.tags)
  }, [post?.tags])

  const onGetPost = useCallback(async () => {
    try {
      if (userInfo.id) {
        const { data } = await getPost(repostId)
        if (data) {
          setPost({ ...data })
          setIsLoading(false)
        }
      } else {
        const { data } = await getPostPublic(repostId)
        if (data) {
          setPost({ ...data })
          setIsLoading(false)
        }
      }
    } catch (error) {
      if (error.response.status === 404) {
        setIsLoading(false)
      } else {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        setIsLoading(false)
      }
    }
  }, [repostId, toast, userInfo.id])

  useEffect(() => {
    onGetPost().then()
  }, [repostId, onGetPost])

  return (
    <div>
      {isLoading ? (
        <Skeleton height="100px" startColor="#1F2937" />
      ) : post ? (
        <div
          className="cursor-pointer border-gray-600 mb-2 border border-solid rounded-md"
          onClick={(event) => {
            event.stopPropagation()
            history.push(`/${post?.user?.username}/post/${post?.id}`)
          }}
        >
          <article className="hover:bg-gray-900 transition duration-350 ease-in-out pb-4 rounded-md">
            <div className="flex flex-1 flex-shrink-0 p-4 pb-0">
              <div className="flex-shrink-0 flex-1 group block">
                <div className="flex items-center flex-1">
                  <div>
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src={post?.user.photo}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-base leading-6 font-medium text-white">
                      {post?.user.name}
                      <span
                        className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150"
                        title={readableTime}
                      >
                        {` @${post?.user.username} . ${relativeTime}`} .
                      </span>
                      <Icon
                        color={'gray.400'}
                        as={PRIVACY_MODES[post?.privacy_mode || 'public'].icon}
                        boxSize={4}
                        ml={1}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="pl-16">
              <p className="text-base width-auto font-medium text-white flex-shrink mr-4">
                {post?.text}
              </p>
              {tags && tags.length > 0 && (
                <HStack my={2}>
                  <Divider w={6} mr={1} />
                  <Text color={'gray.500'} mr={2}>
                    with
                  </Text>
                  {tags.map((tag, i) => {
                    return (
                      <Link
                        key={tag._id}
                        fontSize={'sm'}
                        onClick={() => {
                          history.push('/profile', {
                            userObject: { ...tag, id: tag._id },
                          })
                        }}
                      >{`${tag.name || ''}${i + 1 >= tags.length ? '' : ', '}`}</Link>
                    )
                  })}
                </HStack>
              )}
              {files && files[0] && (
                <div className="md:flex-shrink pr-6 pt-3 pb-4 max-w-xs ">
                  <FilePreview file={files[0]} />
                </div>
              )}
            </div>
          </article>
        </div>
      ) : (
        <div className="cursor-pointer border-gray-600 border border-solid rounded-md">
          <article className="hover:bg-gray-900 transition duration-350 ease-in-out pb-4 rounded-md">
            <div className="flex flex-1 flex-shrink-0 p-4 pb-0">
              <div className="flex-shrink-0 flex-1 group block">
                <div className="flex items-center flex-1">
                  <div className="ml-3"></div>
                </div>
              </div>
            </div>

            <div className="pl-16">
              <p className="text-base width-auto font-small text-white flex-shrink mr-4">
                Post not found
              </p>
            </div>
          </article>
        </div>
      )}
    </div>
  )
}

export default RePost
