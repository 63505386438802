import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../..'
import { uuidv4 } from '../../services/util.service'
import './ImageUploader.css'
import ImageCropModal from './ImageCropModal'

const ImageUploader = ({
  selectedImage,
  signup,
  onChange,
  containerStyle,
  disabled = false,
  setSignupImage = null,
  signupImage = null,
}) => {
  const [imageUrl, setImageUrl] = useState(selectedImage)
  const { userInfo, updateUserInfo } = useContext(GlobalContext)
  const [showModal, setShowModal] = useState(false)
  const [id] = useState(() => uuidv4())

  useEffect(() => {
    if (userInfo && userInfo.photo) {
      setImageUrl(userInfo.photo)
    }
  }, [userInfo])

  useEffect(() => {
    if (selectedImage instanceof File) {
      let imageURL = URL.createObjectURL(selectedImage)
      setImageUrl(imageURL)
    } else {
      if (selectedImage) {
        setImageUrl(selectedImage)
      }
    }
  }, [selectedImage])

  useEffect(() => {
    if (signupImage instanceof File) {
      let imageURL = URL.createObjectURL(signupImage)
      setImageUrl(imageURL)
    } else {
      if (signupImage) {
        setImageUrl(signupImage)
      }
    }
  }, [signupImage])

  return (
    <div style={{ ...containerStyle }} className={disabled ? '' : 'cursor-pointer'}>
      <label htmlFor={id}>
        <div className="image-picker-container">
          {imageUrl && (
            <div className="image-back">
              <img src={imageUrl} alt="ProfilePicture" className="picked-image" />
              {!disabled && (
                <i
                  onClick={() => setShowModal(true)}
                  className="plus-icon fas fa-plus-circle text-purple-700 shadow-lg"
                ></i>
              )}
            </div>
          )}
        </div>
      </label>
      <ImageCropModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
          updateUserInfo()
        }}
        signup={signup}
        setSignupImage={setSignupImage}
      />
    </div>
  )
}

export default ImageUploader
