import React from 'react'
import Modal from 'react-modal'
import './ModalCustom.css'

const ModalCustom = ({
  isOpen,
  afterModalOpen = () => {},
  onClose = () => {},
  contentStyle = {},
  shouldCloseOnOverlayClick = false,
  shouldCloseOnEsc = false,
  children,
  classNames = '',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterModalOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={classNames}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          position: 'relative',
          inset: 0,
          border: '1px solid transparent',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px',
          ...contentStyle,
        },
      }}
    >
      <button
        type="button"
        className="absolute top-3 right-3 w-6 h-6 rounded-full items-center justify-center flex bg-gray-200 hover:bg-gray-200 text-xl text-gray-400"
        onClick={onClose}
      >
        <i className="fas fa-times"></i>
      </button>
      <div className="h-full w-full">{children}</div>
    </Modal>
  )
}

export default ModalCustom
