import React from 'react'
import logoWithName from '../../assets/svgs/CoNectar_with_text.svg'
import { GoogleLogin } from '@react-oauth/google'
import Button from '../../components/Button'
import useGoogleFetch from '../../hooks/useGoogleFetch'

const CoNectarOrGoogleAccount = ({ history }) => {
  const { handleGoogle } = useGoogleFetch(
    `${process.env.REACT_APP_BACKEND_URL}/v1/auth/signUpGoogle`, 
    true
  )
  const responseMessage = (response) => {
    handleGoogle(response)
  }

  const errorMessage = (response) => {
    console.log(response)
  }

  return (
    <div className="flex flex-1 justify-center items-center h-full w-full">
      <div className="flex flex-col w-full max-w-md px-4 mx-3 pb-8 bg-white rounded-lg shadow-2xl dark:bg-gray-800 sm:mx-3 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center flex justify-center items-center">
          <img className="w-60 -mt-6 -mb-6" src={logoWithName} alt="logo" />
        </div>
        <div className="mt-2">
          <div className="flex justify-center flex-col items-center">
            <Button
              className="cursor-pointer m-4 bg-white text-black hover:text-white hover:bg-blue-500 w-full font-normal"
              text="Create a Co-Nectar Account"
              type="submit"
              onClick={() => history.push({ pathname: '/signup' })}
            />

            <div style={{ width: '100%' }}>
              <GoogleLogin width="100%" onSuccess={responseMessage} onError={errorMessage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoNectarOrGoogleAccount
