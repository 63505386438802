import React from 'react'
import 'video.js/dist/video-js.css'
import { useVideoJS } from 'react-hook-videojs'

export const VideoPlayer = ({ options, playerClassName = null }) => {
  const { Video, ready } = useVideoJS(options, playerClassName)
  if (ready) {
  }
  return (
    <>
      <Video />
    </>
  )
}
