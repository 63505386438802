import React, { useState } from 'react'
import useComponentVisible from '../../hooks/useComponentVisible'
import ModalCustom from '../../components/ModalCustom/ModalCustom'
import { IoPersonAddOutline, IoPersonRemoveOutline } from 'react-icons/io5'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import { createContact, deleteContact } from '../../services/user.service'
import Button from '../../components/Button'
import { BUTTON_VARIANT } from '../../utils/app-constants'
import { useHistory } from 'react-router-dom'

const ConnectionRow = ({
  userProfile,
  currentUserId,
  onAddConnection = () => {},
  onRemoveConnection = () => {},
  onSelect = () => {},
  hideRightButton = false,
  shortDeleteView = false,
  setIsOpen,
  isNewUser,
}) => {
  const history = useHistory()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { ref } = useComponentVisible(false)

  const { id, photo, connected, name, username, connections = [] } = userProfile

  /*
   * TODO: this is a temporary fix, please figure out why the connection state
   * in the database does not reflect immediately
   */
  const [isConnected, setIsConnected] = useState(connected || false)

  const onRemoveContact = async () => {
    setIsLoading(true)
    setShowDeleteConfirmation(false)
    try {
      let connection = connections.find((x) => x.userId === currentUserId)
      connection && (await deleteContact(connection.connectionId))
      onRemoveConnection()
      if (isConnected) setIsConnected(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onCreateContact = async () => {
    setIsLoading(true)
    try {
      await createContact(id)
      onAddConnection()
      if (!isConnected) setIsConnected(true)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onHandleRedirect = () => {
    if (isNewUser) {
      onSelect(userProfile)
    } else {
      history.push(`/profile/${userProfile.id}`)
      setIsOpen(false)
    }
  }

  return (
    <li className="list-none cursor-pointer" onClick={onHandleRedirect}>
      <article className="hover:bg-gray-800 transition duration-350 ease-in-out">
        <div className="flex flex-1 flex-shrink-0 p-4">
          <div className="flex-shrink-0 flex-1 group block">
            <div className="flex items-center flex-1 mb-4">
              <div>
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={photo || profilePicture}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <p className="text-base leading-6 font-medium text-white">{name}</p>
                <span className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
                  {` @${username}`}
                </span>
              </div>
              {!hideRightButton && (
                <div ref={ref} className="flex flex-1 justify-end relative">
                  {!isConnected ? (
                    <Button
                      isLoading={isLoading}
                      variant={BUTTON_VARIANT.ICON}
                      Icon={() => <IoPersonAddOutline className="text-white" size={16} />}
                      onClick={(event) => {
                        event.stopPropagation()
                        onCreateContact()
                      }}
                    />
                  ) : (
                    <Button
                      isLoading={isLoading}
                      variant={BUTTON_VARIANT.ICON}
                      className="border-2 border-blue-800"
                      Icon={() => <IoPersonRemoveOutline className="text-white" size={16} />}
                      onClick={(event) => {
                        event.stopPropagation()
                        if (shortDeleteView) {
                          onRemoveContact()
                        } else {
                          setShowDeleteConfirmation(true)
                        }
                      }}
                    />
                  )}
                  {showDeleteConfirmation && (
                    <ConfirmationModal
                      onPrimary={(event) => {
                        event.stopPropagation()
                        onRemoveContact()
                      }}
                      onClose={(event) => {
                        event.stopPropagation()
                        onRemoveConnection()
                        setShowDeleteConfirmation(false)
                      }}
                      onSecondary={(event) => {
                        event.stopPropagation()
                        onRemoveConnection()
                        setShowDeleteConfirmation(false)
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <hr className="border-gray-800" />
      </article>
    </li>
  )
}

export default ConnectionRow

const ConfirmationModal = ({
  onPrimary = () => {},
  onSecondary = () => {},
  onClose = () => {},
}) => {
  return (
    <ModalCustom onClose={onClose} isOpen={true}>
      <div className="rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
        <div className="w-full h-full text-center">
          <div className="flex h-full flex-col justify-between">
            <svg
              width="40"
              height="40"
              className="mt-4 w-12 h-12 m-auto text-indigo-500"
              fill="currentColor"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"></path>
            </svg>
            <p className="text-gray-800 dark:text-gray-200 text-xl font-bold mt-4">
              Remove contact
            </p>
            <p className="text-gray-600 dark:text-gray-400 text-xs py-2 px-6">
              Are you sure you want to delete this contact ?
            </p>
            <div className="flex items-center justify-between gap-4 w-full mt-8">
              <button
                type="button"
                onClick={onPrimary}
                className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                Remove
              </button>
              <button
                type="button"
                onClick={onSecondary}
                className="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-indigo-500 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCustom>
  )
}
