import React, { useState } from 'react'
import { Skeleton } from '@chakra-ui/react'
import RenderImageModal from './RenderImageModal'

const RenderImage = ({ photo }) => {
  const [imgLoaded, setImgLoaded] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  return (
    <>
      {!imgLoaded && (
        <Skeleton
          height={{
            base: '300px',
            xl: '400px',
          }}
          fadeDuration={0.1}
          startColor="#1F2937"
          isLoaded={imgLoaded}
        />
      )}
      {showImageModal && (
        <RenderImageModal photo={photo} onClose={() => setShowImageModal(false)} />
      )}
      <img
        style={imgLoaded ? { height: '360px', margin: 'auto' } : { display: 'none' }}
        alt=""
        src={photo}
        onClick={() => setShowImageModal(true)}
        onLoad={() => setImgLoaded(true)}
      />
    </>
  )
}

export default RenderImage
