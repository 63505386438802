export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ICON: 'icon',
}

export const FILE_TYPE_MAP = {
  pdf: 'pdf',
  mp3: 'audio',
  mp4: 'video',
  gif: 'gif',
  png: 'photo',
  jpg: 'photo',
  jpeg: 'photo',
  svg: 'photo',
  tiff: 'photo',
}
