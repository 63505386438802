import { Box, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { createPaymentIntent } from '../../../services/payment.service'
import CheckoutForm from './CheckoutForm'

const Checkout = () => {
  const location = useLocation()
  const [cartItems, setCartItems] = useState([])
  const [clientSecret, setClientSecret] = useState('')
  const [stripePromise, setStripePromise] = useState(null)

  const getStripeData = async () => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
    const secret = await createPaymentIntent()
    const { clientSecret: stripeSecret } = secret.data
    setClientSecret(stripeSecret)
  }

  useEffect(() => {
    const service = location?.state?.service
    if (service) {
      const cartItem = { ...service, quantitySelected: 1 }
      setCartItems([cartItem])
    }
    getStripeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box h={'full'} pt={20}>
      <Box h={'full'}>
        <Box
          maxW={{
            base: '5xl',
            lg: '10xl',
          }}
          mx="auto"
          px={{
            base: '4',
            md: '8',
            lg: '12',
          }}
          py={{
            base: '6',
            md: '8',
            lg: '12',
          }}
        >
          <Stack
            direction={{
              base: 'column',
              lg: 'row',
            }}
            align={{
              lg: 'flex-start',
            }}
            spacing={{
              base: '8',
              md: '16',
            }}
          >
            <Stack
              spacing={{
                base: '8',
                md: '10',
              }}
              flex="2"
            >
              {stripePromise && clientSecret && (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret,
                    appearance: {
                      theme: 'night',
                      variables: {
                        colorPrimary: '#9f7aea',
                      },
                    },
                  }}
                >
                  <CheckoutForm total={cartItems[0]?.price * cartItems[0]?.quantitySelected || 0} />
                </Elements>
              )}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default Checkout
