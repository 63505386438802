import {
  Avatar,
  Box,
  Center,
  CloseButton,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  VStack,
  Text,
  Icon,
  Flex,
  Heading,
  Spinner,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
  Image,
} from '@chakra-ui/react'
import Peer from 'simple-peer'
import { GlobalContext } from '../../'
import { BiBlock } from 'react-icons/bi'
import useState from 'react-usestateref'
import { MdCallEnd } from 'react-icons/md'
import { BsFillChatFill } from 'react-icons/bs'
import {
  acceptCall,
  callUser,
  createMessage,
  createMessageWithFiles,
  endCall,
  getMessages,
} from '../../services/conversations.service'
import { ImFilesEmpty, ImArrowDown2 } from 'react-icons/im'
import { IoMdMic, IoMdMicOff } from 'react-icons/io'
import { uuidv4 } from '../../services/util.service'
import scrollIntoView from 'scroll-into-view-if-needed'
import React, { useContext, useEffect, useRef } from 'react'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import { IoAttachOutline, IoSendSharp } from 'react-icons/io5'
import EmojiPickerPopover from './components/EmojiPickerPopover'
import { MdScreenShare, MdStopScreenShare } from 'react-icons/md'

const CALL_STATUS = {
  CALLING: 'calling',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  UNREACHABLE: 'unreachable',
  PERMISSION_ERROR: 'no-permission',
}

const SCROLL_TARGET = 'chat-windows-scroll-target-' + uuidv4()

const CallWindow = ({
  onEnd,
  userProfile,
  message = {},
  currentThreadId,
  callerSignal = '',
  setLastVideoCallId,
  isVideoCall = true,
  handleAfterNewMessage,
  isOutGoingCall = false,
  setIsVideoCall = () => {},
}) => {
  const [muted, setMuted] = useState(false)
  const [myPeer, setMyPeer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [userMessages, setUserMessages] = useState([])
  const [typedMessage, setTypedMessage] = useState('')
  const [screenStream, setScreenStream] = useState(null)
  const [countDownTime, setCountDownTime] = useState(10)
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const [showChat, setShowChat, showChatRef] = useState(false)
  const [isSharingScreen, setIsSharingScreen] = useState(false)
  const [, setPartnerStream, partnerStreamRef] = useState(null)
  const [callStatus, setCallStatus] = useState(CALL_STATUS.CONNECTING)
  const [callMessage, setCallMessage, callMessageRef] = useState(message)
  const [myVideoStream, setMyVideoStream, myVideoStreamRef] = useState(null)

  const { userInfo: myInfo, socket } = useContext(GlobalContext)

  const toast = useToast()

  const myVideo = useRef(null)
  const partnerVideo = useRef(null)
  const fileInputRef = useRef(null)

  const [attachments, setAttachments] = useState([])
  const [isMessageSend, setIsMessageSend] = useState(false)

  const allowedExtensions = [
    'pdf',
    'png',
    'gif',
    'jpg',
    'jpeg',
    'mp3',
    'mp4',
    'avi',
    'xlsx',
    'xls',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'txt',
  ]

  const shareScreen = async () => {
    setIsSharingScreen(true)
    try {
      const captureStream = await navigator.mediaDevices.getDisplayMedia({})
      const captureStreamTrack = captureStream?.getTracks().find((track) => track.kind === 'video')

      setScreenStream(captureStream)
      setMyVideoStream(captureStream)

      if (myVideo.current) {
        myVideo.current.srcObject = captureStream
      }

      if (myPeer) {
        myPeer.streams[0].getVideoTracks()[0].stop()
        myPeer.replaceTrack(
          myPeer.streams[0].getVideoTracks()[0],
          captureStreamTrack,
          myPeer.streams[0]
        )
      }
    } catch (error) {
      toast({
        title: 'Screen Sharing Error',
        description: 'Unable to share screen, please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      setIsSharingScreen(false)
    }
  }

  const stopShareScreen = async () => {
    setIsSharingScreen(false)
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: isVideoCall,
        audio: true,
      })

      const videoStreamTrack = videoStream?.getTracks().find((track) => track.kind === 'video')

      setMyVideoStream(videoStream)

      if (myVideo.current) {
        myVideo.current.srcObject = videoStream
      }

      if (myPeer) {
        if (screenStream) {
          screenStream.getTracks().forEach((track) => track.stop())
          setScreenStream(null)
        }

        myPeer.streams[0].getVideoTracks()[0].stop()
        myPeer.replaceTrack(
          myPeer.streams[0].getVideoTracks()[0],
          videoStreamTrack,
          myPeer.streams[0]
        )
      }
    } catch (error) {
      toast({
        title: 'Camera Error',
        description: 'Unable to switch back to camera, please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: isVideoCall, audio: true })
      .then((stream) => {
        setMyVideoStream(stream)
        if (myVideo.current) {
          myVideo.current.srcObject = stream
        }
        if (isOutGoingCall) {
          onCallPeer(stream, userProfile)
        } else {
          onAcceptCall(stream, userProfile, callMessage)
          onGetMessages(callMessage.threadId || currentThreadId)
        }
      })
      .catch((_) => {
        setCallStatus(CALL_STATUS.PERMISSION_ERROR)
      })

    socket.on('endCall', () => {
      handleVideoAndCallEnd()
    })

    socket.on('rejectedCall', () => {
      handleVideoAndCallEnd()
    })

    socket?.on('message', (data) => handleOnMessage(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    socket?.on('no_user', (message) => {
      handleCallEnd()
      toast({
        title: `We're Sorry`,
        description: message,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, toast])

  useEffect(() => {
    if (callStatus === CALL_STATUS.CONNECTED) {
      if (partnerVideo?.current && partnerStreamRef?.current) {
        partnerVideo.current.srcObject = partnerStreamRef.current
      }
    }

    if (callStatus === CALL_STATUS.PERMISSION_ERROR) {
      const interval = setInterval(() => {
        if (countDownTime > 0) {
          setCountDownTime((prevValue) => prevValue - 1)
        } else {
          clearInterval(interval)
        }
      }, 1000)

      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStatus, partnerStreamRef])

  useEffect(() => {
    if (countDownTime === 0) {
      setIsVideoCall(false)
    }
  }, [countDownTime, setIsVideoCall])

  const handleVideoAndCallEnd = () => {
    if (myVideoStreamRef?.current) {
      myVideoStreamRef.current.getTracks().forEach((track) => track.stop())
    }

    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop())
      setScreenStream(null)
    }

    if (myVideo?.current) {
      myVideo.current.srcObject = null
    }

    socket.off('endCall')
    socket.off('rejectedCall')
    socket.off('message')
    socket.off('no_user')

    onEnd()
  }

  const onCallPeer = (stream, userProfile) => {
    setLastVideoCallId(userProfile?._id)
    setIsLoading(true)

    const peer = new Peer({
      initiator: true,
      trickle: false,
      config: {
        iceServers: [
          {
            urls: 'stun:openrelay.metered.ca:80',
          },
          {
            urls: 'turn:openrelay.metered.ca:80',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
          {
            urls: 'turn:openrelay.metered.ca:443',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
          {
            urls: 'turn:openrelay.metered.ca:443?transport=tcp',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
        ],
      },
      stream,
    })

    peer.on('signal', async (data) => {
      const reqObject = {
        message: {
          type: 2,
          text: '',
          signalData: data,
          connectionId: socket?.id,
          receiverId: userProfile._id,
        },
        isVideoCall,
      }

      try {
        const response = await callUser(reqObject)
        const { data: responseData } = response

        setCallMessage(responseData.message)

        await onGetMessages(responseData.message.threadId || currentThreadId)

        setIsLoading(false)
      } catch (error) {
        if (error) {
          toast({
            duration: 5000,
            isClosable: true,
            status: 'warning',
            title: 'Unable to Connect',
            description: 'The user is currently unavailable. Please try again later.',
          })
        }
        onEnd()
      }
    })

    peer.on('stream', (stream) => {
      setPartnerStream(stream)
      if (partnerVideo?.current) {
        partnerVideo.current.srcObject = stream
      }
    })

    socket.on('callAccepted', (signal) => {
      setCallStatus(CALL_STATUS.CONNECTED)
      peer.signal(signal)
    })

    setMyPeer(peer)
  }

  const onAcceptCall = (stream, userProfile, callMessage) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    })

    peer.on('signal', async (data) => {
      const reqObject = {
        message: {
          text: '',
          receiverId: userProfile._id,
          type: 2,
          connectionId: socket?.id,
          messageId: callMessage.id,
          signalData: data,
        },
      }
      await acceptCall(reqObject)
    })

    peer.on('stream', (stream) => {
      setPartnerStream(stream)
      setCallStatus(CALL_STATUS.CONNECTED)
    })

    peer.signal(callerSignal)

    setMyPeer(peer)
  }

  const handleCallEnd = async () => {
    const reqObject = {
      message: {
        text: '',
        receiverId: userProfile._id,
        type: 2,
        messageId: callMessageRef.current.id,
        connectionId: socket?.id,
      },
    }
    await endCall(reqObject)
    handleVideoAndCallEnd()
  }

  const handleMute = () => {
    let streamAudio = myVideoStreamRef.current.getAudioTracks()[0]
    streamAudio.enabled = muted
    setMuted(!muted)
  }

  const handleOnMessage = (data) => {
    if (!showChatRef?.current) {
      setHasNewMessage(true)
    }

    const { message, thread } = data

    setUserMessages((prevMessages) => [...prevMessages, message])
    handleAfterNewMessage(message, thread, message.senderId)
    scrollChatToBottom()
  }

  const onGetMessages = async (threadId) => {
    try {
      setIsLoading(true)
      let { data: messages } = await getMessages(threadId)

      setUserMessages(messages || [])
      setIsLoading(false)
      scrollChatToBottom()
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Failed to Load Messages',
        description: error.message || 'An error occurred while fetching messages.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onMessageCreate = async () => {
    if (typedMessage.trim()) {
      try {
        setIsMessageSend(true)

        const createMessageReq = {
          message: {
            type: 1,
            text: typedMessage,
            receiverId: userProfile._id,
          },
        }

        setTypedMessage('')

        let response = await createMessage(createMessageReq)

        const { message } = response.data

        setUserMessages((prevMessages) => [...prevMessages, message])

        handleAfterNewMessage(message, null, message.receiverId)

        setIsMessageSend(false)
        scrollChatToBottom()
      } catch (error) {
        console.error('send message failed', error)
        setIsMessageSend(false)
      }
    }
  }

  const onMessageCreateWithFiles = async () => {
    try {
      if (attachments.length || typedMessage) {
        setIsMessageSend(true)

        const formData = new FormData()

        let reqObject = {
          message: {
            text: typedMessage,
            receiverId: userProfile._id,
            connectionId: socket?.id,
            type: 1,
          },
        }

        formData.append('message', JSON.stringify(reqObject.message))

        if (attachments.length) {
          attachments.forEach((file) => {
            formData.append('files', file)
          })
        }

        setTypedMessage('')

        let response = await createMessageWithFiles(formData)
        const { message } = response.data

        await onGetMessages(message.threadId)

        setAttachments([])
        setIsMessageSend(false)
      }
    } catch (error) {
      setIsMessageSend(false)
      toast({
        title: 'Failed to Send Message with Files',
        description:
          error.message === 'Request failed with status code 409'
            ? 'The content of your message violates the safety policies of the platform.'
            : error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const scrollChatToBottom = () => {
    let currentDOMItem = document.getElementById(SCROLL_TARGET)
    if (currentDOMItem) {
      setTimeout(() => {
        scrollIntoView(currentDOMItem, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
        })
      }, 200)
    }
  }

  const handleEmojiSelect = (emoji) => {
    setTypedMessage((prev) => `${prev}${emoji}`)
  }

  const handleOnFilePick = (event) => {
    const files = event.target.files
    const newFiles = Array.from(files)
    const validFiles = []

    newFiles.forEach((file) => {
      const fileName = file.name
      const lastIndex = fileName.lastIndexOf('.')
      const extension = fileName.slice(lastIndex + 1).toLowerCase()
      if (!allowedExtensions.includes(extension)) {
        toast({
          title: `Extension Not Allowed`,
          description: `${extension.toUpperCase()} files are not allowed to upload.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }
      validFiles.push(file)
    })

    if (validFiles.length > 0) {
      setAttachments((prev) => [...prev, ...validFiles])
    }
  }

  const generateFileName = (fileName, maxLength) => {
    const lastIndex = fileName.lastIndexOf('.')
    const extension = fileName.slice(lastIndex + 1)

    let name = fileName.slice(0, lastIndex)
    if (name.length > maxLength) {
      name = name.slice(0, maxLength) + '...'
    }

    return `${name}.${extension}`
  }

  const handleFileDownload = (files) => {
    files.forEach((file) => {
      const link = document.createElement('a')
      link.href = file.url
      link.target = '_blank'
      link.rel = 'noopener noreferrer'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  const onRemoveFile = (index) => {
    const updatedFiles = [...attachments]
    updatedFiles.splice(index, 1)
    setAttachments(updatedFiles)
  }

  return (
    <Modal onClose={handleCallEnd} size={'full'} isOpen={true}>
      <ModalContent>
        <ModalBody p={0} h={'100vh'} flex={'none'}>
          <Box h={'full'} boxSizing={'border-box'}>
            <HStack alignItems={'stretch'} spacing={0} h={'full'}>
              <VStack flex={5} alignItems={'stretch'} spacing={0} h={'full'}>
                <VStack alignItems={'stretch'} spacing={0} h={'full'} position={'relative'}>
                  <HStack
                    p={5}
                    justifyContent={'center'}
                    spacing={4}
                    pos={'absolute'}
                    right={2}
                    top={2}
                    zIndex={20}
                  >
                    <Box pos={'relative'}>
                      <IconButton
                        rounded={'full'}
                        variant={'ghost'}
                        aria-label={'Chat'}
                        title={'Chat'}
                        color={showChat ? 'primary.400' : 'white'}
                        icon={<BsFillChatFill size={20} />}
                        onClick={() => {
                          setHasNewMessage(false)
                          setShowChat(!showChat)
                          onGetMessages(
                            currentThreadId || JSON.parse(localStorage.getItem('thread'))?._id
                          )
                          !showChat && scrollChatToBottom()
                        }}
                      />
                      <Box
                        className={`absolute bg-red-500 border-2 border-white rounded-full bottom-2 right-2 w-3 h-3 ${
                          hasNewMessage ? '' : 'hidden'
                        }`}
                      />
                    </Box>
                  </HStack>

                  <Box
                    position={'absolute'}
                    bottom={4}
                    left={4}
                    bg={'blackAlpha.300'}
                    rounded={'md'}
                    py={2}
                    px={4}
                    zIndex={20}
                  >
                    <Text fontSize="xs">{`${userProfile.name || 'User'}`}</Text>
                  </Box>

                  <Box
                    position={'absolute'}
                    bottom={4}
                    right={4}
                    bg={'gray.900'}
                    rounded={'md'}
                    h={152}
                    w={260}
                    zIndex={20}
                    overflow={'hidden'}
                    shadow={'lg'}
                  >
                    {myVideoStream && (
                      <Box w={'full'} h={'full'} position={'relative'}>
                        <video
                          ref={myVideo}
                          autoPlay
                          muted
                          style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'contain',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            opacity: isVideoCall ? 1 : 0,
                          }}
                          playsInline
                        />
                        {!isVideoCall && (
                          <Center
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            zIndex={10}
                          >
                            <Avatar size="lg" name={myInfo.name || 'Me'} src={myInfo.photo || ''} />
                          </Center>
                        )}
                        {muted && (
                          <Icon
                            color={'white'}
                            boxSize={6}
                            as={IoMdMicOff}
                            pos={'absolute'}
                            left={4}
                            bottom={4}
                          />
                        )}
                      </Box>
                    )}
                  </Box>

                  {callStatus === CALL_STATUS.PERMISSION_ERROR && isVideoCall ? (
                    <Center h={'full'}>
                      <VStack>
                        <Icon color={'white'} boxSize={32} as={BiBlock} />
                        <Heading fontSize="2xl" fontWeight={'semibold'}>
                          {`You Haven't Allowed Your Camera`}
                        </Heading>
                        <Text textAlign={'center'}>
                          Allow your camera from permission settings so others on the call can see
                          and hear you.
                          <br />
                          Otherwise, the call will switch to a voice call in {countDownTime}...
                        </Text>
                      </VStack>
                    </Center>
                  ) : callStatus !== CALL_STATUS.CONNECTED ? (
                    <Center h={'full'}>
                      <VStack>
                        <Avatar
                          size="2xl"
                          name={userProfile.name || ''}
                          src={userProfile.photo || ''}
                        />
                        <Text fontSize="2xl" fontWeight={'semibold'}>
                          {`Calling ${userProfile.name || 'User'}`}...
                        </Text>
                      </VStack>
                    </Center>
                  ) : (
                    <Box h={'100%'} position="relative">
                      <video
                        ref={partnerVideo}
                        autoPlay
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          opacity: isVideoCall ? 1 : 0,
                        }}
                        playsInline
                      />
                      {!isVideoCall && (
                        <Center
                          h="full"
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          bg="gray.900"
                        >
                          <VStack>
                            <Avatar
                              size="2xl"
                              name={userProfile.name || ''}
                              src={userProfile.photo || ''}
                            />
                            <Text fontSize="2xl" fontWeight={'semibold'}>
                              {userProfile.name}
                            </Text>
                          </VStack>
                        </Center>
                      )}
                    </Box>
                  )}
                </VStack>

                <HStack bg={'gray.800'} p={5} justifyContent={'center'} spacing={4}>
                  {isVideoCall && (
                    <IconButton
                      rounded={'full'}
                      bg={'white'}
                      aria-label={'Share Screen'}
                      size={'lg'}
                      title={'Share Screen'}
                      icon={
                        isSharingScreen ? (
                          <MdStopScreenShare color={'black'} size={26} />
                        ) : (
                          <MdScreenShare color={'black'} size={26} />
                        )
                      }
                      onClick={() => (isSharingScreen ? stopShareScreen() : shareScreen())}
                    />
                  )}
                  <IconButton
                    rounded={'full'}
                    bg={'white'}
                    aria-label={'Mute/Unmute'}
                    size={'lg'}
                    title={'Mute/Unmute'}
                    icon={
                      muted ? (
                        <IoMdMicOff color={'black'} size={26} />
                      ) : (
                        <IoMdMic color={'black'} size={26} />
                      )
                    }
                    onClick={handleMute}
                  />
                  <IconButton
                    rounded={'full'}
                    bg={'red.500'}
                    aria-label={'End Call'}
                    size={'lg'}
                    title={'End Call'}
                    icon={<MdCallEnd color={'white'} size={24} />}
                    onClick={handleCallEnd}
                  />
                </HStack>
              </VStack>

              <Flex
                display={showChat ? 'flex' : 'none'}
                flex={2}
                h={'full'}
                direction={'column'}
                bg={'gray.900'}
                pt={5}
              >
                {isLoading ? (
                  <Center h={'full'}>
                    <Spinner size="xl" />
                  </Center>
                ) : (
                  <>
                    <Box className="flex-col-reverse justify-start chat-body p-4 flex-1 overflow-y-scroll">
                      <VStack spacing={4} align="stretch">
                        {userMessages.map((message, i) => {
                          let isMyMessage = message.senderId === myInfo.id
                          let messageText = message.text
                          if (message.type === 2) {
                            messageText = isMyMessage
                              ? `You called ${userProfile?.name || ''}`
                              : `${userProfile?.name || ''} called you`
                          }
                          return (
                            <Flex
                              key={i}
                              justify={isMyMessage ? 'flex-end' : 'flex-start'}
                              align="center"
                            >
                              {!isMyMessage && (
                                <Avatar
                                  size="sm"
                                  name={userProfile?.name || 'User'}
                                  src={userProfile?.photo || profilePicture}
                                  mr={2}
                                />
                              )}
                              <Box
                                bg={isMyMessage ? 'blue.700' : 'gray.800'}
                                color="white"
                                px={4}
                                py={2}
                                borderRadius="lg"
                                maxW="70%"
                                fontStyle={message.type === 2 ? 'italic' : 'normal'}
                              >
                                {messageText}
                                {message.attachments && message.attachments.length > 0 && (
                                  <Box mt={2}>
                                    {message.attachments.map((file, index) => (
                                      <Flex key={index} align="center" mb={2}>
                                        <ImFilesEmpty size={20} />
                                        <Text ml={2}>{generateFileName(file.name, 10)}</Text>
                                        <Tooltip label="Download File">
                                          <IconButton
                                            aria-label="Download File"
                                            icon={<ImArrowDown2 />}
                                            size="sm"
                                            ml={2}
                                            onClick={() => handleFileDownload([file])}
                                          />
                                        </Tooltip>
                                      </Flex>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                            </Flex>
                          )
                        })}
                        <Box id={SCROLL_TARGET} />
                      </VStack>
                    </Box>
                    <Box p={6} bg="gray.800">
                      <Flex align="center">
                        <EmojiPickerPopover onSelectEmoji={handleEmojiSelect} />
                        <Tooltip label="Attach Files" aria-label="Attach Files">
                          <IconButton
                            rounded="full"
                            variant="ghost"
                            aria-label="Attach Files"
                            icon={<IoAttachOutline size={24} />}
                            onClick={() => fileInputRef.current.click()}
                          />
                        </Tooltip>
                        <input
                          ref={fileInputRef}
                          type="file"
                          accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
                          multiple
                          onChange={handleOnFilePick}
                          onClick={(event) => {
                            event.target.value = ''
                          }}
                          hidden
                        />
                        <InputGroup>
                          <Input
                            variant="filled"
                            placeholder="Type your message..."
                            value={typedMessage}
                            onChange={(e) => setTypedMessage(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                if (attachments.length > 0) {
                                  onMessageCreateWithFiles()
                                } else {
                                  onMessageCreate()
                                }
                              }
                            }}
                            bg="gray.700"
                            color="white"
                            borderRadius="full"
                            pl={4}
                            pr={10}
                          />
                          <InputRightElement width="3rem">
                            <IconButton
                              aria-label="Send Message"
                              icon={<IoSendSharp />}
                              colorScheme="blue"
                              variant="ghost"
                              rounded="full"
                              onClick={() => {
                                if (attachments.length > 0) {
                                  onMessageCreateWithFiles()
                                } else {
                                  onMessageCreate()
                                }
                              }}
                              isLoading={isMessageSend}
                            />
                          </InputRightElement>
                        </InputGroup>
                      </Flex>
                      {attachments.length > 0 && (
                        <Box mt={4} bg="gray.700" p={2} borderRadius="md">
                          <Text color="white" mb={2}>
                            Attached Files:
                          </Text>
                          <Flex wrap="wrap" gap={2}>
                            {attachments.map((file, index) => {
                              const isImage = file.type.startsWith('image/')
                              const isVideo = file.type.startsWith('video/')
                              const isAudio = file.type.startsWith('audio/')
                              return (
                                <Box
                                  key={index}
                                  position="relative"
                                  border="1px solid"
                                  borderColor="gray.600"
                                  borderRadius="md"
                                  p={2}
                                  bg="gray.800"
                                  minW="100px"
                                >
                                  {isImage && (
                                    <Image
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                      boxSize="80px"
                                      objectFit="cover"
                                      borderRadius="md"
                                      mb={2}
                                    />
                                  )}
                                  {isVideo && (
                                    <Box boxSize="80px" mb={2} zIndex={10}>
                                      <video
                                        src={URL.createObjectURL(file)}
                                        width="100%"
                                        height="100%"
                                        controls
                                        style={{ borderRadius: '8px' }}
                                      />
                                    </Box>
                                  )}
                                  {isAudio && (
                                    <Flex align="center" mb={2}>
                                      <ImFilesEmpty size={20} />
                                      <Text ml={2} isTruncated>
                                        {generateFileName(file.name, 10)}
                                      </Text>
                                    </Flex>
                                  )}
                                  {!isImage && !isVideo && !isAudio && (
                                    <Flex align="center" mb={2}>
                                      <ImFilesEmpty size={20} />
                                      <Text ml={2} isTruncated>
                                        {generateFileName(file.name, 10)}
                                      </Text>
                                    </Flex>
                                  )}
                                  <Tooltip label="Remove File" aria-label="Remove File">
                                    <CloseButton
                                      size="sm"
                                      position="absolute"
                                      top="0"
                                      right="0"
                                      onClick={() => onRemoveFile(index)}
                                    />
                                  </Tooltip>
                                </Box>
                              )
                            })}
                          </Flex>
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Flex>
            </HStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CallWindow
