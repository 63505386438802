import apiClient from '../http-clients/api.client'

export const getNotifications = async (pageSize, pageIndex) => {
  return await apiClient.get(`/v1/notification?pageSize=${pageSize}&pageIndex=${pageIndex}`)
}

export const getNotification = async (id) => {
  return await apiClient.get(`/v1/notification/${id}`)
}

export const readNotifications = async () => {
  return await apiClient.post(`/v1/notification/read`)
}
