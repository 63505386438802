import React, { useRef, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { IoImageOutline } from 'react-icons/io5'
import { update } from '../../services/user.service'

const ImageCropModal = ({ signup, isOpen, onClose = () => {}, setSignupImage = null }) => {
  const cropperRef = useRef(null)
  const fileInputRef = useRef(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [cropper, setCropper] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const handleCrop = () => {
    if (typeof cropperRef.current?.cropper?.getCroppedCanvas() === 'undefined') {
      return
    }
    const croppedImage = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL()
    setImageUrl(croppedImage)
  }

  const getNewAvatarUrl = (e) => {
    if (e.target.files) {
      setImageUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  const uploadImage = async () => {
    if (cropper) {
      setIsLoading(true)
      const file = await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], 'newAvatar.png', { type: 'image/png' })
        })
      if (file) {
        const userInfoJSON = localStorage.getItem('user')
        let userInfoObject = JSON.parse(userInfoJSON)
        let userPayload = {
          id: userInfoObject.id,
          name: userInfoObject.name || '',
          username: userInfoObject.username,
          email: userInfoObject.email,
          photo: file && file instanceof File ? file : null,
        }
        try {
          const res = await update(userPayload)
          if (res?.data?.reason === 'INAPPROPRIATE_CONTENT') {
            toast({
              title: 'Oops! Something Went Wrong',
              description: `${res?.data?.message}`,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          } else {
            toast({
              title: `Photo Updated`,
              description: `${res?.data?.message}`,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
          }
          setIsLoading(false)
          setImageUrl(null)
          onClose()
        } catch (error) {
          setIsLoading(false)
          toast({
            title: 'Oops! Something Went Wrong',
            description: `${error?.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      }
    } else {
      setIsLoading(false)
    }
  }

  const signupImage = async () => {
    if (cropper) {
      setIsLoading(true)
      const file = await fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], 'newAvatar.png', { type: 'image/png' })
        })
      if (file) {
        setSignupImage(file)
        setIsLoading(false)
        setImageUrl(null)
        onClose()
      }
    } else {
      setIsLoading(false)
    }
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{signup ? 'Select Profile Photo' : 'Upload Image'}</ModalHeader>
        <ModalCloseButton onClick={() => setImageUrl(null)} />
        <ModalBody>
          {imageUrl ? (
            <Cropper
              ref={cropperRef}
              src={imageUrl}
              aspectRatio={1}
              guides={true}
              viewMode={1}
              dragMode="move"
              scalable={true}
              cropBoxMovable={true}
              cropBoxResizable={true}
              style={{ height: 450, width: 530 }}
              onInitialized={(instance) => {
                setCropper(instance)
              }}
            />
          ) : (
            <>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={getNewAvatarUrl}
                onClick={(event) => {
                  event.target.value = ''
                }}
                hidden
              />
              <Center
                flex={1}
                border={1}
                borderColor={'gray.600'}
                borderStyle={'solid'}
                rounded="md"
                cursor={'pointer'}
                onClick={() => {
                  fileInputRef?.current?.click()
                }}
              >
                <VStack>
                  <Icon boxSize={40} as={IoImageOutline}></Icon>
                  <Text fontSize="lg" fontWeight="bold">
                    Add Photos
                  </Text>
                </VStack>
              </Center>
            </>
          )}
        </ModalBody>

        {imageUrl ? (
          <ModalFooter>
            <Button colorScheme="gray" onClick={handleCrop} className="mr-3 w-24">
              Crop
            </Button>
            {!signup ? (
              <Button isLoading={isLoading} colorScheme="gray" onClick={uploadImage}>
                Upload
              </Button>
            ) : (
              <Button isLoading={isLoading} colorScheme="gray" onClick={signupImage}>
                Save
              </Button>
            )}
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ImageCropModal
