import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import ButtonRed from '../../../components/ButtonRed'
import { deactivateUser } from '../../../services/user.service'

const DeactivateAccountModal = ({ isOpen, onClose = () => {} }) => {
  const toast = useToast()
  const history = useHistory()

  const onDeactivateAccount = async () => {
    const userInfoJSON = localStorage.getItem('user')
    let userInfoObject = JSON.parse(userInfoJSON)

    try {
      await deactivateUser(userInfoObject.id, { active: false })
      localStorage.removeItem('user')
      history.push('/login')
      onClose()
      toast({
        title: `Successfully Deactivated`,
        description: `Your account has been Deactivated`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deactivate Account?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to deactivate your account? Your friends will miss you. By
          deactivating your account all your posts and content will hide with it. You can activate
          your account just simply login.
        </ModalBody>

        <ModalFooter>
          <ButtonRed
            colorScheme="red"
            text={'Deactivate'}
            className="mr-3 w-24"
            onClick={onDeactivateAccount}
          />
          <Button colorScheme="gray" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeactivateAccountModal
