import { Button as ChkButton } from '@chakra-ui/button'
import { HStack } from '@chakra-ui/layout'
import { Divider, FormControl, FormLabel, Switch, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { BsChatFill } from 'react-icons/bs'
import { IoPersonAdd, IoPersonRemove } from 'react-icons/io5'
import { useParams, useHistory } from 'react-router-dom'
import { GlobalContext } from '../..'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import Button from '../../components/Button'
import ButtonRed from '../../components/ButtonRed'
import ButtonWarning from '../../components/ButtonWarning'
import ImageUploader from '../../components/ImageUploader/ImageUploader'
import {
  createContact,
  deleteContact,
  getMyContacts,
  getUser,
  update,
  updateLoadPost,
} from '../../services/user.service'
import ConnectionsProfile from './Profile/ConnectionsProfile'
import DeactivateAccountModal from './Profile/DeactivateAccountModal'
import DeleteAccountModal from './Profile/DeleteAccountModal'
import ResetPasswordModal from './Profile/ResetPasswordModal'

const initialValues = {
  name: {
    value: '',
    validated: true,
    message: 'Not a valid name',
  },
  username: {
    value: '',
    validated: true,
    message: 'Not a valid username',
  },
  email: {
    value: '',
    validated: true,
    message: 'Not a valid email',
  },
  submitted: false,
  canSave: false,
}

const UserProfile = () => {
  const [userLogin, setUserLogin] = useState(initialValues)
  const [userContacts, setUserContacts] = useState([])
  const [, setLoginNoMatch] = useState(false)
  const [profileImage, setProfileImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { userInfo, updateUserInfo } = useContext(GlobalContext)
  const [userDetails, setUserDetails] = useState({})
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] = useState(false)
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [isMe, setIsMe] = useState(false)
  const toast = useToast()
  const history = useHistory()
  let { id } = useParams()
  const pageSize = 5

  useEffect(() => {
    if (id) {
      getUser(id).then((user) => {
        const userInfo = user.data
        if (userInfo && userInfo?.active) {
          let updatedUserInfo = {
            ...userInfo,
            name: {
              ...userInfo.name,
              value: userInfo.name,
            },
            email: {
              ...userInfo.email,
              value: userInfo.email,
            },
            username: {
              ...userInfo.username,
              value: userInfo.username,
            },
            canSave: true,
          }
          setUserLogin(updatedUserInfo)
          setProfileImage(userInfo.photo || null)
          setUserDetails(userInfo)
        } else {
          history.push('/')
        }
      })

      if (userInfo.id === id) setIsMe(true)

      onGetMyContacts().catch(console.log)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userInfo.id])

  const onGetData = async () => {
    let userInfoObject = history?.location?.state?.userObject
    if (userInfoObject) await onGetUser(userInfoObject)
    await onGetMyContacts()
  }

  const onGetUser = async (userInfoObject) => {
    try {
      if (!!!userInfoObject?.id) {
        const userInfoJSON = localStorage.getItem('user')
        userInfoObject = JSON.parse(userInfoJSON)
        setIsMe(true)
      } else {
        setIsMe(false)
      }
      let { data: userInfo } = await getUser(userInfoObject.id)
      let updatedUserInfo = {
        ...userInfo,
        name: {
          ...userInfo.name,
          value: userInfo.name,
        },
        email: {
          ...userInfo.email,
          value: userInfo.email,
        },
        username: {
          ...userInfo.username,
          value: userInfo.username,
        },
        canSave: true,
      }
      setUserLogin(updatedUserInfo)
      setProfileImage(userInfo.photo || null)
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onGetMyContacts = async () => {
    try {
      let { data: myContacts } = await getMyContacts(pageSize)
      myContacts = myContacts.map((contact) => {
        let updated = { ...contact }
        updated.id = contact?._id
        return updated
      })
      setUserContacts(myContacts || [])
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleChange = (event) => {
    let { name, value } = event.target
    let updated = { ...userLogin, submitted: false, canSave: true }
    updated[name].value = value
    updated[name].validated = true
    setUserLogin(updated)
    setLoginNoMatch(false)
    validateFields(updated)
  }

  const validateFields = (userLogin) => {
    let globalValidation = true
    let validationCopy = { ...userLogin }
    let { name } = userLogin
    if (!name.value || name.value.length < 2) {
      validationCopy.name = {
        ...name,
        validated: false,
      }
      globalValidation = false
    }

    setUserLogin({
      ...validationCopy,
      submitted: true,
      canSave: globalValidation,
    })
    return globalValidation
  }

  const handleOnSummit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    let allValidated = validateFields(userLogin)
    if (!allValidated) {
      setIsLoading(false)
      return
    }
    const userInfoJSON = localStorage.getItem('user')
    let userInfoObject = JSON.parse(userInfoJSON)
    let userPayload = {
      id: userInfoObject.id,
      name: userLogin.name.value,
      username: userInfoObject.username,
      email: userInfoObject.email,
      photo: profileImage instanceof File && profileImage,
    }
    try {
      const res = await update(userPayload)
      if (res?.data?.reason === 'INAPPROPRIATE_CONTENT') {
        toast({
          title: 'Oops! Something Went Wrong',
          description: `${res?.data?.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Updated',
          description: `${res?.data?.message}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    updateUserInfo()
    setIsLoading(false)
  }

  const onRemoveContact = async () => {
    setIsLoading(true)
    try {
      let connection = userLogin.connections.find((x) => x.userId === userInfo.id)
      await deleteContact(connection.connectionId)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onCreateContact = async () => {
    setIsLoading(true)
    try {
      await createContact(userLogin.id)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const finalProfileImage = userDetails?.photo ? userDetails?.photo : profilePicture

  const {
    name: { value: name },
    username: { value: username },
    email: { value: email },
  } = userLogin

  const isConnected = userContacts.some((x) => x?._id === userLogin.id)

  const onPostAutoLoad = async (e, loadPost) => {
    setIsLoading(true)
    try {
      updateLoadPost({
        willLoad: loadPost,
      }).then((res) => {
        if (res.status === 200) {
          onGetData()
          updateUserInfo()

          getUser(id).then((user) => {
            setUserDetails(user.data)
          })
        }
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <div className="h-full grid grid-cols-1 lg:grid-cols-2 gap-10 pb-8">
      {showResetPasswordModal && (
        <ResetPasswordModal
          userEmail={email}
          isOpen={showResetPasswordModal}
          onClose={() => setShowResetPasswordModal(false)}
        />
      )}
      {showDeactivateAccountModal && (
        <DeactivateAccountModal
          userEmail={email}
          isOpen={showDeactivateAccountModal}
          onClose={() => setShowDeactivateAccountModal(false)}
        />
      )}
      {showDeleteAccountModal && (
        <DeleteAccountModal
          userEmail={email}
          isOpen={showDeleteAccountModal}
          onClose={() => setShowDeleteAccountModal(false)}
        />
      )}
      <section className="pt-24 lg:pt-32 mx-4 lg:mx-56">
        <div className="flex justify-center">
          <ImageUploader
            containerStyle={{ alignSelf: 'center' }}
            selectedImage={finalProfileImage}
            onChange={(image) => setProfileImage(image)}
            disabled={!isMe}
          />
        </div>

        <div className="mt-6 lg:ml-8">
          <div className="flex justify-between items-center">
            <h1 className="text-gray-100 text-2xl">{userDetails?.name}</h1>
          </div>
          <div className="mt-5 space-y-2">
            <button
              onClick={() => history.push(`/marketplace`, { userDetails })}
              className="text-gray-100 text-lg lg:text-xl mt-1"
            >
              Services
            </button>
            <div>
              <button
                onClick={() =>
                  history.push(`/profile/calendar/${id}`, {
                    userDetails,
                  })
                }
                className="text-gray-100 text-lg lg:text-xl mt-1"
              >
                Calendar
              </button>
            </div>
            <div>
              <button className="text-gray-100 text-lg lg:text-xl mt-1">Featured Posts</button>
            </div>
          </div>
          {userInfo.id === id && (
            <>
              <Divider mt={8} mb={6} />
              <div className="text-center text-2xl lg:text-3xl">
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="load-alerts" mb="0">
                    Auto Load Feed
                  </FormLabel>
                  <Switch
                    isChecked={userDetails?.loadPostsAutomatically}
                    onChange={(e) => onPostAutoLoad(e, !userDetails?.loadPostsAutomatically)}
                    id="load-alerts"
                  />
                </FormControl>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="lg:pt-24 lg:pt-32 mx-4 lg:mr-56 lg:ml-0">
        <h1 className="text-gray-100 text-2xl lg:text-3xl">Profile Information</h1>
        <form action="#" className="container mt-4">
          <div>
            <label htmlFor="username" className="text-gray-100 text-lg">
              Username
            </label>
            <input
              type="text"
              name="username"
              disabled
              value={username}
              onChange={handleChange}
              className="block w-full my-2 py-2 pl-6 pr-4 cursor-not-allowed rounded-2xl focus:border-transparent border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-opacity-90 bg-gray-800 text-gray-400"
              placeholder="User Name"
              readOnly
            />
          </div>
          <div className="mt-2">
            <label htmlFor="name" className="text-gray-100 text-lg">
              Full Name
            </label>
            <input
              type="text"
              name="name"
              value={name}
              maxLength={24}
              onChange={handleChange}
              className="block w-full my-2 py-2 pl-6 pr-4 rounded-2xl focus:border-transparent border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-opacity-90 bg-gray-800 text-gray-400"
              placeholder="Full Name"
              readOnly={!isMe}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="email" className="text-gray-100 text-lg">
              Email Address
            </label>
            <input
              type="text"
              name="email"
              disabled
              value={email}
              onChange={handleChange}
              className="block w-full my-2 py-2 pl-6 pr-4 cursor-not-allowed rounded-2xl focus:border-transparent border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-opacity-90 bg-gray-800 text-gray-400"
              placeholder="Email"
              readOnly
            />
          </div>

          <div className="max-w-lg mx-auto md:w-full md:mx-0">
            <div className="flex space-x-4 h-full">
              <div className="flex flex-col w-full h-auto justify-between mt-6">
                {userInfo.id === id ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Button
                      disabled={userDetails.id !== id}
                      isLoading={isLoading}
                      onClick={handleOnSummit}
                      className={'w-full h-10'}
                      text={'Update Profile'}
                    />
                    <Button
                      onClick={() => {
                        setShowResetPasswordModal(true)
                      }}
                      className={'w-full h-10'}
                      text={'Reset Password'}
                    />
                    <ButtonWarning
                      onClick={() => {
                        setShowDeactivateAccountModal(true)
                      }}
                      className={'w-full h-10'}
                      text={'Deactivate Account'}
                    />
                    <ButtonRed
                      onClick={() => {
                        setShowDeleteAccountModal(true)
                      }}
                      className={'w-full h-10'}
                      text={'Delete Account'}
                    />
                  </div>
                ) : (
                  <HStack mb={'4'}>
                    <ChkButton
                      onClick={() => {
                        history.push('/messages', {
                          userInfo: userLogin,
                        })
                      }}
                      colorScheme="facebook"
                      leftIcon={<BsChatFill />}
                    >
                      Message
                    </ChkButton>
                    <ChkButton
                      isLoading={isLoading}
                      onClick={async () => {
                        if (isConnected) {
                          await onRemoveContact()
                        } else {
                          await onCreateContact()
                        }
                        onGetData()
                      }}
                      colorScheme="primary"
                      leftIcon={isConnected ? <IoPersonRemove /> : <IoPersonAdd />}
                    >
                      {isConnected ? 'Remove Contact' : 'Add Contact'}
                    </ChkButton>
                  </HStack>
                )}
              </div>
            </div>
          </div>
          {userInfo.id === id && (
            <div className="mt-6">
              <h1 className="text-gray-100 text-2xl lg:text-3xl">Connections</h1>
              <ul className="flex flex-wrap mt-6">
                {userContacts
                  .filter((x) => x.id !== userLogin.id)
                  .map((userProfile) => {
                    return (
                      <div key={userProfile.id}>
                        <ConnectionsProfile
                          currentUserId={userLogin.id}
                          userProfile={{
                            ...userProfile,
                            connected: true,
                          }}
                          onSelect={(userProfile) => {
                            localStorage.removeItem('thread')
                            history.push(`/profile/${userProfile.id}`)
                          }}
                        />
                      </div>
                    )
                  })}
                {userContacts && userContacts.length > 5 && (
                  <button
                    onClick={() => history.push('allContacts')}
                    className="text-gray-300 text-base"
                  >
                    See all...
                  </button>
                )}
                {(!userContacts || userContacts.length < 1) && (
                  <h1 className="p-4 text-white">{`No contacts yet`}</h1>
                )}
              </ul>
            </div>
          )}
        </form>
      </section>
    </div>
  )
}

export default UserProfile
