import React, { useContext, useEffect, useState, useRef } from 'react'
import {
  Box,
  Flex,
  Spacer,
  Tooltip,
  useDisclosure,
  useToast,
  useMediaQuery,
  Heading,
} from '@chakra-ui/react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { GlobalContext } from '../../..'
import './style.css'
import EventModal from './EventModal'
import { getUserEvents } from '../../../services/event.service'
import { generateCallLink } from '../../../utils/calendar-utils'
import EventDetailsModal from './EventDetailsModal'

const Calendar = ({ history }) => {
  const { userInfo: loggedInUser } = useContext(GlobalContext)
  const calendarUser = history?.location?.state?.userDetails || loggedInUser
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure()
  const [selectedEventId, setSelectedEventId] = useState('')
  const [selectedDate, setSelectedDate] = useState()
  const [parsedEvents, setParsedEvents] = useState([])
  const [eventLink, setEventLink] = useState('')
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo
    const eventTitle = event.title
    const eventTime = event.allDay
      ? 'All day'
      : `${event.start.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        })} - ${
          event.end
            ? event.end.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
              })
            : ''
        }`
    const tooltip = eventTitle + ' / ' + eventTime
    return (
      <Tooltip label={tooltip} closeDelay={2000}>
        {event.title.length < 12 ? event.title : `${event.title.substring(0, 13)}...`}
      </Tooltip>
    )
  }

  const handleDateSelect = (selectInfo) => {
    setSelectedDate({ ...selectInfo })
  }

  const fetchEvents = async () => {
    try {
      const events = await getUserEvents(calendarUser.id)
      const parsed = events.data?.map((event) => {
        const { _id, title, startDateTimeUTC, endDateTimeUTC, isAllDay } = event
        return {
          id: _id,
          title,
          start: new Date(startDateTimeUTC),
          end: new Date(endDateTimeUTC),
          allDay: isAllDay,
        }
      })
      setParsedEvents(parsed)
    } catch (error) {
      toast({
        title: `${error}`,
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (selectedDate) {
      setEventLink(generateCallLink())
      onOpen()
    }
    // eslint-disable-next-line
  }, [selectedDate])

  useEffect(() => {
    if (!isOpen) setSelectedDate()
  }, [isOpen])

  useEffect(() => {
    fetchEvents()
    // eslint-disable-next-line
  }, [loggedInUser])

  return (
    <>
      <Flex alignItems="center" justifyContent="center">
        <Box width="100%" height="100%" p={isMobile ? '10px' : '100px'} pt="100px">
          <Flex mb="20px" alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
            <Heading
              color="gray.300"
              fontSize={isMobile ? '2xl' : '3xl'}
              textAlign={isMobile ? 'center' : 'left'}
              mb={isMobile ? '10px' : '0'}
            >
              {`${calendarUser.name}'s Calendar`}
            </Heading>
            <Spacer />
          </Flex>
          {loggedInUser && (
            <CalendarWrapper
              renderEventContent={renderEventContent}
              handleDateSelect={handleDateSelect}
              parsedEvents={parsedEvents}
              setSelectedEventId={setSelectedEventId}
              onOpenDetails={onOpenDetails}
              isMobile={isMobile}
            />
          )}
        </Box>
      </Flex>
      <EventModal
        isOpen={isOpen}
        link={eventLink}
        onClose={onClose}
        selectedDate={selectedDate}
        fetchEvents={fetchEvents}
      />
      <EventDetailsModal
        isOpen={isOpenDetails}
        eventId={selectedEventId}
        onClose={onCloseDetails}
      />
    </>
  )
}

export default Calendar

const CalendarWrapper = ({
  renderEventContent,
  handleDateSelect,
  parsedEvents,
  setSelectedEventId,
  onOpenDetails,
  isMobile,
}) => {
  const calendarRef = useRef(null)

  useEffect(() => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi()
      const newView = isMobile ? 'listWeek' : 'dayGridMonth'
      calendarApi.changeView(newView)
    }
  }, [isMobile])

  const headerToolbar = {
    left: 'prev,next today',
    center: 'title',
    right: isMobile ? '' : 'dayGridMonth,timeGridWeek,timeGridDay',
  }

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
      headerToolbar={headerToolbar}
      initialView={isMobile ? 'listWeek' : 'dayGridMonth'}
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      eventContent={renderEventContent}
      select={handleDateSelect}
      events={parsedEvents}
      eventBorderColor="#2c3e50"
      eventClick={(eventData) => {
        setSelectedEventId(eventData?.event?.id)
        onOpenDetails()
      }}
    />
  )
}
