import React from 'react'
import RePost from '../../Feed/RePost'

const PostLinkPreview = ({ page, link }) => {
  return (
    link?.id &&
    page === 'post' && (
      <div>
        <RePost repostId={link.id} />
      </div>
    )
  )
}

export default PostLinkPreview
