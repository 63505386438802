import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Flex, HStack, Link, Stack, VStack, useToast } from '@chakra-ui/react'
import { CartOrderSummary } from './CartOrderSummary'
import { useHistory } from 'react-router-dom'

const CheckoutForm = ({ total }) => {
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()
  const [isProcessing, setIsProcessing] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }
    setIsProcessing(true)

    try {
      await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/checkout`,
        },
      })
    } catch (error) {
      toast({
        duration: 8000,
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        title: 'Oops! Something Went Wrong',
        description: error.message,
      })
    }
    setIsProcessing(false)
  }

  const paymentElementOptions = {
    layout: 'tabs',
  }

  return (
    <>
      {stripe && elements && (
        <form onSubmit={handleSubmit}>
          <Stack
            display="flex"
            alignItems="center"
            justifyContent="center"
            direction={['column', 'column', 'row', 'row']}
            spacing="24px"
          >
            <VStack gap="30px" align="strech" w="60%">
              <PaymentElement options={paymentElementOptions} />
              <button
                className={'font-bold p-2 bg-purple-600 text-white rounded-md cursor-pointer'}
                disabled={isProcessing}
                type="submit"
              >
                {isProcessing ? 'Processing...' : 'Pay now'}
              </button>
            </VStack>
            <Flex direction="column" align="center">
              <CartOrderSummary total={total} />
              <HStack mt="6" fontWeight="semibold">
                <p>or</p>
                <Link onClick={() => history.push('/marketplace')} color="purple.400">
                  Continue shopping
                </Link>
              </HStack>
            </Flex>
          </Stack>
        </form>
      )}
    </>
  )
}

export default CheckoutForm
