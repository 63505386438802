/* eslint-disable no-lone-blocks */

import React, { Component } from 'react'
import Select, { components } from 'react-select'
const MultiValueLabel = (props) => {

  return (
    <div content={'Customise your multi-value label component!'}>
      <components.MultiValueLabel {...props} />
    </div>
  )
}

const groupStyles = {
  fontSize: '20px',
  fontWeight: '600',
  padding: '5px 0px',
  display: 'flex',
}

const GroupHeading = (props) => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
)

const DropdownIndicator = (props) => {
  return (null)
}

const customStyle = {
  fontSize: '1rem',
  fontWeight: 400,
  color: 'white',
}

class CustomSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: this.props.options,
      selectedOptions: null,
      addedOptions: [],
      removedOptions: [],
    }
  }

  componentDidMount() {}

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.options || nextProps.selectedOptions) {
      return {
        options: [...nextProps.options],
        selectedOptions: nextProps.selectedOptions,
      }
    } else {
      return null
    }
  }
  async onChange(selectedOptions, actionType) {
    if (this.props.isMulti === true) {
      switch (actionType.action) {
        case 'select-option':
          {
            var currentAddedOption = []
            currentAddedOption.push(actionType.option.id)
            this.setState({
              addedOptions: currentAddedOption,
              removedOptions: [],
            })
          }
          break
        case 'remove-value':
          {
            var currentRemovedOption = []
            currentRemovedOption.push(actionType.removedValue.id)
            this.setState({
              removedOptions: currentRemovedOption,
              addedOptions: [],
            })
          }
          break
        default:
          break
      }
    }
    this.setState({ selectedOptions })
    this.props.onChange(selectedOptions, currentAddedOption, currentRemovedOption)
  }
  setValue() {
    console.log('Select Down')
  }
  render() {
    return (
      <>
        <Select
          closeMenuOnSelect={this.props.closeMenuOnSelect}
          maxMenuHeight={150}
          components={{ MultiValueLabel, GroupHeading, DropdownIndicator }}
          styles={{
            container: (base) => ({
              ...base,
            }),
            menu: (base) => ({
              ...base,
              background: this.props.optionBgColor,
            }),
            groupHeading: (base) => ({
              ...base,
              flex: '1 1',
              margin: 0,
            }),
            singleValue: (provided, state) => {
              return { ...provided, ...customStyle }
            },
            multiValue: (provided, state) => {
              return {
                ...provided,
                ...customStyle,
                background: this.props.optionBgColor,
                color: 'white',
                borderRadius: 8,
                padding: '2px 4px',
              }
            },
            multiValueLabel: (provided, state) => {
              return {
                ...provided,
                color: 'white',
              }
            },
            input: (provided, state) => {
              return { ...provided, ...customStyle }
            },
            placeholder: (provided, state) => {
              return { ...provided, ...customStyle }
            },
            option: (provided, state) => {
              return {
                ...provided,
                ...customStyle,
                background: state.isFocused ? this.props.brandColor : this.props.optionBgColor,
                cursor: 'pointer',
              }
            },
            indicatorSeparator: (provided, state) => {
              return { ...provided, display: 'none' }
            },
            control: (provided, state) => {
              return {
                ...provided,
                backgroundColor: 'transparent',
                border: 0,
                borderRadius: 0,
              }
            },
          }}
          onChange={this.onChange.bind(this)}
          onFocus={this.props.onFocus}
          isMulti={this.props.isMulti}
          placeholder={this.props.placeholder}
          options={this.state.options}
          value={this.state.selectedOptions}
          isClearable={false}
        />
      </>
    )
  }
}

export { CustomSelect }
