import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  ListItem,
  Progress,
  Text,
  useToast,
  UnorderedList,
  Link,
  Spinner,
  Center,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import React, { useContext, useMemo, useState } from 'react'

import Button from '../../components/Button'
import useCheckLogIn from '../../hooks/useCheckLogIn'

import { GlobalContext } from '../../index'
import { tokenResetPassword } from '../../services/user.service'

const MEDIUM_REGEX =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})./

const STRONG_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{12,}$/

const ResetPasswordForm = ({ id, token }) => {
  useCheckLogIn()
  const [tokenExpired, setTokenExpired] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState()

  const { updateUserInfo } = useContext(GlobalContext)

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()

  const toast = useToast()
  const history = useHistory()

  const [newPassword, confirmPassword] = watch(['newPassword', 'confirmPassword'])

  const onSubmit = async (data) => {
    try {
      const response = await tokenResetPassword({
        id,
        token,
        password: data.newPassword,
      })
      if (response.data) {
        setIsRedirecting(true)
        toast({
          duration: 5000,
          status: 'success',
          position: 'top-right',
          variant: 'left-accent',
          title: 'Your Password Has Been Updated Successfully',
        })
        localStorage.setItem('user', JSON.stringify(response.data))
        updateUserInfo()
        setTimeout(() => history.push('/'), 5000)
      }
    } catch (error) {
      reset()
      if (error?.response.data.statusCode === 400) {
        setTokenExpired(true)
      }
    }
  }

  const passwordStrength = useMemo(() => {
    if (!newPassword) return null
    if (STRONG_REGEX.test(newPassword)) {
      return (
        <>
          <Progress mt={2} value={100} size="sm" colorScheme="green" />
          <Text color="green.500">Strong</Text>
        </>
      )
    }
    if (MEDIUM_REGEX.test(newPassword)) {
      return (
        <>
          <Progress mt={2} value={50} size="sm" />
          <Text color="blue.500">Medium</Text>
        </>
      )
    }
    return (
      <>
        <Progress mt={2} value={25} size="sm" colorScheme="red" />
        <Text color="red.500">Weak</Text>
      </>
    )
  }, [newPassword])

  const passwordsMatch = newPassword === confirmPassword

    const handleChange = () => {
      reset({ errors: false })
    }

  return (
    <>
      {!isRedirecting ? (
        <div className="mt-4">
          {!tokenExpired ? (
            <div>
              <form onSubmit={handleSubmit(onSubmit)} action="#" autoComplete="off">
                <FormControl isInvalid={errors.newPassword}>
                  <div className="flex flex-col mb-4">
                    <div className="flex relative">
                      <InputGroup>
                        <input
                          name="newPassword"
                          placeholder="New Password"
                          type={showNewPassword ? 'text' : 'password'}
                          onChange={handleChange}
                          className=" rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          {...register('newPassword', {
                            minLength: {
                              value: 8,
                              message: 'Password must be at least 8 characters long',
                            },
                            maxLength: {
                              value: 20,
                              message: 'Password must not exceed 20 characters in length.',
                            },
                            pattern: {
                              value: /^(?=.*[a-z])(?=.*[A-Z0-9]).*$/,
                              message:
                                'Password must contain at least one lowercase letter and one uppercase letter or one number',
                            },
                            required: 'Please enter a new password.',
                          })}
                        />
                      </InputGroup>
                      <span
                        style={{ right: 13, top: '36%' }}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="inline-flex items-center bg-white text-gray-500 cursor-pointer absolute"
                      >
                        <i className={`far ${!showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                      </span>
                    </div>
                    {passwordStrength}
                    <FormErrorMessage>
                      {errors.newPassword && errors.newPassword.message}
                    </FormErrorMessage>
                  </div>
                </FormControl>
                <FormControl isInvalid={errors.confirmPassword}>
                  <div className="flex flex-col">
                    <div className="flex relative">
                      <InputGroup>
                        <input
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={handleChange}
                          type={showConfirmPassword ? 'text' : 'password'}
                          className=" rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 pl-4 pr-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          {...register('confirmPassword', {
                            validate: (value) => value === newPassword || 'Passwords do not match',
                            required: 'Please confirm your password.',
                          })}
                        />
                      </InputGroup>
                      <span
                        style={{ right: 13, top: '36%' }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className="inline-flex items-center bg-white text-gray-500 cursor-pointer absolute"
                      >
                        <i className={`far ${!showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                      </span>
                    </div>
                    <FormErrorMessage>
                      {errors.newPassword && errors.newPassword.message}
                    </FormErrorMessage>
                  </div>
                </FormControl>
                <div className="my-4">
                  <UnorderedList color="blackAlpha.600">
                    <ListItem>Minimum 8 characters </ListItem>
                    <ListItem>At least one lowercase </ListItem>
                    <ListItem>At least one uppercase or one number </ListItem>
                  </UnorderedList>
                </div>
                <div className={'flex w-full'}>
                  <Button
                    text={'Reset'}
                    type={'submit'}
                    isLoading={isSubmitting}
                    disabled={!passwordsMatch}
                    className={passwordsMatch ? 'cursor-pointer' : 'cursor-not-allowed'}
                  />
                </div>
              </form>
              <Text mt={4} fontSize={12} color="blackAlpha.600">
                © Co-Nectar, All rights reserved.
              </Text>
            </div>
          ) : (
            <div>
              <Text mt={4} fontSize={16} color="blackAlpha.600">
                Link has expired or is invalid, please{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link onClick={() => history.push('/forgot-password')}>
                  <span className={'text-purple-600'}>Request a New Link</span>
                </Link>
                .
              </Text>
            </div>
          )}
        </div>
      ) : (
        <>
          <Center my={8}>
            <Spinner
              emptyColor="gray.200"
              color="purple.600"
              thickness="4px"
              speed="0.95s"
              size="xl"
            />
          </Center>
          <Center my={2}>
            <Text fontSize={16} color="blackAlpha.600">
              Taking you to the login screen.
            </Text>
          </Center>
        </>
      )}
    </>
  )
}

export default ResetPasswordForm
