import React from 'react'
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useClipboard,
} from '@chakra-ui/react'
import { BsEnvelope, BsWhatsapp, BsTelegram, BsTwitter } from 'react-icons/bs'

const ShareModal = ({ onClose, postLink }) => {
  const { hasCopied, onCopy } = useClipboard(postLink)
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={['center']}>Share Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex items-center py-6">
            <div
              className="
                duration-350 flex
                flex-1
                items-center
                justify-center
                transition
                ease-in-out
            "
            >
              <IconButton
                rounded={'full'}
                variant={'ghost'}
                aria-label={''}
                size={'lg'}
                title={'Envelope'}
                color={'primary.300'}
                icon={<BsEnvelope size={42} />}
                onClick={() => {
                  window.location =
                    'mailto:?subject=Check out this post&body=' + postLink + '%0D%0A%0D%0A'
                }}
              />
            </div>
            <div
              className="
                duration-350 flex
                flex-1
                items-center
                justify-center
                transition
                ease-in-out
            "
            >
              <IconButton
                rounded={'full'}
                variant={'ghost'}
                aria-label={''}
                size={'lg'}
                title={'Whatsapp'}
                color={'primary.300'}
                icon={<BsWhatsapp size={42} />}
                onClick={() => {
                  window.open(
                    'https://api.whatsapp.com/send/?text=Check out this post ' + postLink,
                    '_blank'
                  )
                }}
              />
            </div>
            <div
              className="
                duration-350 flex
                flex-1
                items-center
                justify-center
                transition
                ease-in-out
            "
            >
              <IconButton
                rounded={'full'}
                variant={'ghost'}
                aria-label={''}
                size={'lg'}
                title={'Telegram'}
                color={'primary.300'}
                icon={<BsTelegram size={42} />}
                onClick={() => {
                  window.open('https://telegram.me/share/url?url=' + postLink, '_blank')
                }}
              />
            </div>
            <div className="duration-350 flex flex-1 items-center justify-center transition ease-in-out">
              <IconButton
                rounded={'full'}
                variant={'ghost'}
                aria-label={''}
                size={'lg'}
                title={'Twitter'}
                color={'primary.300'}
                icon={<BsTwitter size={42} />}
                onClick={() => {
                  window.open(
                    'https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20&url=' +
                      postLink,
                    '_blank'
                  )
                }}
              />
            </div>
          </div>
          <div className="flex items-center py-6">
            <div
              className="
                duration-350 flex
                flex-1
                items-center
                justify-center
                transition
                ease-in-out
              "
            >
              <input
                class="w-64 bg-gray-800 p-4 text-sm font-bold read-only:bg-gray-100 border-2"
                color={'primary.500'}
                value={postLink}
                disabled
              />
            </div>
            <div className="duration-350 transitionease-in-out flex flex-1 items-center justify-center">
              <button
                className="text-md block font-semibold cursor-pointer px-2 py-2 text-blue-400 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-gray-100 active:bg-gray-900 active:text-gray-900"
                onClick={() => {
                  onCopy()
                }}
              >
                {hasCopied ? 'Copied!' : 'Copy'}
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ShareModal
