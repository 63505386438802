import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React from 'react'

const RenderImageModal = ({ onClose, photo }) => {
  return (
    <Modal
      isOpen={true}
      variant="wide"
      className="flex justify-center"
      onClose={onClose}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent className="h-5/6" maxW="80%">
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4} className="flex justify-center h-5/6">
          <img src={photo} alt="" />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}

export default RenderImageModal
