import React from 'react'
import profilePicture from '../../../assets/svgs/avtar-rect.svg'

const SimpleConnectionRow = ({ userProfile, onSelect = () => {} }) => {
  const { photo, name, username } = userProfile

  const onHandleRedirect = () => {
    onSelect(userProfile)
  }

  return (
    <li className="list-none cursor-pointer" onClick={onHandleRedirect}>
      <article className="hover:bg-gray-800 transition duration-350 ease-in-out">
        <div className="flex flex-1 flex-shrink-0 p-4">
          <div className="flex-shrink-0 flex-1 group block">
            <div className="flex items-center flex-1 mb-4">
              <div>
                <img
                  className="inline-block h-12 w-12 rounded-full"
                  src={photo || profilePicture}
                  alt=""
                />
              </div>
              <div className="ml-3">
                <p className="text-base leading-6 font-medium text-white">{name}</p>
                <span className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
                  {` @${username}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-gray-800" />
      </article>
    </li>
  )
}

export default SimpleConnectionRow
