import { Flex, Box, Tooltip } from '@chakra-ui/react'
import React, { useState, useCallback } from 'react'
import { formatDistanceToNowStrict, format } from 'date-fns'
import { useHistory } from 'react-router'
import { createComment } from '../../services/comments.service'
import { Replies } from './Replies'

const Comment = ({ comment, handleGetComment }) => {
  const history = useHistory()
  const { text, userId, createdAt, user, postId } = comment
  const { photo, name, username, id } = user

  const [newReply, setNewReply] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const relativeTime = formatDistanceToNowStrict(new Date(createdAt), { addSuffix: false })
  const readableTime = format(new Date(createdAt), 'dd MMM yyyy hh:mm aaaa')

  const handleNonLoggedInUser = () => {
    history.push('/login')
  }

  const handleOpenNewReplyComment = () => {
    setIsOpen(!isOpen)
  }

  const replyOnChange = (e) => {
    setNewReply(e.target.value)
  }

  const addNewReplyToComment = useCallback(
    async (postId, parentId, text) => {
      if (postId && text) {
        await createComment({
          postId: postId,
          text: text,
          parentId: parentId,
        })
        setNewReply('')
        setIsOpen(false)
        handleGetComment(postId, id)
      }
    },
    [handleGetComment, id]
  )

  const handleUserOnSelect = () => {
    history.push('/profile', {
      userObject: { id: userId },
    })
  }

  return (
    <div>
      <Flex alignItems={'stretch'} justifyContent={'center'}>
        <Box maxW={'3xl'} w={'full'}>
          <div className="flex-1 pr-2 pt-1 m-1 ml-2 ">
            <div className="flex">
              <div className="ml-2" onClick={handleUserOnSelect}>
                <img className="inline-block h-10 w-10 rounded-full" src={photo} alt="" />
              </div>
              <div onClick={handleUserOnSelect} className="flex ml-3">
                <p className="text-base leading-6 font-medium text-white cursor-pointer">{name}</p>
                <span
                  className="text-sm ml-2 leading-5 font-sm text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150"
                  title={readableTime}
                >
                  {` @${username} . ${relativeTime}`} .
                </span>
              </div>
            </div>
            <div className="ml-14 pl-2 pt-1 -mt-3.5 mb-4">
              <p className=" text-white ">{text}</p>
            </div>
            <div className="oolo">
              <div
                className="pl-16 flex-1 flex
              items-center
              text-white text-xs
              transition
              duration-350
              ease-in-out
              hover:text-blue-400 cursor-pointer
              pb-4
              pt-4
              "
                onClick={id ? handleOpenNewReplyComment : handleNonLoggedInUser}
              >
                <Tooltip label="Reply post...">
                  <svg viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 mr-2">
                    <g>
                      <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path>
                    </g>
                  </svg>
                </Tooltip>
                <p>Reply</p>
              </div>
              {isOpen && (
                <>
                  <textarea
                    style={{ marginTop: '1rem' }}
                    className="pl-2 bg-transparent text-gray-400 font-medium text-base w-full focuc-visible:border-opacity-0
                    border-solid border-2 rounded
                    "
                    placeholder="Write a reply..."
                    value={newReply}
                    onChange={(e) => replyOnChange(e)}
                  />
                  <div className="flex mt-3 pb-2">
                    <div className="flex flex-1 justify-end mr-2">
                      <button
                        className={
                          'flex items-center w-42 z-10 py-2 px-3 bg-purple-600 text-white rounded-md'
                        }
                        onClick={() => {
                          addNewReplyToComment(postId, comment.id, newReply)
                        }}
                      >
                        Add Reply
                      </button>
                    </div>
                  </div>
                </>
              )}
              <Replies repliesData={comment.replies} handleGetComment={handleGetComment} />
            </div>
          </div>
        </Box>
      </Flex>
    </div>
  )
}

export default Comment
