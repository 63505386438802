import React, { useContext, useEffect } from 'react'
import useState from 'react-usestateref'
import './App.css'
import TopNavBar from './modules/Feed/TopNavBar'
import Dashboard from './modules/Feed/Dashboard'
import { Route, Switch } from 'react-router-dom'
import UserProfile from './modules/User/UserProfile'
import { PrivateRoute } from './components/PrivateRoute'
import SearchPage from './modules/Feed/SearchPage'
import Messages from './modules/Message/Messages'
import { GlobalContext } from '.'
import PersonasHome from './modules/Marketplace/PersonasHome'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { HStack, VStack } from '@chakra-ui/layout'
import { Text, Button, useToast } from '@chakra-ui/react'
import { Avatar } from '@chakra-ui/avatar'
import { IoIosCall } from 'react-icons/io'
import CallWindow from './modules/Message/CallWindow'
import { rejectCall } from './services/conversations.service'
import PostPage from './modules/Feed/PostPage'
import Checkout from './modules/Marketplace/Checkout/Checkout'
import NotFound404 from './components/NotFound404'
import jwt_decode from 'jwt-decode'
import { useHistory } from 'react-router'
import AllConnections from './modules/User/AllConnections'
import CreateService from './modules/Marketplace/CreateService'
import Calendar from './modules/User/Calendar/Calendar'
import CallLobby from './modules/Message/CallLobby'
import NewsLetter from './modules/User/NewsLetter'

function App() {
  const {
    socket,
    setNewMessageCount,
    setNewNotificationCount,
    setNewNotification,
    audios: { callTone, messageTone },
  } = useContext(GlobalContext)

  const [, , messageToneRef] = useState(messageTone)
  const [, setCall_tone, callToneRef] = useState(callTone)
  const [showCallModal, setShowCallModal] = useState(false)
  const [showCallWindow, setShowCallWindow] = useState(false)

  const toast = useToast()
  const history = useHistory()

  const [callObject, setCallObject, callObjectRef] = useState({
    userProfile: {},
    callerSignal: {},
    callMessage: {},
    isVideoCall: true,
  })

  useEffect(() => {
    let userInfoFromStorage = localStorage.getItem('user')
    let userInfo = JSON.parse(userInfoFromStorage)

    if (socket && userInfo?.accessToken) {
      if (socket.disconnected) socket.connect()

      socket.emit('join', {
        userAgent: navigator.userAgent,
        accessToken: userInfo.accessToken,
      })

      socket.on('call', (data) => {
        if (callToneRef?.current) {
          callToneRef.current.muted = false
          callToneRef.current.loop = true
          const playPromise = callToneRef?.current.play()
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                console.log('Audio played auto')
              })
              .catch((_) => {
                console.error('Playback prevented')
              })
          }
        }
        setCallObject({
          ...callObject,
          userProfile: data?.from ? { ...data.from, _id: data.from.id } : {},
          callerSignal: data?.signal || null,
          callMessage: data?.message || {},
          isVideoCall: data?.isVideoCall,
        })
      })

      socket.on('endCall', () => {
        callToneRef?.current.pause()
        setShowCallModal(false)
      })

      socket.on('callAccepted', () => {
        callToneRef?.current.pause()
        setShowCallModal(false)
      })

      socket.on('rejectedCall', () => {
        callToneRef?.current.pause()
        setShowCallModal(false)
      })

      socket.on('message', (data) => handleOnMessage(data))
    }

    return () => {
      socket?.off('endCall')
      socket?.off('call')
      socket?.off('callAccepted')
      socket?.off('rejectedCall')
      socket?.off('message')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (callObject?.userProfile?._id) setShowCallModal(true)
  }, [callObject])

  useEffect(() => {
    if (callTone) setCall_tone(callTone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callTone])

  const handleOnMessage = (_) => {
    if (messageToneRef.current) {
      messageToneRef.current.muted = false
      const playPromise = messageToneRef?.current.play()
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            console.log('Audio played auto')
          })
          .catch((_) => {
            console.error('Playback prevented')
          })
      }
    }
    setNewMessageCount((newMessage) => {
      return newMessage + 1
    })
  }

  const { userProfile } = callObject

  function isAuthenticated() {
    try {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        const { exp } = jwt_decode(user.accessToken)
        return !(Date.now() >= exp * 1000)
      }
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname

    if (!isAuthenticated()) {
      if (currentPath === '/join') {
        return () => {}
      } else {
        localStorage.removeItem('user')
        history.push('/login')
      }
    }
    return () => {}
  })

  useEffect(() => {
    if (!isAuthenticated()) {
      socket.disconnect()
    }
    socket.on('postInteraction', (data) => {
      setNewNotification(data)
      setNewNotificationCount((newNotification) => newNotification + 1)
    })
  }, [socket, history, toast, setNewNotificationCount, setNewNotification])

  // Function to remove relevant items from localStorage before the page refreshes
  const clearLocalStorageItemsOnRefresh = () => {
    localStorage.removeItem('posts_size')
    localStorage.removeItem('current_post')
  }

  // Add an event listener to the 'beforeunload' event on component mount
  useEffect(() => {
    window.addEventListener('beforeunload', clearLocalStorageItemsOnRefresh)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorageItemsOnRefresh)
    }
  }, []) // Empty dependency array to ensure the event listener is added only once on mount

  return (
    <>
      <TopNavBar />
      {showCallModal && (
        <Modal isCentered onClose={() => {}} isOpen={true} size={'lg'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Incoming Call</ModalHeader>
            <ModalBody>
              <HStack>
                <HStack>
                  <Avatar
                    size="lg"
                    mr={2}
                    name={userProfile?.name || ''}
                    src={userProfile?.photo || ''}
                  />
                  <VStack alignItems={'flex-start'} spacing={0}>
                    <Text fontSize="lg" fontWeight={'semibold'}>
                      {userProfile?.name || 'User'}
                    </Text>
                    <Text fontSize="sm" m={0}>
                      {userProfile?.username ? `@${userProfile.username}` : '@username'}
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <Button
                variant={'ghost'}
                mr={3}
                onClick={() => {
                  if (callTone) {
                    callTone.pause()
                  }
                  setShowCallModal(false)
                  let reqObject = {
                    message: {
                      text: '',
                      receiverId: callObjectRef?.current?.userProfile._id,
                      type: 2,
                      messageId: callObjectRef?.current?.callMessage.id,
                    },
                  }
                  rejectCall(reqObject).then()
                }}
              >
                Decline
              </Button>
              <Button
                onClick={() => {
                  if (callTone) {
                    callTone.pause()
                  }
                  setShowCallModal(false)
                  setShowCallWindow(true)
                }}
                bg="primary.600"
                leftIcon={<IoIosCall size={24} />}
              >
                Accept
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {showCallWindow && (
        <CallWindow
          onEnd={() => {
            setShowCallWindow(false)
          }}
          userProfile={callObjectRef?.current?.userProfile}
          callerSignal={callObjectRef?.current?.callerSignal}
          message={callObjectRef?.current?.callMessage}
          isVideoCall={callObjectRef?.current?.isVideoCall}
          currentThreadId={callObjectRef?.current?.callMessage?.threadId}
        />
      )}
      <Switch>
        <PrivateRoute
          exact
          path="/search"
          component={SearchPage}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/profile/:id"
          component={UserProfile}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/allContacts"
          component={AllConnections}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/marketplace"
          component={PersonasHome}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/messages"
          component={Messages}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/checkout"
          component={Checkout}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/marketplace/createService"
          component={CreateService}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/marketplace/update/:service_id"
          component={CreateService}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/marketplace/purchase/:id"
          component={CreateService}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/profile/calendar/:id"
          component={Calendar}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <PrivateRoute
          exact
          path="/meet/:id"
          component={CallLobby}
          loginPath="/login"
          permissionKey={{}}
          bypassAuthorization={true}
        />
        <Route path="/join" component={NewsLetter} />
        <Route path="/:userid/post/:id" component={PostPage} />
        <Route path="/" component={Dashboard} />
        <Route path="*" component={NotFound404} />
      </Switch>
    </>
  )
}

export default App
