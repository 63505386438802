import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useOutsideClick,
  useToast,
} from '@chakra-ui/react'
import {
  createMessage,
  createMessageWithFiles,
  deleteMessage,
  deleteThread,
  getMessages,
  getMyMessageThreads,
  messageForward,
  multipleMessageDelete,
  readMessages,
  updateDeleteDate,
} from '../../services/conversations.service'
import CallWindow from './CallWindow'
import { GlobalContext } from '../../'
import styled from 'styled-components'
import MessageItem from './MessageItem'
import useState from 'react-usestateref'
import Loader from './components/Loader'
import 'react-calendar/dist/Calendar.css'
import { useWindowSize } from 'usehooks-ts'
import MessageThreads from './MessageThreads'
import { useLocation } from 'react-router-dom'
import phone from '../../assets/pngs/phone.png'
import { TiDeleteOutline } from 'react-icons/ti'
import { GiHamburgerMenu } from 'react-icons/gi'
import { MdDeleteOutline } from 'react-icons/md'
import { BiSelectMultiple } from 'react-icons/bi'
import ConnectionRow from '../Feed/ConnectionRow'
import { uuidv4 } from '../../services/util.service'
import { useHandleMessageSearch } from './utils/Utils'
import scrollIntoView from 'scroll-into-view-if-needed'
import { ImFilesEmpty, ImForward } from 'react-icons/im'
import { IoIosCloseCircle, IoMdMic } from 'react-icons/io'
import AudioRecorder from '../../components/AudioRecorder'
import React, { useContext, useEffect, useRef } from 'react'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import { IoAttachOutline, IoSendSharp } from 'react-icons/io5'
import EmojiPickerPopover from './components/EmojiPickerPopover'
import MessageForwardModal from './components/MessageForwardModal'
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import { createContact, getMyContacts, getUser } from '../../services/user.service'

const initCurrentContact = { _id: null, photo: null, name: null }

const SCROLL_TARGET = 'chat-windows-scroll-target'

const Messages = ({ history }) => {
  const { setSearchText } = useHandleMessageSearch()

  const { state: locationState } = useLocation()

  const [userContacts, setUserContacts] = useState([])
  const [currentThread, setCurrentThread] = useState({})
  const [messageThreads, setMessageThreads] = useState([])
  const [currentDeleteDate, setCurrentDeleteDate] = useState(null)
  const [filteredUserContacts, setFilteredUserContacts] = useState([])
  const [showNewMessage, setShowNewMessage, showNewMessageRef] = useState(false)
  const [currentContact, setCurrentContact, currentContactRef] = useState(initCurrentContact)

  const {
    userInfo,
    socket,
    audios: {
      messageTone,
      messageSendTone,
      outgoingCallTone,
      stopRecordingTone,
      startRecordingTone,
    },
    setNewMessageCount,
    forwardMessage,
    setForwardMessage,
    postReceiverId,
    setPostReceiverId,
  } = useContext(GlobalContext)

  const [userMessages, setUserMessages] = useState([])
  const [typedMessage, setTypedMessage] = useState('')
  const [showCallWindow, setShowCallWindow] = useState(false)
  const [showSearchPopOver, setShowSearchPopOver] = useState(false)
  const [, setMessage_Tone, messageToneRef] = useState(messageTone)
  const [, setMessageSendTone, messageSendToneRef] = useState(messageSendTone)
  const [, setOutgoingCallTone, outgoingCallToneRef] = useState(outgoingCallTone)
  const [, setStopRecording_Tone, stopRecordingToneRef] = useState(stopRecordingTone)
  const [, setStartRecording_Tone, startRecordingToneRef] = useState(startRecordingTone)

  const newMessageAreaRef = useRef(null)
  const initialSearchFocusRef = useRef()

  const [, setSearchUserTex] = useState('')
  const [recordStarted, setRecordStarted] = useState(false)

  const audioBlob = useRef(null)
  const fileInputRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [lastMessagePrompt, setLastMessagePrompt] = useState(false)

  const toast = useToast()

  const [attachments, setAttachments] = useState([])
  const [isMessageSend, setIsMessageSend] = useState(false)
  const [multipleSelect, setMultipleSelect] = useState(false)
  const [lastVideoCallId, setLastVideoCallId] = useState(null)
  const [isMessageForward, setIsMessageForward] = useState(false)
  const [showForwardModal, setShowForwardModal] = useState(false)
  const [multipleMessageSelect, setMultipleMessageSelect] = useState([])
  const [showPostForwardModal, setShowPostForwardModal] = useState(false)

  const inputFocusRef = useRef(null)

  const [threadsSorted, setThreadsSorted] = useState([])

  const scrollRef = useRef(null)

  const [showThread, setShowThread] = useState(true)
  const [isVideoCall, setIsVideoCall] = useState(true)
  const [scrollMessageId, setScrollMessageId] = useState(null)

  const { width } = useWindowSize()

  const handleShowThread = () => {
    setShowThread(true)
  }

  const handleCloseThread = () => {
    setShowThread(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (locationState) {
      const receiverId = locationState?.userInfo?.id
      const showModal = locationState?.showModal

      if (receiverId && threadsSorted.length && userInfo?.id) {
        const selectedThread = threadsSorted.filter(
          (t) => t.receiverId === receiverId || t.senderId === receiverId
        )

        if (selectedThread.length) {
          const messageThread = selectedThread[0]

          let contact

          if (userInfo.id === messageThread?.receiver?._id) {
            contact = messageThread?.sender
          } else {
            contact = messageThread?.receiver
          }

          setCurrentThread(messageThread)
          setCurrentContact(contact)
          onGetMessages(messageThread?._id).then()
        } else {
          const contacts = await getMyContacts()
          const userContacts = contacts?.data.filter((c) => c._id === receiverId)

          let sellerProfile = null

          if (userContacts.length) {
            sellerProfile = userContacts[0]
          } else {
            await createContact(receiverId)
            const contacts = await getMyContacts()
            const userContacts = contacts?.data.filter((c) => c._id === receiverId)
            if (userContacts.length) {
              sellerProfile = userContacts[0]
            }
          }

          await onReceiverSelected(undefined, {
            ...sellerProfile,
            _id: sellerProfile?.id,
          })
        }
      }

      if (showModal) {
        setShowPostForwardModal(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState, threadsSorted, userInfo])

  useOutsideClick({
    ref: newMessageAreaRef,
    handler: () => setShowSearchPopOver(false),
  })

  useEffect(() => {
    setNewMessageCount(0)
    onGetData().then()

    socket?.on('online', (message) => handleOnlineStatus(message, true))
    socket?.on('offline', (message) => handleOnlineStatus(message, false))
    socket?.on('message', (data) => handleOnMessage(data))
    socket?.on('read', (data) => onGetMessages(data?.threadId))

    return () => {
      socket?.off('online')
      socket?.off('offline')
      socket?.off('message')
      socket?.off('read')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      setForwardMessage('')
    }
  }, [setForwardMessage])

  useEffect(() => {
    if (messageTone) {
      setMessage_Tone(messageTone)
    }
  }, [messageTone, setMessage_Tone])

  useEffect(() => {
    if (messageSendTone) {
      setMessageSendTone(messageSendTone)
    }
  }, [messageSendTone, setMessageSendTone])

  useEffect(() => {
    if (startRecordingTone) {
      setStartRecording_Tone(startRecordingTone)
    }
  }, [startRecordingTone, setStartRecording_Tone])

  useEffect(() => {
    if (stopRecordingTone) {
      setStopRecording_Tone(stopRecordingTone)
    }
  }, [stopRecordingTone, setStopRecording_Tone])

  useEffect(() => {
    if (outgoingCallTone) {
      setOutgoingCallTone(outgoingCallTone)
    }
  }, [outgoingCallTone, setOutgoingCallTone])

  const onGetData = async () => {
    let myContacts = await onGetMyContacts()
    setUserContacts(myContacts || [])
    setFilteredUserContacts(myContacts || [])
    let threads = await onGetMessageThreads()

    if (lastVideoCallId) {
      let targetUserId = lastVideoCallId

      let selectedThread = threads?.find((thread) => {
        return targetUserId === thread?.receiver?._id || targetUserId === thread?.sender?._id
      })

      try {
        const { data: contact } = await getUser(targetUserId)
        await onReceiverSelected(selectedThread, {
          ...contact,
          _id: contact.id,
        })
      } catch (error) {
        if (error?.response?.status !== 403) {
          toast({
            title: 'Oops! Something Went Wrong',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      }
    }
  }

  const handleOnlineStatus = (message, isOnline) => {
    const { userId } = message

    setMessageThreads((messageThreads) => {
      return messageThreads.map((message) => {
        let updated = { ...message }

        if (updated?.sender?._id === userId) {
          updated.sender.isOnline = isOnline
        }

        if (updated?.receiver?._id === userId) {
          updated.receiver.isOnline = isOnline
        }

        return updated
      })
    })
  }

  const playNewMessageTone = () => {
    if (messageToneRef.current) {
      messageToneRef.current.muted = false

      const audioPlayPromise = messageToneRef?.current.play()

      if (audioPlayPromise !== undefined) {
        audioPlayPromise
          .then((_) => {
            console.log('audio played auto')
          })
          .catch((_) => {
            console.log('playback prevented')
          })
      }
    }
  }

  const playMessageSendTone = () => {
    if (messageSendToneRef.current) {
      messageSendToneRef.current.muted = false

      const audioPlayPromise = messageSendToneRef?.current.play()

      if (audioPlayPromise !== undefined) {
        audioPlayPromise.then((_) => {}).catch((_) => {})
      }
    }
  }

  const playNewStartRecordingTone = () => {
    if (startRecordingToneRef.current) {
      startRecordingToneRef.current.muted = false

      const audioPlayPromise = startRecordingToneRef?.current.play()

      if (audioPlayPromise !== undefined) {
        audioPlayPromise.then((_) => {}).catch((_) => {})
      }
    }
  }

  const playNewStopRecordingTone = () => {
    if (stopRecordingToneRef.current) {
      stopRecordingToneRef.current.muted = false

      const audioPlayPromise = stopRecordingToneRef?.current.play()

      if (audioPlayPromise !== undefined) {
        audioPlayPromise.then((_) => {}).catch((_) => {})
      }
    }
  }

  const playOutgoingCallTone = () => {
    if (outgoingCallToneRef.current) {
      outgoingCallToneRef.current.muted = false

      const audioPlayPromise = outgoingCallToneRef?.current.play()

      if (audioPlayPromise !== undefined) {
        audioPlayPromise.then((_) => {}).catch((_) => {})
      }
    }
  }

  const handleOnMessage = (data) => {
    playNewMessageTone()
    const { message, thread } = data

    if (!showNewMessageRef?.current && message.senderId === currentContactRef?.current?._id) {
      setUserMessages((messageThreads) => {
        return [...messageThreads, message]
      })
    }

    handleAfterNewMessage(message, thread, message.senderId)
  }

  const handleAfterNewMessage = (message, insertedThread, targetUserId) => {
    setMessageThreads((messageThreads) => {
      if (insertedThread?._id) messageThreads = [insertedThread, ...messageThreads]

      let updatedThreads = messageThreads.map((thread) => {
        if (thread?._id === message.threadId) {
          thread.hasNewMessage = targetUserId !== currentContactRef?.current?._id
          thread.lastMessage = {
            _id: message._id,
            text: message.text,
            type: message.type,
            receiverIdLastMessage: message.receiverId,
            updatedAt: message.updatedAt,
          }
          thread.updatedAt = new Date().toISOString()
        }

        return thread
      })

      scrollChatToBottom()

      return [...updatedThreads]
    })
  }

  const onGetMyContacts = async () => {
    try {
      let { data: myContacts } = await getMyContacts()

      myContacts = myContacts.map((contact) => {
        let updated = { ...contact }
        return updated
      })

      return myContacts
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const onGetMessageThreads = async (threadId = null) => {
    try {
      let { data: messageThreads } = await getMyMessageThreads()

      setMessageThreads(messageThreads || [])
      setIsLoading(false)

      if (threadId) {
        let findThread = messageThreads.find((thread) => thread?._id === threadId)
        await onReceiverSelected(findThread, currentContact)
      }

      return messageThreads
    } catch (error) {
      setIsLoading(false)
      if (error?.response?.status !== 403) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const onGetMessages = async (threadId, deleteDate = null) => {
    try {
      let { data: messages } = await getMessages(threadId)
      setUserMessages(messages || [])
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const onDeleteThread = async (threadId) => {
    try {
      let resp = await deleteThread(threadId)
      if (resp) {
        setUserContacts([])
        setFilteredUserContacts([])
        setMessageThreads([])
        setShowNewMessage(false)
        setCurrentThread({})
        setCurrentDeleteDate(null)
        setCurrentContact(initCurrentContact)
        setShowSearchPopOver(false)
        setUserMessages([])
        setTypedMessage('')
        setLastMessagePrompt(false)
        setIsLoading(true)
        setSearchText('')
        setIsMessageSend(false)
        onGetData()
      }
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const onUpdateDeleteDate = async (threadId) => {
    try {
      let resp = await updateDeleteDate(threadId, { userId: userInfo?.id })
      if (resp) {
        setUserContacts([])
        setFilteredUserContacts([])
        setMessageThreads([])
        setShowNewMessage(false)
        setCurrentThread({})
        setCurrentDeleteDate(null)
        setCurrentContact(initCurrentContact)
        setShowSearchPopOver(false)
        setUserMessages([])
        setTypedMessage('')
        setLastMessagePrompt(false)
        setIsLoading(true)
        setSearchText('')
        setIsMessageSend(false)
        onGetData()
      }
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const onMessageCreate = async () => {
    let audio = null

    setIsMessageSend(true)

    if (typedMessage || audioBlob.current) {
      if (audioBlob.current) audio = new File([audioBlob.current], `${uuidv4()}.mp3`)

      const messageToSend = {
        text: typedMessage,
        type: audio ? 4 : 1,
        connectionId: socket?.id,
        receiverId: currentContact._id,
      }

      const messageFormData = new FormData()

      messageFormData.append('message', JSON.stringify(messageToSend))

      audioBlob.current && messageFormData.append('audio', audio)

      setTypedMessage('')

      audioBlob.current = null

      setRecordStarted(false)

      let {
        data: { message, thread },
      } = await createMessage(messageFormData)

      setUserMessages([...userMessages, message])
      handleAfterNewMessage(message, thread, message.receiverId)
      setForwardMessage('')
      setIsMessageSend(false)
      playMessageSendTone()
    }
  }

  useEffect(() => {
    if (threadsSorted?.length) {
      const listThreads = JSON.parse(localStorage.getItem('thread'))

      if (listThreads?._id) {
        let contact = initCurrentContact

        if (userInfo.id === listThreads?.receiver?._id) {
          contact = listThreads?.sender
        } else {
          contact = listThreads?.receiver
        }

        setCurrentThread(listThreads)
        setCurrentContact(contact)
        onGetMessages(listThreads?._id).then()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadsSorted])

  const onReceiverSelected = async (messageThread, contact, isMessageClicked = false) => {
    setShowNewMessage(false)
    setCurrentThread(messageThread)
    setCurrentContact(contact)

    let deleteDate =
      contact?._id === messageThread?.senderId
        ? messageThread?.receiverIdDeleteDate
        : messageThread?.senderIdDeleteDate

    setCurrentDeleteDate(deleteDate)

    if (messageThread?._id) {
      setMessageThreads((messageThreads) => {
        let updatedThreads = messageThreads.map((thread) => {
          let updated = { ...thread }
          if (thread?._id === messageThread?._id) {
            updated.hasNewMessage = false
          }
          return updated
        })
        return [...updatedThreads]
      })

      const localThread = JSON.parse(localStorage.getItem('thread'))

      if (!localThread?._id) await onGetMessages(messageThread?._id, deleteDate)
    } else {
      setUserMessages([])
    }
    if (!isMessageClicked) scrollChatToBottom()

    if (inputFocusRef.current) {
      inputFocusRef.current.focus()
    }
  }

  const scrollChatToBottom = () => {
    let currentDOMItem = document.getElementById(SCROLL_TARGET)
    if (currentDOMItem) {
      setTimeout(() => {
        scrollIntoView(currentDOMItem, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
        })
      }, 200)
    }
  }

  useEffect(() => {
    if (messageThreads && messageThreads.length) {
      const threadsSorted = messageThreads?.sort((a, b) => {
        try {
          if (a.updatedAt && b.updatedAt) {
            return +new Date(b.updatedAt) - +new Date(a.updatedAt)
          } else {
            return -1
          }
        } catch (error) {
          return -1
        }
      })
      setThreadsSorted(threadsSorted)
    }
  }, [messageThreads])

  useEffect(() => {
    if (currentContact?._id === null) {
      setShowNewMessage(true)
    }
  }, [currentContact, setShowNewMessage])

  const onDeleteMessage = async (id) => {
    try {
      const messageId = await deleteMessage(id)
      if (messageId.status === 200) {
        await onGetMessages(currentThread?._id, currentDeleteDate)
        await onGetMessageThreads()
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onMultipleDeleteMessage = async () => {
    try {
      const messageId = await multipleMessageDelete({
        ids: multipleMessageSelect,
      })
      if (messageId.status === 200) {
        setMultipleMessageSelect([])
        await onGetMessages(currentThread?._id, currentDeleteDate)
        await onGetMessageThreads()
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleMultiMessageSelect = (e) => {
    const id = e.target.value
    const index = multipleMessageSelect.findIndex((existingId) => existingId === id)

    if (index === -1) {
      setMultipleMessageSelect((ids) => [...ids, id])
    } else {
      const ids = [...multipleMessageSelect]
      ids.splice(index, 1)
      setMultipleMessageSelect(ids)
    }
  }

  const onMessageForward = async (id) => {
    setIsMessageForward(true)
    try {
      const messageId = await messageForward(id, { ids: multipleMessageSelect })

      if (messageId.status === 201) {
        setTimeout(async () => {
          setMultipleMessageSelect([])
          setMultipleSelect(false)
          setIsMessageForward(false)
          setShowForwardModal(false)

          const selectedThread = threadsSorted.filter(
            (t) => t.receiverId === id || t.senderId === id
          )

          if (selectedThread.length) {
            const messageThread = selectedThread[0]

            let contact
            if (userInfo.id === messageThread?.receiver?._id) {
              contact = messageThread?.sender
            } else {
              contact = messageThread?.receiver
            }

            localStorage.setItem('thread', JSON.stringify(messageThread))

            await onReceiverSelected(messageThread, contact)
            await onGetMessages(currentThread?._id, currentDeleteDate)
            await onGetMessageThreads()
            playMessageSendTone()
          }
        }, 1000)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleOnFilePick = (event) => {
    const allowedExtensions = [
      'pdf',
      'png',
      'gif',
      'jpg',
      'jpeg',
      'mp3',
      'mp4',
      'avi',
      'xlsx',
      'xls',
      'doc',
      'docx',
      'ppt',
      'pptx',
      'txt',
    ]
    if (!event.target.files[0]) return
    for (let file = 0; file < event.target.files.length; file++) {
      const fileName = event.target.files[file].name
      const lastIndex = fileName.lastIndexOf('.')
      const extension = fileName.slice(lastIndex + 1)

      if (!allowedExtensions.includes(extension)) {
        toast({
          title: `Extension not Allowed`,
          description: `${extension} file is not allowed to upload`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }
    }

    setAttachments((files) => [...files, ...event.target.files])
  }

  const generateFileName = (fileName, length) => {
    const lastIndex = fileName.lastIndexOf('.')
    const extension = fileName.slice(lastIndex + 1)

    let name = fileName.slice(0, lastIndex)

    if (length < name.length) {
      name = name.slice(0, length)
      return `${name}...${extension}`
    }

    return fileName
  }

  const onMessageCreateWithFiles = async () => {
    try {
      if (attachments.length || typedMessage) {
        setIsMessageSend(true)
        const formData = new FormData()

        let reqObject = {
          message: {
            text: typedMessage,
            receiverId: currentContact._id,
            connectionId: socket?.id,
            type: 1,
          },
        }

        formData.append('message', JSON.stringify(reqObject.message))

        if (attachments.length) {
          attachments.forEach((file) => {
            formData.append('files', file)
          })
        }

        setTypedMessage('')

        let { data } = await createMessageWithFiles(formData)

        await onGetMessageThreads(data.message.threadId)

        if (currentThread?._id && currentDeleteDate) {
          await onGetMessages(currentThread?._id, currentDeleteDate)
        }

        setAttachments([])
        setIsMessageSend(false)
        playMessageSendTone()
      }
    } catch (error) {
      setIsMessageSend(false)
      toast({
        title: 'Oops! Something Went Wrong',
        description:
          error.message === 'Request failed with status code 409'
            ? 'The content of your message violates the safety policies of Co-Nectar as a platform'
            : error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleFileDownload = (attachments) => {
    attachments.forEach((element) => {
      const link = document.createElement('a')
      link.href = element.url
      if (element.type === 'pdf') {
        link.target = '_blank'
      }
      link.download = true
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  const onRemoveFile = (index) => {
    const files = [...attachments]
    files.splice(index, 1)
    setAttachments(files)
  }

  const onIdle = () => {
    socket.disconnect()
  }

  const onActive = () => {
    let userInfoJSON = localStorage.getItem('user')
    let userInfo = JSON.parse(userInfoJSON)
    if (socket && userInfo?.accessToken) {
      if (socket.disconnected) {
        socket.connect()
      }
      socket.emit('join', userInfo.accessToken)
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 3,
    onIdle,
    onActive,
    debounce: 500,
  })

  useEffect(() => {
    if (forwardMessage && postReceiverId && threadsSorted.length) {
      handleForwardPost(postReceiverId).then()
      setForwardMessage('')
      setPostReceiverId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forwardMessage, postReceiverId, threadsSorted])

  const handleForwardPost = async (receiverId) => {
    setIsMessageForward(true)

    if (attachments.length || forwardMessage) {
      const formData = new FormData()
      let reqObject = {
        message: {
          text: forwardMessage,
          receiverId: receiverId,
          connectionId: socket?.id,
          type: 1,
        },
      }

      formData.append('message', JSON.stringify(reqObject.message))

      if (attachments.length) {
        attachments.forEach((file) => {
          formData.append('files', file)
        })
      }

      setTypedMessage('')

      let { data } = await createMessageWithFiles(formData)
      await onGetMessageThreads(data.message.threadId)

      const selectedThread = threadsSorted.filter(
        (t) => t.receiverId === receiverId || t.senderId === receiverId
      )

      if (selectedThread.length) {
        const messageThread = selectedThread[0]

        let contact

        if (userInfo.id === messageThread?.receiver?._id) {
          contact = messageThread?.sender
        } else {
          contact = messageThread?.receiver
        }

        localStorage.setItem('thread', JSON.stringify(messageThread))

        await onReceiverSelected(messageThread, contact)
        await onGetMessages(messageThread?._id, currentDeleteDate)
        await onGetMessageThreads()

        scrollChatToBottom()
      }

      setAttachments([])
      setIsMessageSend(false)
      setIsMessageForward(false)
      setShowPostForwardModal(false)
      playMessageSendTone()
    }
  }

  const executeScroll = (ref) => ref.scrollIntoView({ behavior: 'smooth' })

  const handleButtonClick = () => {
    if (initialSearchFocusRef.current) {
      initialSearchFocusRef.current.focus()
    }
  }

  useEffect(() => {
    if (scrollMessageId && userMessages.length && scrollRef.current) {
      if (scrollRef.current) executeScroll(scrollRef.current)
      setScrollMessageId(null)
      scrollRef.current = null
    }
  }, [scrollMessageId, scrollRef, userMessages])

  useEffect(() => {
    if (userMessages.length && userInfo && userInfo.id) {
      const lastUnread = userMessages.findLast((m) => m.receiverId === userInfo.id)

      if (lastUnread && lastUnread?.status !== 'read') {
        readMessages({
          threadId: lastUnread.threadId,
          messageId: lastUnread._id,
          senderId: lastUnread.senderId,
        })
      }
    }
  }, [userInfo, userMessages])

  useEffect(() => {
    if (showNewMessage) {
      handleButtonClick()
    }
  }, [showNewMessage])

  return (
    <div className="h-full relative">
      {showCallWindow && (
        <CallWindow
          onEnd={() => {
            setShowCallWindow(false)
            onGetData()
          }}
          isVideoCall={isVideoCall}
          setIsVideoCall={setIsVideoCall}
          userProfile={currentContact}
          isOutGoingCall={true}
          setLastVideoCallId={setLastVideoCallId}
          handleAfterNewMessage={handleAfterNewMessage}
          currentThreadId={currentThread?._id}
        />
      )}
      <div className="h-screen w-full flex pt-20 antialiased text-gray-200  overflow-hidden">
        <div className="flex-1 flex flex-col">
          <main className="flex-grow flex flex-row min-h-0">
            {showThread && (
              <MessageThreads
                isMobile={width <= 767}
                width={width}
                handleCloseThread={handleCloseThread}
                setShowThread={setShowThread}
                setShowNewMessage={setShowNewMessage}
                setCurrentThread={setCurrentThread}
                setCurrentContact={setCurrentContact}
                initCurrentContact={initCurrentContact}
                setFilteredUserContacts={setFilteredUserContacts}
                userContacts={userContacts}
                setSearchUserTex={setSearchUserTex}
                CalendarContainer={CalendarContainer}
                threadsSorted={threadsSorted}
                onReceiverSelected={onReceiverSelected}
                setScrollMessageId={setScrollMessageId}
                isLoading={isLoading}
                showPostForwardModal={showPostForwardModal}
                onMessageForward={onMessageForward}
                isMessageForward={isMessageForward}
                handleForwardPost={handleForwardPost}
                showNewMessage={showNewMessage}
                lastMessagePrompt={lastMessagePrompt}
                setLastMessagePrompt={setLastMessagePrompt}
                currentContact={currentContact}
                scrollChatToBottom={scrollChatToBottom}
                onUpdateDeleteDate={onUpdateDeleteDate}
                onDeleteThread={onDeleteThread}
              />
            )}
            {!isLoading ? (
              <section className="flex w-full">
                {!showNewMessage && currentContact?._id !== null ? (
                  <Flex flexDirection={'column'} flex="auto" border="thick" borderColor="gray.800">
                    <div className="chat-header px-6 py-4 flex flex-row flex-none justify-between items-center shadow">
                      <div className="flex items-center">
                        {width < 768 && (
                          <div className="flex pr-4">
                            <button onClick={handleShowThread}>
                              <GiHamburgerMenu size={20} />
                            </button>
                          </div>
                        )}
                        <div
                          className="flex"
                          onClick={() => {
                            history.push(`/profile/${currentContact?._id}`)
                          }}
                        >
                          <div className="w-12 h-12 mr-4 flex flex-shrink-0">
                            <p />
                            <img
                              className="shadow-md rounded-full w-full h-full object-cover cursor-pointer"
                              src={currentContact?.photo || profilePicture}
                              alt=""
                            />
                            <div
                              className={`absolute bg-gray-900 p-1 rounded-full bottom-0 right-0 ${
                                currentContact?.isOnline ? '' : 'hidden'
                              }`}
                            >
                              <div className="bg-green-500 rounded-full w-2 h-2" />
                            </div>
                          </div>
                          <div className="text-sm cursor-pointer">
                            <p className="font-bold">{currentContact?.name}</p>
                            <p className="text-gray-300">{`@${currentContact?.username}`}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <button
                          onClick={() => setMultipleSelect(!multipleSelect)}
                          className="block rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 md:block group-hover:block cursor-pointer"
                        >
                          <BiSelectMultiple size={25} />
                        </button>
                        {multipleMessageSelect?.length > 0 && (
                          <>
                            <button
                              onClick={() => setShowForwardModal(true)}
                              className="block rounded-full mr-2 ml-2 hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 md:block group-hover:block cursor-pointer"
                            >
                              <ImForward size={25} />
                            </button>
                            <button
                              onClick={() => onMultipleDeleteMessage()}
                              className="block rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 md:block group-hover:block cursor-pointer"
                            >
                              <MdDeleteOutline size={25} />
                            </button>
                            <div className="flex justify-items-center items-center ml-2 ">
                              {multipleMessageSelect.length}
                            </div>
                          </>
                        )}
                        {showForwardModal && (
                          <MessageForwardModal
                            onMessageForward={onMessageForward}
                            isMessageForward={isMessageForward}
                            onClose={() => setShowForwardModal(false)}
                          />
                        )}
                        <button
                          className="block rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 ml-4"
                          onClick={() => {
                            playOutgoingCallTone()
                            setShowCallWindow(true)
                            setIsVideoCall(false)
                          }}
                        >
                          <img src={phone} alt="" />
                        </button>
                        <button
                          className="block rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 ml-4"
                          onClick={() => {
                            playOutgoingCallTone()
                            setShowCallWindow(true)
                            setIsVideoCall(true)
                          }}
                        >
                          <svg
                            viewBox="0 0 20 20"
                            className="w-full h-full fill-current text-blue-500"
                          >
                            <path d="M0,3.99406028 C0,2.8927712 0.894513756,2 1.99406028,2 L14.0059397,2 C15.1072288,2 16,2.89451376 16,3.99406028 L16,16.0059397 C16,17.1072288 15.1054862,18 14.0059397,18 L1.99406028,18 C0.892771196,18 0,17.1054862 0,16.0059397 L0,3.99406028 Z M8,14 C10.209139,14 12,12.209139 12,10 C12,7.790861 10.209139,6 8,6 C5.790861,6 4,7.790861 4,10 C4,12.209139 5.790861,14 8,14 Z M8,12 C9.1045695,12 10,11.1045695 10,10 C10,8.8954305 9.1045695,8 8,8 C6.8954305,8 6,8.8954305 6,10 C6,11.1045695 6.8954305,12 8,12 Z M16,7 L20,3 L20,17 L16,13 L16,7 Z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="flex-col-reverse justify-start chat-body p-4 flex-1 overflow-y-scroll">
                      {userMessages.map((message, i) => (
                        <MessageItem
                          key={i}
                          message={message}
                          userInfo={userInfo}
                          currentContact={currentContact}
                          scrollMessageId={scrollMessageId}
                          scrollRef={scrollRef}
                          generateFileName={generateFileName}
                          setShowForwardModal={setShowForwardModal}
                          setMultipleMessageSelect={setMultipleMessageSelect}
                          handleFileDownload={handleFileDownload}
                          onDeleteMessage={onDeleteMessage}
                          multipleSelect={multipleSelect}
                          handleMultiMessageSelect={handleMultiMessageSelect}
                        />
                      ))}
                      <div id={SCROLL_TARGET} />
                    </div>
                    <div className="chat-footer flex-none">
                      <div
                        className={
                          showThread && width <= 767 ? 'hidden' : 'flex flex-row items-center p-4'
                        }
                      >
                        <Box>
                          <IconButton
                            cursor={'pointer'}
                            mr={1}
                            variant="unstyled"
                            aria-label="Audio"
                            as={recordStarted ? IoIosCloseCircle : IoMdMic}
                            w={8}
                            h={8}
                            onClick={() => {
                              setRecordStarted(!recordStarted)
                              if (!recordStarted) {
                                playNewStartRecordingTone()
                              } else {
                                playNewStopRecordingTone()
                              }
                            }}
                          />
                        </Box>
                        <Box>
                          <EmojiPickerPopover
                            onSelectEmoji={(emoji) => {
                              setTypedMessage((prev) => `${prev}${emoji}`)
                            }}
                          />
                        </Box>
                        <Box>
                          <IconButton
                            rounded={'full'}
                            variant={'ghost'}
                            aria-label={''}
                            mr={2}
                            size={'md'}
                            title={'File Attach'}
                            icon={<IoAttachOutline size={32} />}
                            onClick={() => fileInputRef?.current?.click()}
                          />
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept=".png,.jpg,.jpeg,.gif,.avi,.mp4,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.mp3"
                            multiple
                            id={Math.random().toString()}
                            onChange={handleOnFilePick}
                            onClick={(event) => {
                              event.target.value = ''
                            }}
                            hidden
                          />
                        </Box>
                        {!recordStarted ? (
                          <div className="relative flex-grow">
                            <div>
                              {attachments.length ? (
                                <div className="rounded py-2 pl-5 pr-5 w-full border border-gray-800 bg-gray-800 ">
                                  <div className="flex mb-2">
                                    {attachments.map((file, index) => {
                                      return (
                                        <div className="mr-2">
                                          <div>
                                            <button
                                              onClick={() => onRemoveFile(index)}
                                              className="block ml-7 rounded-full hover:bg-gray-700 bg-gray-800 w-10 h-10 p-2 cursor-pointer"
                                            >
                                              <TiDeleteOutline className="w-full h-full" />
                                            </button>
                                            <Icon as={ImFilesEmpty} boxSize="50px" />
                                            <p color="white">{generateFileName(file.name, 5)}</p>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                  <input
                                    className="rounded py-2 pl-3 pr-10 w-full focus:border-gray-700 bg-gray-800 focus:bg-gray-900 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in"
                                    type="text"
                                    value={typedMessage}
                                    onChange={({ target }) => {
                                      setTypedMessage(target.value)
                                    }}
                                    onClick={() => setForwardMessage('')}
                                    onKeyPress={(event) => {
                                      if (event.key === 'Enter') {
                                        if (audioBlob.current) {
                                          onMessageCreate()
                                        } else {
                                          onMessageCreateWithFiles()
                                        }
                                      }
                                    }}
                                    placeholder="Aa"
                                  />
                                  {isMessageSend ? (
                                    <button className="absolute top-0 right-0 mt-2 mr-3 flex flex-shrink-0 focus:outline-none text-blue-600 hover:text-blue-700 w-6 h-6">
                                      <Spinner />
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="absolute top-0 right-0 mt-2 mr-3 flex flex-shrink-0 focus:outline-none text-blue-600 hover:text-blue-700 w-6 h-6"
                                      >
                                        <svg
                                          viewBox="0 0 20 20"
                                          className="w-full h-full fill-current hidden"
                                        >
                                          <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 3a6 6 0 0 1-11.32 0h11.32z" />
                                        </svg>
                                      </button>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <input
                                    className="rounded-full py-2 pl-3 pr-10 w-full border border-gray-800 focus:border-gray-700 bg-gray-800 focus:bg-gray-900 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in"
                                    type="text"
                                    value={typedMessage}
                                    onChange={({ target }) => {
                                      setTypedMessage(target.value)
                                    }}
                                    ref={inputFocusRef}
                                    autoFocus
                                    onClick={() => setForwardMessage('')}
                                    onKeyPress={(event) => {
                                      if (event.key === 'Enter') {
                                        if (audioBlob.current) {
                                          onMessageCreate()
                                        } else {
                                          onMessageCreateWithFiles()
                                        }
                                      }
                                    }}
                                    placeholder="Aa"
                                  />
                                  {isMessageSend ? (
                                    <button className="absolute top-0 right-0 mt-2 mr-3 flex flex-shrink-0 focus:outline-none text-blue-600 hover:text-blue-700 w-6 h-6">
                                      <Spinner />
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        className="absolute top-0 right-0 mt-2 mr-3 flex flex-shrink-0 focus:outline-none text-blue-600 hover:text-blue-700 w-6 h-6"
                                      >
                                        <svg
                                          viewBox="0 0 20 20"
                                          className="w-full h-full fill-current hidden"
                                        >
                                          <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 3a6 6 0 0 1-11.32 0h11.32z" />
                                        </svg>
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <AudioRecorder
                            recordStarted={recordStarted}
                            onRecordChange={(value, blob) => {
                              audioBlob.current = blob
                            }}
                            onRecordCancel={() => {
                              setRecordStarted(false)
                              audioBlob.current = null
                            }}
                          />
                        )}
                        <IconButton
                          cursor={'pointer'}
                          ml={2}
                          variant="unstyled"
                          aria-label="Send"
                          as={IoSendSharp}
                          w={8}
                          h={8}
                          color="primary.500"
                          onClick={() =>
                            audioBlob.current ? onMessageCreate() : onMessageCreateWithFiles()
                          }
                          disabled={isMessageSend}
                        />
                      </div>
                    </div>
                  </Flex>
                ) : (
                  <Flex flexDirection={'column'} flex="auto" border="thick" borderColor="gray.800">
                    <div className="chat-header px-6 py-4 flex flex-row flex-none justify-between items-center shadow">
                      <div ref={newMessageAreaRef} className="flex">
                        <Popover
                          initialFocusRef={initialSearchFocusRef}
                          isOpen={showSearchPopOver}
                          placement="bottom"
                          closeOnBlur={false}
                        >
                          <PopoverTrigger>
                            <InputGroup
                              className={showThread && width <= 767 && 'mt-5'}
                              border="none"
                            >
                              <InputLeftAddon
                                border="none"
                                children="Search:"
                                bg="transparent"
                                borderRight={'thin'}
                                borderColor={'gray.200'}
                              />
                              <Input
                                ref={initialSearchFocusRef}
                                border="none"
                                colorScheme="messenger"
                                type="text"
                                style={showThread && width <= 767 ? { width: '11.5rem' } : {}}
                                bg={showThread && width <= 767 ? 'gray.700' : 'transparent'}
                                placeholder="Enter a username"
                                onFocus={() => {
                                  setShowSearchPopOver(true)
                                }}
                                onChange={({ target }) => {
                                  setSearchUserTex(target.value)
                                  if (!target.value) {
                                    setFilteredUserContacts(userContacts || [])
                                  } else {
                                    let searched = userContacts.filter(
                                      (contact) =>
                                        contact.name
                                          .toLowerCase()
                                          .includes(target.value.toLowerCase()) ||
                                        contact.username
                                          .toLowerCase()
                                          .includes(target.value.toLowerCase())
                                    )
                                    setFilteredUserContacts(searched || [])
                                  }
                                }}
                              />
                            </InputGroup>
                          </PopoverTrigger>

                          <PopoverContent p={5} minH={40} ml="58px">
                            {filteredUserContacts?.map((userProfile) => {
                              return (
                                <ConnectionRow
                                  key={userProfile.id}
                                  isNewUser={true}
                                  currentUserId={userInfo.id}
                                  userProfile={{
                                    ...userProfile,
                                    connected: true,
                                  }}
                                  onSelect={(userProfile) => {
                                    let selectedThread = messageThreads?.find((thread) => {
                                      return (
                                        userProfile.id === thread?.receiver?._id ||
                                        userProfile.id === thread?.sender?._id
                                      )
                                    })

                                    onReceiverSelected(selectedThread, {
                                      ...userProfile,
                                      _id: userProfile.id,
                                    })
                                  }}
                                  hideRightButton
                                />
                              )
                            })}
                          </PopoverContent>
                        </Popover>
                      </div>

                      <div className="flex" />
                    </div>
                  </Flex>
                )}
              </section>
            ) : (
              <Loader />
            )}
          </main>
        </div>
      </div>
    </div>
  )
}

export default Messages

const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */

  .react-calendar {
    background: #1a202c;
  }

  .react-calendar__tile:disabled {
    background: #273241;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #1087ff;
  }

  .react-calendar__tile--now {
    background: #7f58de;
  }

  .react-calendar__navigation button:disabled {
    background-color: #1a202c;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #1087ff;
  }
`
