import React, { useContext, useEffect, useState } from 'react'
import { format, formatDistanceToNowStrict } from 'date-fns'
import { GlobalContext } from '../..'
import useComponentVisible from '../../hooks/useComponentVisible'
import { deletePost, updateLikePost } from '../../services/post.service'
import ModalCustom from '../../components/ModalCustom/ModalCustom'
import {
  Divider,
  HStack,
  Icon,
  Link,
  SkeletonCircle,
  Text,
  Tooltip,
  useClipboard,
  useToast,
} from '@chakra-ui/react'
import { useHistory } from 'react-router'
import { PRIVACY_MODES } from '../../components/PrivacySelector'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { FiSend } from 'react-icons/fi'
import ShareModal from '../../components/ShareModal'
import LikesModal from '../../components/LikesModal'
import RePost from './RePost'
import ShareService from '../Marketplace/ShareService'
import PostCarousel from '../../components/PostCarousel'
import MessageForwardModal from '../Message/components/MessageForwardModal'

const Post = ({
  post,
  onDelete = () => {},
  onEdit = () => {},
  onRepost = () => {},
  hideEdit = false,
}) => {
  const history = useHistory()
  const postLink = `${window.location.origin}/${post.userName}/post/${post.id}`
  const { onCopy } = useClipboard(postLink)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [showLikes, setShowLikes] = useState(false)
  const [isProfileImgLoaded, setIsProfileImgLoaded] = useState(false)
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const [files, setFiles] = useState([])
  const [defaultIndex, setDefaultIndex] = useState(0)
  const [showMessageForwardModal, setShowMessageForwardModal] = useState(false)
  const { userInfo, setForwardMessage } = useContext(GlobalContext)
  const {
    profilePhoto,
    text,
    createdAt,
    name,
    userName,
    tags = [],
    likes = [],
    reposts = [],
    privacy_mode = 'public',
    attachments = [],
    repostId = '',
    serviceId = '',
    comments,
  } = post
  const [likeSet, setLikeSet] = useState([])
  const [repostSet, setRepostSet] = useState([])
  const relativeTime = formatDistanceToNowStrict(new Date(createdAt), { addSuffix: false })
  const readableTime = format(new Date(createdAt), 'dd MMM yyyy hh:mm aaaa')
  const toast = useToast()

  useEffect(() => {
    let updated =
      attachments?.map((x) => {
        return {
          ...x,
          original: x.url,
          thumbnail: x.url,
        }
      }) || []

    let defaultIndex = attachments.findIndex((x) => x?.isDefault)
    defaultIndex = defaultIndex < 0 ? 0 : defaultIndex
    setDefaultIndex(defaultIndex)
    setFiles(updated)
  }, [attachments])

  useEffect(() => {
    setLikeSet(likes)
  }, [likes])

  useEffect(() => {
    setRepostSet(reposts)
  }, [reposts])

  useEffect(() => {
    const id = localStorage.getItem('current_post')

    if (id) {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'auto' })
      }
    }
  }, [])

  const onDeletePost = async () => {
    setIsComponentVisible(false)
    setShowDeleteConfirmation(false)
    try {
      await deletePost(post.id)
      onDelete()
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onLikeHandle = async () => {
    try {
      const { data } = await updateLikePost(post.id, {
        userId: userInfo.id,
      })
      setLikeSet([...data.post.likes])
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onSendHandle = () => {
    setForwardMessage(postLink)
    setShowMessageForwardModal(true)
  }

  const handleUserOnSelect = () => {
    history.push(`/profile/${post?.user?.id}`)
  }

  const handleNonLoggedInUser = () => {
    history.push('/login')
  }

  const handleOpenPost = () => {
    if (history.location.pathname === '/') {
      history.push(`${post?.user?.username}/post/${post?.id}`)
    }
    localStorage.setItem('current_post', post?.id)
  }

  const getUrlFromPost = (text) => {
    let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
    return text.replace(urlRegex, function (url, b, c) {
      let url2 = c === 'www.' ? 'http://' + url : url
      return '<a class="postUrl" href="' + url2 + '" target="_blank">' + url + '</a>'
    })
  }

  return (
    <div>
      <article
        className="hover:bg-gray-800 transition duration-350 ease-in-out cursor-pointer"
        id={post?.id}
      >
        <div className="flex flex-1 flex-shrink-0 p-4 pb-0">
          <div className="flex-shrink-0 flex-1 group block">
            <div className="flex items-center flex-1">
              <div onClick={handleUserOnSelect}>
                {!isProfileImgLoaded && (
                  <SkeletonCircle size="10" fadeDuration={0.1} startColor="#1F2937" />
                )}
                <img
                  className="inline-block h-10 w-10 rounded-full"
                  src={profilePhoto}
                  alt=""
                  style={isProfileImgLoaded ? {} : { display: 'none' }}
                  onLoad={() => setIsProfileImgLoaded(true)}
                />
              </div>
              <div onClick={handleUserOnSelect} className="ml-3">
                <p className="text-base leading-6 font-medium text-white">
                  {name}
                  <span
                    className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150"
                    title={readableTime}
                  >
                    {` @${userName} . ${relativeTime}`} .
                  </span>
                  <Icon
                    color={'gray.400'}
                    as={PRIVACY_MODES[privacy_mode || 'public'].icon}
                    boxSize={4}
                    ml={1}
                  />
                </p>
              </div>
              <div ref={ref} className="flex flex-1 justify-end relative">
                <span
                  style={{}}
                  className="text-gray-400 cursor-pointer hover:text-gray-600"
                  onClick={() => {
                    setIsComponentVisible(!isComponentVisible)
                  }}
                >
                  <i className="fas fa-ellipsis-h"></i>
                </span>
                <div
                  style={{
                    display: isComponentVisible ? 'flex' : 'none',
                  }}
                  className="origin-top-right absolute top-4 right-0 mt-2 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-50"
                >
                  <div
                    className="py-1 flex-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <span
                      className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                      role="menuitem"
                      onClick={() => {
                        onCopy()
                        setIsComponentVisible(false)
                      }}
                    >
                      <span className="flex flex-col">
                        <span>Copy link</span>
                      </span>
                    </span>
                    {!hideEdit && (
                      <>
                        <span
                          className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                          role="menuitem"
                          onClick={() => onEdit()}
                        >
                          <span className="flex flex-col">
                            <span>Edit</span>
                          </span>
                        </span>
                        <span
                          className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                          role="menuitem"
                          onClick={() => setShowDeleteConfirmation(true)}
                        >
                          <span className="flex flex-col">
                            <span>Delete</span>
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                  {showDeleteConfirmation && (
                    <ConfirmationModal
                      onPrimary={() => onDeletePost()}
                      onClose={() => setShowDeleteConfirmation(false)}
                      onSecondary={() => setShowDeleteConfirmation(false)}
                    />
                  )}
                  {showShare && (
                    <ShareModal onClose={() => setShowShare(false)} postLink={postLink} />
                  )}
                  {showLikes && <LikesModal onClose={() => setShowLikes(false)} likes={likeSet} />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pl-16" onClick={handleOpenPost}>
          <div>
            <div
              className="text-base width-auto font-medium text-white flex-shrink mr-4"
              dangerouslySetInnerHTML={{ __html: getUrlFromPost(text) }}
            />
            {tags && tags.length > 0 && (
              <HStack my={2}>
                <Divider w={6} mr={1} />
                <Text color={'gray.500'} mr={2}>
                  with
                </Text>
                {tags.map((tag, i) => {
                  return (
                    <Link
                      key={tag._id}
                      fontSize={'sm'}
                      onClick={() => {
                        history.push('/profile', {
                          userObject: { ...tag, id: tag._id },
                        })
                      }}
                    >{`${tag.name || ''}${i + 1 >= tags.length ? '' : ', '}`}</Link>
                  )
                })}
              </HStack>
            )}

            <div className="md:flex-shrink pr-6 pt-3 pb-4">
              <PostCarousel startIndex={defaultIndex} attachments={files} />
            </div>
          </div>
          {repostId && (
            <div className="text-base width-auto font-small text-white flex-shrink mr-4">
              <RePost repostId={repostId} />
            </div>
          )}
          {serviceId && (
            <div className="text-base width-auto font-small text-white flex-shrink mr-4">
              <ShareService serviceId={serviceId} />
            </div>
          )}
          <div className="flex items-center py-4">
            <div
              className="flex-1 flex
              items-center
              text-white text-xs
              transition
              duration-350
              ease-in-out"
              onClick={(event) => event.stopPropagation()}
            >
              <Tooltip label="Comment post...">
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 hover:text-blue-400 cursor-pointer"
                  onClick={userInfo.id ? handleOpenPost : handleNonLoggedInUser}
                >
                  <g>
                    <path d="M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"></path>
                  </g>
                </svg>
              </Tooltip>
              <p className="hover:text-blue-400 cursor-pointer">{comments}</p>
            </div>
            <div
              className="
                flex-1 flex
                items-center
                text-white text-xs
                transition
                duration-350
                ease-in-out"
              onClick={(event) => event.stopPropagation()}
            >
              <Tooltip label="Repost...">
                <svg
                  onClick={userInfo.id ? onRepost : handleNonLoggedInUser}
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 hover:text-green-400 cursor-pointer"
                >
                  <g>
                    <path d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"></path>
                  </g>
                </svg>
              </Tooltip>
              <p className="hover:text-green-400 cursor-pointer">
                {repostSet ? repostSet.length : 0}
              </p>
            </div>
            <div
              className="
                flex-1 flex
                items-center
                text-white text-xs
                transition
                duration-350
                ease-in-out
              "
              onClick={(event) => event.stopPropagation()}
            >
              <Tooltip
                label={likeSet && likeSet.includes(userInfo.id) ? 'Unlike post...' : 'Like post...'}
              >
                <div>
                  {likeSet && likeSet.includes(userInfo.id) ? (
                    <FaHeart
                      className="w-5 h-5 mr-2 hover:text-red-600 cursor-pointer"
                      onClick={userInfo.id ? onLikeHandle : handleNonLoggedInUser}
                    />
                  ) : (
                    <FaRegHeart
                      className="w-5 h-5 mr-2 hover:text-red-600 cursor-pointer"
                      onClick={userInfo.id ? onLikeHandle : handleNonLoggedInUser}
                    />
                  )}
                </div>
              </Tooltip>
              <p
                className="hover:text-red-600 cursor-pointer"
                onClick={
                  userInfo.id
                    ? () => likeSet.length > 0 && setShowLikes(true)
                    : handleNonLoggedInUser
                }
              >
                {likeSet.length}
              </p>
            </div>
            <div
              className="
                flex-1 flex
                items-center
                text-white text-xs
                hover:text-blue-400
                transition
                duration-350
                ease-in-out
              "
              onClick={(event) => event.stopPropagation()}
            >
              <Tooltip label="Share post...">
                <svg
                  onClick={() => setShowShare(true)}
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 cursor-pointer"
                >
                  <g>
                    <path d="M17.53 7.47l-5-5c-.293-.293-.768-.293-1.06 0l-5 5c-.294.293-.294.768 0 1.06s.767.294 1.06 0l3.72-3.72V15c0 .414.336.75.75.75s.75-.336.75-.75V4.81l3.72 3.72c.146.147.338.22.53.22s.384-.072.53-.22c.293-.293.293-.767 0-1.06z"></path>
                    <path d="M19.708 21.944H4.292C3.028 21.944 2 20.916 2 19.652V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 .437.355.792.792.792h15.416c.437 0 .792-.355.792-.792V14c0-.414.336-.75.75-.75s.75.336.75.75v5.652c0 1.264-1.028 2.292-2.292 2.292z"></path>
                  </g>
                </svg>
              </Tooltip>
            </div>
            <div
              className="
                flex-1 flex
                items-center
                cursor-pointer
                text-white
                hover:text-blue-400
                transition
                duration-350
                ease-in-out
              "
              onClick={(event) => event.stopPropagation()}
            >
              {showMessageForwardModal && (
                <MessageForwardModal
                  setShowMessageForwardModal={setShowMessageForwardModal}
                  isPost={true}
                  isFromPost={true}
                  onClose={() => setShowMessageForwardModal(false)}
                />
              )}
              <Tooltip label="Forward post...">
                <div>
                  <FiSend
                    onClick={userInfo.id ? onSendHandle : handleNonLoggedInUser}
                    className="w-5 h-5 mr-2"
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <hr className="border-gray-800" />
      </article>
    </div>
  )
}

export default Post

const ConfirmationModal = ({
  onPrimary = () => {},
  onSecondary = () => {},
  onClose = () => {},
}) => {
  return (
    <ModalCustom onClose={onClose} isOpen={true}>
      <div className="rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
        <div className="w-full h-full text-center">
          <div className="flex h-full flex-col justify-between">
            <svg
              width="40"
              height="40"
              className="mt-4 w-12 h-12 m-auto text-indigo-500"
              fill="currentColor"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"></path>
            </svg>
            <p className="text-gray-800 dark:text-gray-200 text-xl font-bold mt-4">Remove post</p>
            <p className="text-gray-600 dark:text-gray-400 text-xs py-2 px-6">
              Are you sure you want to delete this post ?
            </p>
            <div className="flex items-center justify-between gap-4 w-full mt-8">
              <button
                type="button"
                onClick={onPrimary}
                className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                Delete
              </button>
              <button
                type="button"
                onClick={onSecondary}
                className="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-indigo-500 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCustom>
  )
}
