import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({
  component: Component,
  loginPath,
  bypassAuthorization,
  permissionKey,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (localStorage.getItem('user')) {
        return <Component {...props} />
      } else {
        return <Redirect to={{ pathname: loginPath, state: { from: props.location } }} />
      }
    }}
  />
)
