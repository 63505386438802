import React, { useState, useRef, useContext, useEffect } from 'react'
import { GlobalContext } from '../..'
import { useHistory } from 'react-router-dom'
import ConnectionRow from './ConnectionRow'
import { getUserPublicSearch, getUserSearch } from '../../services/user.service'
import { useDebounce } from '../../hooks/useDebounce'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  PopoverFooter,
  useOutsideClick,
  useToast,
  Spinner,
} from '@chakra-ui/react'

const SearchBar = () => {
  const { userInfo, userInfo: currentUserInfo } = useContext(GlobalContext)
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const newSearchAreaRef = useRef(null)
  const initialFocusRef = useRef(null)
  const [userProfiles, setUserProfiles] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const debouncedSearchTerm = useDebounce(searchText, 500)
  const toast = useToast()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleUseDebounce(), [debouncedSearchTerm])

  const onSearchUsers = async () => {
    try {
      if (searchText && userInfo.id) {
        setIsSearching(true)
        const { data } = await getUserSearch(searchText)
        setIsSearching(false)
        if (data) {
          setUserProfiles([...data])
        }
      } else if (searchText) {
        setIsSearching(true)
        const { data } = await getUserPublicSearch(searchText)
        setIsSearching(false)
        if (data) {
          setUserProfiles([...data])
        }
      } else {
        setUserProfiles([])
        setIsSearching(false)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleUseDebounce = () => {
    if (debouncedSearchTerm) {
      setIsSearching(true)
      onSearchUsers()
    } else {
      setUserProfiles([])
      setIsSearching(false)
    }
  }

  useOutsideClick({
    ref: newSearchAreaRef,
    handler: () => setIsOpen(false),
  })

  const handleUserOnSelect = (userProfile) => {
    setSearchText('')
    setIsOpen(false)
    setUserProfiles([])
    history.push('/profile', {
      userObject: userProfile,
    })
  }

  const handleSearchCloseIconOnClick = (userProfile) => {
    setSearchText('')
    setUserProfiles([])
  }

  const handleSearchPageForward = () => {
    initialFocusRef.current.blur()
    setIsOpen(false)
    history.push('/search', {
      searchText: searchText,
    })
  }

  const handleOnChange = (target) => {
    setIsOpen(true)
    setSearchText(target.value)
    setIsSearching(true)
  }

  const handleOnEnterPressed = (event) => {
    if (event.key === 'Enter') {
      handleSearchPageForward()
    }
  }

  return (
    <div ref={newSearchAreaRef} className="flex">
      <Popover
        initialFocusRef={initialFocusRef}
        isOpen={isOpen}
        placement="bottom"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <div className="relative flex items-center w-full lg:w-64 h-full group">
            <svg
              className="absolute left-0 z-20  w-4 h-4 ml-4 text-gray-500 pointer-events-none fill-current group-hover:text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
            </svg>
            <input
              ref={initialFocusRef}
              type="text"
              className="block w-full py-1.5 pl-10 pr-4 leading-normal rounded-2xl focus:border-transparent border-2 border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ring-opacity-90 bg-gray-800 dark:bg-gray-800 text-gray-400 aa-input"
              placeholder="Search"
              value={searchText}
              onKeyPress={(event) => {
                handleOnEnterPressed(event)
              }}
              onFocus={() => setIsOpen(true)}
              onChange={({ target }) => {
                handleOnChange(target)
              }}
            />
            {searchText && (
              <div className="absolute z-50 flex items-center justify-center w-auto h-10 p-3 pr-2 text-sm text-gray-500 uppercase cursor-pointer right-2">
                <svg
                  onClick={() => handleSearchCloseIconOnClick()}
                  fill="none"
                  className="relative w-5 h-5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
          <PopoverBody>
            {userProfiles.length ? (
              userProfiles
                .filter((user) => user.id !== currentUserInfo.id)
                .slice(0, 5)
                .map((userProfile) => {
                  return (
                    <ConnectionRow
                      key={userProfile.id}
                      currentUserId={currentUserInfo.id}
                      userProfile={{
                        ...userProfile,
                      }}
                      onAddConnection={() => {
                        handleUseDebounce()
                        setIsOpen(true)
                      }}
                      onRemoveConnection={() => {
                        handleUseDebounce()
                        setIsOpen(true)
                      }}
                      onSelect={() => handleUserOnSelect(userProfile)}
                      shortDeleteView={true}
                    />
                  )
                })
            ) : isSearching ? (
              <Spinner size="sm" color="#6B7280" />
            ) : searchText ? (
              <p>Hit enter for advanced results</p>
            ) : (
              <p>Enter some text to start searching</p>
            )}
          </PopoverBody>
          {userProfiles.filter((user) => user.id !== currentUserInfo.id).length > 5 && (
            <PopoverFooter>
              <p
                className="cursor-pointer hover:text-gray-400"
                onClick={() => handleSearchPageForward()}
              >
                See more results
              </p>
            </PopoverFooter>
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default SearchBar
