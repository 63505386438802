import { Menu, MenuButton, MenuItem, MenuList, Button, Icon } from '@chakra-ui/react'
import { IoChevronDownOutline } from 'react-icons/io5'
import { TiGroup } from 'react-icons/ti'
import { FaUserLock } from 'react-icons/fa'
import { MdPublic, MdPeople } from 'react-icons/md'
import React from 'react'

export const PRIVACY_MODES = {
  public: {
    name: 'Public',
    icon: MdPublic,
  },
  platform: {
    name: 'Platform',
    icon: TiGroup,
  },
  followers: {
    name: 'Followers',
    icon: MdPeople,
  },
  private: {
    name: 'Private',
    icon: FaUserLock,
  },
}

const PrivacySelector = ({ privacyMode = PRIVACY_MODES.public, onPrivacyChange = () => {} }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Icon as={privacyMode.icon} boxSize={6} />}
        rightIcon={<IoChevronDownOutline />}
      >
        {privacyMode.name}
      </MenuButton>
      <MenuList>
        {Object.keys(PRIVACY_MODES).map((key, i) => {
          const privacy = PRIVACY_MODES[key]
          return (
            <MenuItem
              key={privacy.name}
              minH="48px"
              onClick={() => {
                onPrivacyChange(key)
              }}
            >
              <Icon as={privacy.icon} mr={2} boxSize={6} />
              <span>{privacy.name}</span>
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default PrivacySelector
