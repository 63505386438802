import React, { useEffect, useState } from 'react'
import ImageUploader from '../../components/ImageUploader/ImageUploader'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import Button from '../../components/Button'
import useCheckLogIn from '../../hooks/useCheckLogIn'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormControl, FormErrorMessage } from '@chakra-ui/react'


const SignUp = ({ history }) => {
  useCheckLogIn()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()
 
  const [showPass, setShowPass] = useState(false)
  const [profileImage, setProfileImage] = useState(null)
  const [signupImage, setSignupImage] = useState(null)
  const [profileImageError, setProfileImageError] = useState(false)
 
  const handleChange = () => {
    reset({ errors: false })
  }

  const handleChangeProfileImage = (image) => {
    reset({ errors: false })
    setProfileImageError(false)
    setProfileImage(image)
  }

  useEffect(() => {
    if (signupImage) {
      setProfileImage(signupImage)
      setProfileImageError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupImage])

  const validateFields = (email, name, password, privacyPolicyAccepted, username) => {
    return !(
      !password ||
      !name ||
      !email ||
      !privacyPolicyAccepted ||
      !username ||
      password === '' ||
      name === '' ||
      username === '' ||
      email === ''
    )
  }

  const handleOnSubmit = async (data) => {
    const { email, name, password, privacyPolicyAccepted, username } = data
    let allValidated = validateFields(email, name, password, privacyPolicyAccepted, username)
    if (!allValidated) return
    if (!profileImage) {
      setProfileImageError(true)
      return
    }
    const userPayload = {
      name: name,
      username: username,
      email: email,
      password: password,
      photo: profileImage && profileImage instanceof File ? profileImage : null,
      privacyPolicyAccepted: privacyPolicyAccepted,
      accountType: 'Choose an account type',
    }
    history.push({ pathname: '/verify-code', state: { ...userPayload } })
  }

  const finalProfileImage = profileImage ? profileImage : profilePicture

  return (
    <div className="flex flex-1 justify-center items-center h-full w-full">
      <div className="flex flex-col w-full max-w-md mx-3 px-4 py-8 bg-white rounded-lg shadow-2xl dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center lg:mb-6 md:mb-6 text-4xl font-light text-gray-600 sm:text-4xl dark:text-white">
          Signup
        </div>
        <div>
          <form action="#" autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="flex flex-col mb-8">
              <ImageUploader
                containerStyle={{ alignSelf: 'center' }}
                selectedImage={finalProfileImage}
                onChange={handleChangeProfileImage}
                signup={true}
                signupImage={signupImage}
                setSignupImage={setSignupImage}
              />
              <p className="mt-2 text-center text-xs text-red-500 dark:text-gray-400">
                * Image is required
              </p>
            </div>
            <div className="mb-4">
              <p className="mt-2 text-sm text-red-500 dark:text-gray-400">
                * All field are required
              </p>
            </div>
            <FormControl isInvalid={errors.name}>
              <div className="flex flex-col mb-4">
                <div className="flex relative">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    defaultValue=""
                    {...register('name', {
                      required: 'Please enter your full name.',
                      minLength: {
                        value: 2,
                        message: 'Name should have between 2 and 20 characters.',
                      },
                      maxLength: {
                        value: 20,
                        message: 'Name should have between 2 and 20 characters.',
                      },
                    })}
                    onChange={handleChange}
                    placeholder="Full Name*"
                    className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  />
                </div>
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </div>
            </FormControl>
            <FormControl isInvalid={errors.username}>
              <div className="flex flex-col mb-4">
                <div className="flex relative">
                  <input
                    type="text"
                    name="username"
                    id="username"
                    defaultValue=""
                    {...register('username', {
                      required: 'Please enter your username.',
                      minLength: {
                        value: 4,
                        message: 'Username must be 4 characters.',
                      },
                    })}
                    onChange={handleChange}
                    placeholder="Username*"
                    className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  />
                </div>
                <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
              </div>
            </FormControl>
            <FormControl isInvalid={errors.email}>
              <div className="flex flex-col mb-4">
                <div className="flex relative">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    defaultValue=""
                    {...register('email', {
                      required: 'Please enter your email address.',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    onChange={handleChange}
                    placeholder="Email Address*"
                    className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  />
                </div>
                <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
              </div>
            </FormControl>
            <FormControl isInvalid={errors.password}>
              <div className="flex flex-col mb-4">
                <div className="flex relative">
                  <input
                    type={showPass ? 'text' : 'password'}
                    name="password"
                    id="password"
                    defaultValue=""
                    {...register('password', {
                      required: "Password field can't be empty.",
                      minLength: {
                        value: 8,
                        message: 'Password should have between 8 and 20 characters.',
                      },
                      maxLength: {
                        value: 20,
                        message: 'Password should have between 8 and 20 characters.',
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z0-9]).*$/,
                        message:
                          'Password must contain at least one lowercase letter and one uppercase letter or one number',
                      },
                    })}
                    onChange={handleChange}
                    placeholder="Password*"
                    className=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 pl-4 pr-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  />
                  <span
                    style={{ right: 13, top: '36%' }}
                    className="inline-flex items-center bg-white text-gray-500 cursor-pointer absolute"
                    onClick={() => setShowPass(!showPass)}
                  >
                    <i className={`far fa-eye${!showPass ? '-slash' : ''}`}></i>
                  </span>
                </div>
                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
              </div>
            </FormControl>
            <FormControl isInvalid={errors.privacyPolicyAccepted}>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="privacyPolicyAccepted"
                  id="privacyPolicyAccepted"
                  {...register('privacyPolicyAccepted', {
                    required: 'Need to check the box.',
                  })}
                  onChange={handleChange}
                  className="w-7 h-7 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="link-checkbox"
                  className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  I agree to the Co-Nectar{' '}
                  <Link
                    target="_blank"
                    to="/privacy-policy"
                    className="text-purple-600 dark:text-blue-500 hover:underline"
                  >
                    Privacy Policy
                  </Link>
                  , and I also confirm that I am above 18 years of age.
                </label>
              </div>
              <FormErrorMessage>
                {errors.privacyPolicyAccepted && errors.privacyPolicyAccepted.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={profileImageError}>
              <FormErrorMessage>{`Profile picture is required.`}</FormErrorMessage>
            </FormControl>
            <div className="flex w-full mt-7 mb-4">
              <Button
                disabled={profileImageError}
                className={isSubmitting ? 'cursor-not-allowed' : 'cursor-pointer'}
                type={'submit'}
                text={'Signup'}
                isLoading={isSubmitting}
              />
            </div>
          </form>
          <div className="flex justify-center flex-col items-center">
          </div>
          <div className="flex items-center justify-center mt-6">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="inline-flex items-center text-xs font-medium text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white"
              onClick={() => {
                history.push('/login')
              }}
            >
              <span className="ml-2">
                Already have an account? <span className={'text-purple-600'}>Login</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
