import { useState, useContext } from 'react'
import { useToast } from '@chakra-ui/react'
import { googleLogin } from '../services/user.service'
import { GlobalContext } from '../'

import { useHistory } from 'react-router'

const useGoogleFetch = (url, autoLogin = false) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const toast = useToast()
  const history = useHistory()
  const { updateUserInfo } = useContext(GlobalContext)

  const handleGoogle = async (response) => {
    setLoading(true)
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ credential: response.credential }),
    })
      .then((res) => {
        setLoading(false)
        return res.json()
      })
      .then((data) => {
        if (data?.user) {
          localStorage.setItem('user', JSON.stringify(data?.user))
          if (autoLogin) {
            googleLogin({ credential: response.credential })
            updateUserInfo()
            history.push('/')
          }
          window.location.reload()
        }
        throw new Error(data?.message || data)
      })
      .catch((error) => {
        setError(error?.message)
        toast({
          duration: 5000,
          status: 'error',
          position: 'top-right',
          variant: 'left-accent',
          title: error?.message || 'Oops! Something Went Wrong',
          description: '',
        })
      })
  }
  return { loading, error, handleGoogle }
}

export default useGoogleFetch
