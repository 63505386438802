import React from 'react'
import Comment from './Comment'

export const Replies = ({ repliesData, handleGetComment }) => {
  return repliesData.map((reply) => {
    return (
      <div
        className="border-solid border-2 rounded m-3"
        style={{ background: 'rgb(122 121 121 / 6%)' }}
      >
        <Comment comment={reply} handleGetComment={handleGetComment} />
      </div>
    )
  })
}
