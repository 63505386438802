import apiClient from '../http-clients/api.client'

export const getPosts = async (pageSize, pageIndex) => {
  return await apiClient.get(`/v1/post/user-post?pageSize=${pageSize}&pageIndex=${pageIndex}`)
}

export const getLatestPosts = async (id) => {
  return await apiClient.get(`/v1/post/latest-posts/${id}`)
}

export const getPostsByConnection = async (pageSize, pageIndex) => {
  return await apiClient.get(`/v1/post/feeds?pageSize=${pageSize}&pageIndex=${pageIndex}`)
}

export const getPublicPost = async (pageSize, pageIndex) => {
  return await apiClient.get(`/v1/post/feeds/public?pageSize=${pageSize}&pageIndex=${pageIndex}`)
}

export const createPost = async (payload) => {
  const formData = new FormData()
  formData.append('userId', payload.userId)
  formData.append('text', payload.text || '')
  formData.append('type', payload.type)
  formData.append('privacy_mode', payload.privacy_mode)
  formData.append('defaultIndex', payload.defaultIndex)
  formData.append('tags', JSON.stringify(payload.tags))
  formData.append('shareTo', JSON.stringify(payload.shareTo))
  formData.append('repostId', payload.repostId)

  payload.files.forEach((added) => {
    formData.append('files', added.file)
  })

  return await apiClient.post(`/v1/post`, formData)
}
export const createPostService = async (payload) => {
  const formData = new FormData()
  formData.append('userId', payload.userId)
  formData.append('text', payload.text || '')
  formData.append('type', payload.type)
  formData.append('privacy_mode', payload.privacy_mode)
  formData.append('defaultIndex', payload.defaultIndex)
  formData.append('tags', JSON.stringify(payload.tags))
  formData.append('shareTo', JSON.stringify(payload.shareTo))
  formData.append('serviceId', payload.serviceId)

  payload.files.forEach((added) => {
    formData.append('files', added.file)
  })

  return await apiClient.post(`/v1/post`, formData)
}

export const updatePost = async (payload) => {
  const formData = new FormData()
  formData.append('userId', payload.userId)
  formData.append('text', payload.text || '')
  formData.append('type', payload.type)
  formData.append('privacy_mode', payload.privacy_mode)
  formData.append('tags', JSON.stringify(payload.tags))
  formData.append('shareTo', JSON.stringify(payload.shareTo))
  formData.append('defaultIndex', payload.defaultIndex)

  payload.files.forEach((added) => {
    formData.append('files', added.file)
  })

  payload.removed.forEach((removed) => {
    formData.append('removed[]', removed)
  })

  return await apiClient.put(`/v1/post/${payload.id}`, formData)
}

export const updateLikePost = async (id, payload) => {
  return await apiClient.put(`/v1/post/${id}/likes`, payload)
}

export const deletePost = async (id) => {
  return await apiClient.delete(`/v1/post/${id}`)
}

export const getPost = async (id) => {
  return await apiClient.get(`/v1/post/${id}`)
}

export const getPostSearch = async (searchText) => {
  return apiClient.get(`/v1/post/query?search=${searchText}`)
}

export const getPostPublic = async (id) => {
  return await apiClient.get(`/v1/post/public/${id}`)
}
