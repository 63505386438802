import React from 'react'
import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBoolean,
} from '@chakra-ui/react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { BsEmojiSmile } from 'react-icons/bs'

const EmojiPickerPopover = ({ onSelectEmoji }) => {
  const [isOpen, setIsOpen] = useBoolean()

  const handleEmojiSelect = (emoji) => {
    if (onSelectEmoji) {
      onSelectEmoji(emoji.native)
    }
    setIsOpen.off()
  }

  return (
    <Flex>
      <Popover isOpen={isOpen} onClose={setIsOpen.off} placement="bottom" closeOnBlur={true}>
        <PopoverTrigger>
          <IconButton
            rounded="full"
            variant="ghost"
            aria-label="Emoji Picker"
            size="md"
            title="Emoji Picker"
            icon={<BsEmojiSmile size={24} />}
            onClick={setIsOpen.toggle}
          />
        </PopoverTrigger>
        <PopoverContent w="auto" p={0}>
          <PopoverArrow />
          <PopoverBody
            sx={{
              '*, *::before, *::after': {
                zIndex: 1,
              },
              '.emoji-mart-bar': {
                display: 'none',
              },
            }}
          >
            <Picker data={data} theme="dark" showPreview={true} onEmojiSelect={handleEmojiSelect} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  )
}

export default EmojiPickerPopover
