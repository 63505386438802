import ImageGallery from 'react-image-gallery'
import React from 'react'
import './ImageCarouselStyle.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import { useState, useEffect } from 'react'

export default function ImageCarousel({
  photos,
  startIndex = 0,
  setCurrentIndex,
  showThumbnails = true,
  showBullets = false,
  isInifniteScroll = false,
}) {
  const [hidePadding, setHidePadding] = useState(true)

  useEffect(() => {
    if (photos.length) {
      const files = photos.filter((file) => file.type === 'audio')
      if (files.length) {
        setHidePadding(false)
      }
    }
  }, [photos])
  return (
    <>
      <div className={hidePadding ? '' : 'customImageGallery'}>
        <ImageGallery
          onSlide={(event) => {
            setCurrentIndex(event)
          }}
          startIndex={startIndex}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={showThumbnails}
          infinite={isInifniteScroll}
          showBullets={showBullets}
          items={photos}
        />
      </div>
    </>
  )
}
