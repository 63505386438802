import apiClient from '../http-clients/api.client'

export const login = async (username, password) => {
  return await apiClient.post('/v1/auth/signin', {
    email: username,
    password: password,
  })
}

export const signup = async (userPayload) => {
  const formData = new FormData()
  formData.append('name', userPayload.name)
  formData.append('username', userPayload.username)
  formData.append('email', userPayload.email)
  formData.append('password', userPayload.password)
  formData.append('privacyPolicyAccepted', userPayload.privacyPolicyAccepted)
  userPayload.photo && formData.append('photo', userPayload.photo)
  return await apiClient.post('/v1/user', formData)
}

export const googleLogin = async (credentials) => {
  return await apiClient.post('/v1/auth/signInGoogle', credentials)
}

export const appendSheetData = async (data) => {
  return await apiClient.post('/v1/user/appendSheetData', data)
}

export const googleSignUp = async (credentials) => {
  return await apiClient.post('/v1/auth/signUpGoogle', credentials)
}

export const getUser = async (id) => {
  return await apiClient.get(`/v1/user/${id}`)
}

export const getUsersByList = async (ids) => {
  return await apiClient.get(`/v1/user/list?ids=${ids}`)
}

export const update = async (userPayload) => {
  const formData = new FormData()
  formData.append('name', userPayload.name)
  formData.append('email', userPayload.email)
  formData.append('username', userPayload.username)
  userPayload.photo && formData.append('photo', userPayload.photo)
  return await apiClient.put(`/v1/user/${userPayload.id}`, formData)
}

export const deactivateUser = async (id, payload) => {
  return await apiClient.put(`/v1/user/${id}`, payload)
}

export const deleteAccount = async () => {
  return await apiClient.post(`/v1/user/account`)
}

export const requestPasswordReset = async (payload) => {
  return await apiClient.post('/v1/auth/request-password-reset', payload)
}

export const updateLoadPost = async (payload) => {
  return await apiClient.post('/v1/user/update-load-post-automatically', payload)
}

export const resetPassword = async (payload) => {
  return await apiClient.post(`/v1/auth/reset-password`, payload)
}

export const tokenResetPassword = async (payload) => {
  return await apiClient.post(`/v1/auth/token-reset-password`, payload)
}

export const tokenVerificationCode = async (payload) => {
  return await apiClient.post(`/v1/auth/token-verification-code`, payload)
}

export const requestVerificationCode = async (payload) => {
  return await apiClient.post('/v1/auth/request-verification-code', payload)
}

export const getUserSearch = async (searchText, connected = false) => {
  return apiClient.get(`/v1/user?search=${searchText}&connected=${connected}`)
}

export const getUserPublicSearch = async (searchText) => {
  return apiClient.get(`/v1/user/public?search=${searchText}`)
}

export const createContact = async (userId) => {
  return await apiClient.post(`/v1/contact`, { userId02: userId })
}

export const deleteContact = async (userId) => {
  return await apiClient.delete(`/v1/contact/${userId}`)
}

export const getMyContacts = async (pageSize) => {
  return apiClient.get(`/v1/contact/connected-to-me?pageSize=${pageSize}`)
}

export const addToFavorite = async (serviceId) => {
  return await apiClient.post(`/v1/user/add-to-favorite/${serviceId}`)
}

export const removeFavorite = async (serviceId) => {
  return await apiClient.delete(`/v1/user/remove-from-favorite/${serviceId}`)
}
